import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import moment from 'moment'
import _ from 'lodash'

import Button, { ClearButton } from './button'
import DeleteButtonWithConfirmation from './delete-button-with-confirmation'
import { SearchInput } from './input'
import { InputLabel, MultiInputField } from './input-v2'
import { LoadingLabel } from './loader'
import Modal from './modal'
import SelectBox, { SelectBoxChecklist } from './select-box'
import { UpdateSubscriptionUsersModal } from './subscription-comparison'
import Table, { TableHeaderColumn, TableSortFn } from './table-v2'
import Tooltip from './tooltip'
import { ErrorMessage, Heading, SuccessText } from './typography'
import { RequestMoreUsersModal } from './upgrade-modals'
import { currentUserDetails } from '../api/apollo/variables'
import {
  addUsersToAccountsBulk,
  removeUserFromAllWorkspaces,
} from '../api/graphql/custom-queries'
import {
  addUserToAccountBulk,
  getCompanyUsers,
  removeExistingUser,
  revokeUserInvite,
  updateUserPermissions,
} from '../api/graphql/settings-client'
import { dateFormatShort } from '../core/constants'
import { downloadUsersCsv, fuzzySearch, getCsvString } from '../helpers'
import { validateEmail } from '../helpers/forms'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import useSubscriptionLevel, {
  subscriptionLevelDetails,
} from '../hooks/useSubscriptionLevel'
import styles from '../styles/settings-user-management.module.scss'

type PermissionLevel = 'admin' | 'regular' | 'support' | 'whitelabelSupport'

const permissionOptions = [
  { value: 'regular', label: 'Regular' },
  { value: 'admin', label: 'Admin' },
]

interface TransformedUser {
  userID: string
  userEmail: string
  created: string
  expired: boolean
  inviteOpen: boolean
  lastLogin: string
  workspacePermissions: {
    [workspaceID: string]: {
      workspaceName: string
      permission: PermissionLevel
    }
  }
}

interface AddNewUsersProps {
  loading: boolean
  totalUserCount: number
  loggedInUserWorkspaces: {
    [workspaceID: string]: {
      workspaceName: string
      permission: PermissionLevel | null
    }
  } | null
  existingUsers: string[]
}

const AddNewUsers = ({
  loading,
  totalUserCount,
  loggedInUserWorkspaces,
  existingUsers,
}: AddNewUsersProps) => {
  const { companyID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { fullOnboardingSections, updateOnboardingSection } = useOnboarding()

  const {
    isFree,
    isTeam,
    isEnterprise,
    paddleData,
    loadingPaddleData,
    microsoftSubscriptionData,
    loadingMicrosoftData,
  } = useSubscriptionLevel()

  const hasInvitedUsers = useMemo(() => {
    const inviteUsersSection = fullOnboardingSections.account.find(
      (section) => section.onboardingSectionID === 'inviteUsers',
    )

    return !!(inviteUsersSection && inviteUsersSection.sectionCompleted)
  }, [fullOnboardingSections])

  const availableLicences = useMemo(() => {
    if (isEnterprise) return Infinity

    if (isFree) return 1

    if (!paddleData && !microsoftSubscriptionData) return 0

    if (paddleData?.currentCompany.paddleSubscriptionQuantity) {
      return parseInt(paddleData.currentCompany.paddleSubscriptionQuantity, 10)
    }

    if (
      microsoftSubscriptionData?.currentCompany.microsoftSubscriptionData
        ?.quantity
    ) {
      return microsoftSubscriptionData.currentCompany.microsoftSubscriptionData
        .quantity
    }

    return 0
  }, [isEnterprise, isFree, paddleData, microsoftSubscriptionData])

  const availableWorkspaces = useMemo(() => {
    if (!loggedInUserWorkspaces) return []

    return Object.keys(loggedInUserWorkspaces)
      .filter((workspaceID) =>
        ['admin', 'support', 'whitelabelSupport'].includes(
          loggedInUserWorkspaces[workspaceID].permission || 'regular',
        ),
      )
      .map((workspaceID) => ({
        workspaceID,
        workspaceName: loggedInUserWorkspaces[workspaceID].workspaceName,
      }))
  }, [loggedInUserWorkspaces])

  const [showUpgradeTierModal, setShowUpgradeTierModal] = useState(false)
  const [showBuyMoreLicencesModal, setShowBuyMoreLicencesModal] = useState(
    false,
  )
  const [buyMoreLicencesMessage, setBuyMoreLicencesMessage] = useState('')

  const [emails, setEmails] = useState<{ label: string; value: string }[]>([])
  const [workspaces, setWorkspaces] = useState<string[]>([])
  const [permissionLevel, setPermissionLevel] = useState<'admin' | 'regular'>(
    'regular',
  )

  const [blockedEmails, setBlockedEmails] = useState<string[]>([])
  const [invalidEmails, setInvalidEmails] = useState<string[]>([])

  const [addUsersState, setAddUsersState] = useState({
    loading: false,
    success: false,
    error: false,
  })

  // Initialise workspaces
  useEffect(() => {
    setWorkspaces(availableWorkspaces.map(({ workspaceID }) => workspaceID))
  }, [availableWorkspaces])

  if (
    isFree ||
    (isTeam && totalUserCount >= subscriptionLevelDetails.startup.maxUsers)
  ) {
    return (
      <>
        <p>
          You need to{' '}
          <Button
            variant="text"
            className={styles.upgradeButton}
            onPress={() => {
              // User limit for tier is reached - push to upgrade
              if (
                isFree ||
                totalUserCount >= subscriptionLevelDetails.startup.maxUsers
              ) {
                setShowUpgradeTierModal(true)

                return
              }

              setShowBuyMoreLicencesModal(true)
            }}
          >
            upgrade
          </Button>{' '}
          to add more users.
        </p>
        {showUpgradeTierModal && (
          <RequestMoreUsersModal onHideModal={setShowUpgradeTierModal} />
        )}
      </>
    )
  }

  if (isTeam && totalUserCount >= availableLicences) {
    return (
      <>
        <p>
          You have reached your user limit.{' '}
          <Button
            variant="text"
            className={styles.upgradeButton}
            onPress={() => setShowBuyMoreLicencesModal(true)}
          >
            Buy more licences
          </Button>{' '}
          or{' '}
          <Button
            variant="text"
            className={styles.upgradeButton}
            onPress={() => setShowUpgradeTierModal(true)}
          >
            upgrade
          </Button>{' '}
          to add more users.
        </p>
        {showUpgradeTierModal && (
          <RequestMoreUsersModal onHideModal={setShowUpgradeTierModal} />
        )}
        {showBuyMoreLicencesModal && (
          <UpdateSubscriptionUsersModal
            onHideModal={setShowBuyMoreLicencesModal}
          />
        )}
      </>
    )
  }

  return (
    <>
      <div className={styles.addUsersContainer}>
        {loading || loadingMicrosoftData || loadingPaddleData ? (
          <LoadingLabel label="Fetching users" />
        ) : (
          <>
            <form
              className={styles.addUsersForm}
              onSubmit={async (e) => {
                e.preventDefault()

                setBlockedEmails([])
                setInvalidEmails([])
                setAddUsersState({
                  loading: true,
                  success: false,
                  error: false,
                })

                // Must upgrade to add the required amount of users
                if (
                  isTeam &&
                  totalUserCount + emails.length >
                    subscriptionLevelDetails.startup.maxUsers
                ) {
                  setAddUsersState({
                    loading: false,
                    success: false,
                    error: false,
                  })

                  setShowUpgradeTierModal(true)

                  return
                }

                // Must buy more licences to add the required amount of users
                if (totalUserCount + emails.length > availableLicences) {
                  setAddUsersState({
                    loading: false,
                    success: false,
                    error: false,
                  })

                  setShowBuyMoreLicencesModal(true)
                  setBuyMoreLicencesMessage(
                    `You do not have enough remaining licences to add ${
                      emails.length
                    } new user${
                      emails.length === 1 ? '' : 's'
                    }. Remove some or purchase more licences.`,
                  )

                  return
                }

                const { errors } = await addUsersToAccountsBulk({
                  users: emails.map(({ value }) => value),
                  accountIDs: workspaces,
                  permissionLevelList: workspaces.map(() => permissionLevel),
                  companyID,
                })

                if (!errors) {
                  logAction({
                    variables: {
                      action: 'add-new-users',
                      functionName: 'addUserToAccountBulk',
                      pagePath: 'settings',
                      websiteSection: 'Users',
                      extra: JSON.stringify({
                        emails,
                        accountIDs: workspaces,
                        permissionLevel,
                      }),
                    },
                  })

                  if (!hasInvitedUsers) {
                    // Updates the backend cache for onboarding state
                    updateOnboardingSection('inviteUsers', 'account')
                  }

                  setAddUsersState({
                    loading: false,
                    success: true,
                    error: false,
                  })

                  window.setTimeout(() => {
                    setAddUsersState({
                      loading: false,
                      success: false,
                      error: false,
                    })

                    setEmails([])
                  }, 3000)
                } else {
                  setAddUsersState({
                    loading: false,
                    success: false,
                    error: false,
                  })
                }
              }}
            >
              <div className={styles.emailField}>
                <InputLabel
                  htmlFor="addEmail"
                  tooltip="Add multiple users by separating email addresses with commas or semicolons."
                >
                  Email
                </InputLabel>
                <MultiInputField
                  id="addEmail"
                  placeholder="Enter email address(es)"
                  addInputOnBlur
                  noPillForSingleValue
                  value={emails}
                  setValue={setEmails}
                  // Reset error states
                  formatInputValue={(value) => {
                    setBlockedEmails([])
                    setInvalidEmails([])

                    return value
                  }}
                  // Only allow valid, non-existing emails
                  modifyInputValues={(values) => {
                    setBlockedEmails([])
                    setInvalidEmails([])

                    const alreadyExists = values.filter(({ value }) =>
                      existingUsers.includes(value),
                    )
                    const newValues = values
                      .filter(({ value }) => !existingUsers.includes(value))
                      .map(({ value }) => value)

                    const { valid, invalid } = validateEmail(newValues, true)

                    if (alreadyExists.length > 0) {
                      setBlockedEmails(alreadyExists.map(({ value }) => value))
                    }

                    if (invalid.length > 0) {
                      setInvalidEmails(invalid)
                    }

                    return {
                      blockedValues: [
                        ...alreadyExists,
                        ...invalid.map((value) => ({
                          label: value,
                          value,
                        })),
                      ],
                      allowedValues: valid.map((value) => ({
                        label: value,
                        value,
                      })),
                    }
                  }}
                />
              </div>
              {availableWorkspaces.length > 1 && (
                <div className={styles.workspacesField}>
                  <InputLabel
                    htmlFor="addWorkspaces"
                    tooltip="Select which workspaces the users(s) should have access to."
                  >
                    Workspaces
                  </InputLabel>
                  <SelectBoxChecklist
                    id="addWorkspaces"
                    allLabel="All workspaces"
                    excludeNone
                    showOnlyButtons
                    isClearable={false}
                    labelKey="workspaceName"
                    valueKey="workspaceID"
                    value={availableWorkspaces.filter(({ workspaceID }) =>
                      workspaces.includes(workspaceID),
                    )}
                    options={availableWorkspaces}
                    onChange={(newValue) => {
                      setWorkspaces(
                        newValue.map(({ workspaceID }) => workspaceID),
                      )
                    }}
                  />
                </div>
              )}
              <div className={styles.permissionField}>
                <InputLabel
                  htmlFor="addPermission"
                  tooltip="**Regular** users can create and view campaign codes. **Admin** users can also edit your campaign code structure and account details."
                >
                  Permission
                </InputLabel>
                <SelectBox
                  id="addPermission"
                  options={permissionOptions}
                  value={permissionOptions.find(
                    ({ value }) => value === permissionLevel,
                  )}
                  onChange={async (newValue) => {
                    if (!newValue) return

                    setPermissionLevel(newValue.value as 'regular' | 'admin')
                  }}
                />
              </div>
              <Button
                type="submit"
                className={styles.addButton}
                loading={addUsersState.loading}
                isDisabled={emails.length === 0}
              >
                Add
              </Button>
            </form>
            {invalidEmails.length > 0 && (
              <ErrorMessage>Input must be a valid email address.</ErrorMessage>
            )}
            {blockedEmails.length > 0 && (
              <ErrorMessage>
                User{blockedEmails.length === 1 ? ' is' : 's are'} already
                registered ({blockedEmails.join(', ')}). You can edit their
                {availableWorkspaces.length > 1 ? ' workspaces and' : ''}{' '}
                permissions in the table below.
              </ErrorMessage>
            )}
            {addUsersState.error && (
              <ErrorMessage showSupport>Failed to add new users.</ErrorMessage>
            )}
            {addUsersState.success && (
              <SuccessText>
                {emails.length} user
                {emails.length === 1 ? ' has' : 's have'} been added to the
                selected workspaces.
              </SuccessText>
            )}
          </>
        )}
      </div>
      {showUpgradeTierModal && (
        <RequestMoreUsersModal onHideModal={setShowUpgradeTierModal} />
      )}
      {showBuyMoreLicencesModal && (
        <UpdateSubscriptionUsersModal
          onHideModal={setShowBuyMoreLicencesModal}
          showWarningMessage={buyMoreLicencesMessage}
        />
      )}
    </>
  )
}

interface AddExistingUserToWorkspacesProps {
  email: string
  availableWorkspaces: { workspaceID: string; workspaceName: string }[]
  onCancel: (success?: boolean) => void
}

const AddExistingUserToWorkspaces = ({
  email,
  availableWorkspaces,
  onCancel,
}: AddExistingUserToWorkspacesProps) => {
  const { companyID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const [permissionLevel, setPermissionLevel] = useState<'admin' | 'regular'>(
    'regular',
  )
  const [selectedAccounts, setSelectedAccounts] = useState<
    { workspaceID: string; workspaceName: string }[]
  >(availableWorkspaces)
  const [addUsersState, setAddUsersState] = useState({
    loading: false,
    success: false,
    error: false,
  })

  return (
    <>
      <div className={styles.addWorkspaces}>
        <SelectBoxChecklist
          value={selectedAccounts}
          labelKey="workspaceName"
          valueKey="workspaceID"
          allLabel="All remaining workspaces"
          excludeNone
          isClearable={false}
          options={availableWorkspaces}
          onChange={(newValue) => {
            if (newValue.length === 0) return
            setSelectedAccounts([...newValue])
          }}
        />
        <SelectBox
          value={permissionOptions.find(
            (option) => option.value === permissionLevel,
          )}
          options={permissionOptions}
          onChange={(newValue) => {
            if (!newValue) return

            setPermissionLevel(newValue.value as 'admin' | 'regular')
          }}
        />
        <Button
          isDisabled={selectedAccounts.length === 0 || addUsersState.success}
          loading={addUsersState.loading}
          onPress={async () => {
            setAddUsersState({ loading: true, success: false, error: false })

            const { errors } = await addUsersToAccountsBulk({
              users: [email],
              accountIDs: selectedAccounts.map(
                ({ workspaceID }) => workspaceID,
              ),
              permissionLevelList: selectedAccounts.map(() => permissionLevel),
              companyID,
            })

            if (!errors) {
              logAction({
                variables: {
                  action: 'add-existing-user-to-more-workspaces',
                  functionName: 'addUserToAccountBulk',
                  pagePath: 'settings',
                  websiteSection: 'Users',
                  extra: JSON.stringify({
                    email,
                    selectedAccounts,
                    permissionLevel,
                  }),
                },
              })

              setAddUsersState({ loading: false, success: true, error: false })

              onCancel(true)
            } else {
              setAddUsersState({ loading: false, success: false, error: false })
            }
          }}
        >
          Add
        </Button>
        <ClearButton onPress={() => onCancel()} />
      </div>
      {addUsersState.error && (
        <ErrorMessage showSupport>
          Failed to add user to workspaces.
        </ErrorMessage>
      )}
    </>
  )
}

interface UserRowProps {
  hasMultipleWorkspaces?: boolean
  user: TransformedUser
  userRowRef: React.RefObject<HTMLElement>
  loggedInUserWorkspaces: {
    [workspaceID: string]: {
      workspaceName: string
      permission: PermissionLevel | null
    }
  }
  loggedInUserIsFullAdmin: boolean
}

const UserRow = ({
  hasMultipleWorkspaces,
  user,
  userRowRef,
  loggedInUserWorkspaces,
  loggedInUserIsFullAdmin,
}: UserRowProps) => {
  const { companyID } = useReactiveVar(currentUserDetails)

  const {
    userID,
    userEmail,
    created,
    lastLogin,
    inviteOpen,
    workspacePermissions,
  } = user

  const logAction = useLogAction()

  const [
    addUserToWorkspace,
    { loading: reinvitingUser, error: reinviteUserError },
  ] = useMutation(addUserToAccountBulk)
  const [
    revokeInvite,
    { loading: revokingInvite, error: revokeInviteError },
  ] = useMutation(revokeUserInvite)
  const [
    updateUserWorkspacePermission,
    { loading: updatingPermission, error: errorUpdatingPermission },
  ] = useMutation(updateUserPermissions)
  const [
    removeUserFromWorkspace,
    { loading: removingUserFromWorkspace, error: removeUserFromWorkspaceError },
  ] = useMutation(removeExistingUser)

  const [reinviteSuccess, setReinviteSuccess] = useState(false)
  const [updatePermissionSuccess, setUpdatePermissionSuccess] = useState(false)
  const [confirmRemoveUserModal, setConfirmRemoveUserModal] = useState<{
    workspaceID: string
    workspaceName: string
  } | null>(null)
  const [showAddWorkspaces, setShowAddWorkspaces] = useState(false)
  const [addWorkspacesSuccess, setAddWorkspacesSuccess] = useState(false)

  /** Changes what shows in permissions column */
  const userIsFullSupport = useMemo(() => {
    return Object.keys(workspacePermissions).every((workspaceID) =>
      ['support', 'whitelabelSupport'].includes(
        workspacePermissions[workspaceID].permission,
      ),
    )
  }, [workspacePermissions])

  /** Used to prevent support users from being deleted */
  const userIsPartialSupport = useMemo(() => {
    return Object.keys(workspacePermissions).some((workspaceID) =>
      ['support', 'whitelabelSupport'].includes(
        workspacePermissions[workspaceID].permission,
      ),
    )
  }, [workspacePermissions])

  /** Workspaces this user isn't on but the logged-in user is an admin for */
  const remainingAvailableWorkspaces = Object.keys(loggedInUserWorkspaces)
    .filter(
      (workspaceID) =>
        !workspacePermissions[workspaceID] &&
        ['support', 'whitelabelSupport', 'admin'].includes(
          loggedInUserWorkspaces[workspaceID]?.permission || 'regular',
        ),
    )
    .map((workspaceID) => ({
      workspaceID,
      workspaceName: loggedInUserWorkspaces[workspaceID].workspaceName,
    }))

  const revokeInviteCallback = useCallback(async () => {
    const { errors } = await revokeInvite({
      variables: { userID },
      // Remove user from Apollo cache, all workspaces they were invited to
      update: (cache, { data }) => {
        if (data) {
          Object.keys(workspacePermissions).forEach((workspace) => {
            const accountCacheItemID = `Account:{"accountID":"${workspace}"}`

            cache.modify({
              id: accountCacheItemID,
              fields: {
                userAccountProfiles: (existingUserAccountProfiles) => {
                  const updatedUserAccountProfiles = _.cloneDeep(
                    existingUserAccountProfiles,
                  )

                  const userIndex = updatedUserAccountProfiles.findIndex(
                    (userAccountProfile) =>
                      userAccountProfile.userID === userID,
                  )

                  if (userIndex !== -1) {
                    updatedUserAccountProfiles.splice(userIndex, 1)
                  }

                  return updatedUserAccountProfiles
                },
              },
            })
          })

          // Update userCount in cached company object
          cache.modify({
            id: `Company:{"companyID":"${companyID}"}`,
            fields: {
              userCount: (existingUserCount) => {
                return existingUserCount - 1
              },
            },
          })
        }
      },
    })

    if (!errors) {
      logAction({
        variables: {
          action: 'revoke-user-invitation',
          functionName: 'revokeUserInvite',
          pagePath: 'settings',
          websiteSection: 'Users',
          extra: JSON.stringify({
            userID,
            userEmail,
            workspacePermissions,
          }),
        },
      })
    }
  }, [revokeInvite, logAction, userID, userEmail, workspacePermissions])

  // Don't show support users in the table
  if (userIsFullSupport) return null

  return (
    <>
      <>
        <td className={styles.emailColumn}>
          <p>{userEmail}</p>
        </td>
        <td className={styles.lastLoginColumn}>
          {lastLogin && lastLogin !== '' ? (
            <p>{moment(lastLogin).format(dateFormatShort)}</p>
          ) : (
            <>
              <p className={styles.greyText}>
                Never - invited {moment(created).format(dateFormatShort)}.
                {inviteOpen && (
                  <>
                    {' '}
                    <Button
                      variant="text"
                      color="blue"
                      isDisabled={reinvitingUser || reinviteSuccess}
                      onPress={async () => {
                        const { errors } = await addUserToWorkspace({
                          variables: {
                            email: userEmail,
                            accountIDList: Object.keys(workspacePermissions),
                            permissionLevelList: Object.values(
                              workspacePermissions,
                            ).map(({ permission }) => permission),
                          },
                        })

                        if (!errors) {
                          logAction({
                            variables: {
                              action: 'reinvite-user',
                              functionName: 'addUserToAccountBulk',
                              pagePath: 'settings',
                              websiteSection: 'Users',
                              extra: JSON.stringify({
                                userID,
                                userEmail,
                                workspacePermissions,
                              }),
                            },
                          })

                          setReinviteSuccess(true)

                          window.setTimeout(() => {
                            setReinviteSuccess(false)
                          }, 3000)
                        }
                      }}
                    >
                      Reinvite
                    </Button>
                  </>
                )}
              </p>
              {reinvitingUser && <LoadingLabel label="Sending new invite" />}
              {reinviteSuccess && <SuccessText>Invite resent.</SuccessText>}
              {!!reinviteUserError && (
                <ErrorMessage showSupport>
                  Failed to send new invite.
                </ErrorMessage>
              )}
            </>
          )}
        </td>
        <td className={styles.permissionsColumn}>
          <>
            {Object.keys(loggedInUserWorkspaces).map((workspaceID) => {
              // Shown user does not have access to this workspace
              if (!workspacePermissions[workspaceID]) return null

              const fullRoles = [...permissionOptions]

              if (
                ['admin', 'support', 'whitelabelSupport'].includes(
                  loggedInUserWorkspaces[workspaceID]?.permission || 'regular',
                )
              ) {
                fullRoles.push({
                  value: 'delete',
                  label: inviteOpen ? 'Revoke invite' : 'Remove',
                })
              }

              return (
                <div
                  key={`${userID}-${workspaceID}`}
                  className={styles.workspacePermission}
                >
                  {hasMultipleWorkspaces && (
                    <p>{workspacePermissions[workspaceID].workspaceName}:</p>
                  )}
                  <Tooltip
                    id={`edit-${workspaceID}`}
                    className={styles.buttonTooltip}
                    tooltipMessage={
                      !loggedInUserWorkspaces[workspaceID]?.permission ||
                      loggedInUserWorkspaces[workspaceID]?.permission ===
                        'regular'
                        ? "You don't have permissions for this workspace"
                        : undefined
                    }
                  >
                    {['support', 'whitelabelSupport'].includes(
                      workspacePermissions[workspaceID].permission,
                    ) ? (
                      // Support users are not editable
                      <p className={styles.greyText}>Support</p>
                    ) : (
                      <SelectBox
                        id={`${userID}-${workspaceID}-permission-selector`}
                        className={
                          hasMultipleWorkspaces
                            ? styles.permissionSelector
                            : undefined
                        }
                        menuPosition="fixed"
                        isDisabled={
                          updatingPermission ||
                          !loggedInUserWorkspaces[workspaceID]?.permission ||
                          loggedInUserWorkspaces[workspaceID]?.permission ===
                            'regular'
                        }
                        styles={{
                          menu: (css) => ({ ...css, minWidth: 125 }),
                        }}
                        isLoading={updatingPermission || revokingInvite}
                        options={fullRoles}
                        value={permissionOptions.find(
                          ({ value }) =>
                            value ===
                            workspacePermissions[workspaceID].permission,
                        )}
                        onChange={async (newValue) => {
                          if (!newValue) return

                          // Delete functionality
                          if (newValue.value === 'delete') {
                            // Revoke invite if never logged in
                            if (inviteOpen) {
                              await revokeInviteCallback()

                              return
                            }

                            setConfirmRemoveUserModal({
                              workspaceID,
                              workspaceName:
                                workspacePermissions[workspaceID].workspaceName,
                            })

                            return
                          }

                          const {
                            errors,
                          } = await updateUserWorkspacePermission({
                            variables: {
                              accountID: workspaceID,
                              permissionLevel: newValue.value,
                              userID,
                            },
                            // Update permission in cache
                            update: (cache, { data }) => {
                              if (data) {
                                const accountCacheItemID = `Account:{"accountID":"${workspaceID}"}`

                                cache.modify({
                                  id: accountCacheItemID,
                                  fields: {
                                    userAccountProfiles: (
                                      existingUserAccountProfiles,
                                    ) => {
                                      const updatedUserAccountProfiles = _.cloneDeep(
                                        existingUserAccountProfiles,
                                      )

                                      const userIndex = updatedUserAccountProfiles.findIndex(
                                        (userAccountProfile) =>
                                          userAccountProfile.userID === userID,
                                      )

                                      if (userIndex !== -1) {
                                        updatedUserAccountProfiles.splice(
                                          userIndex,
                                          1,
                                          {
                                            ...updatedUserAccountProfiles[
                                              userIndex
                                            ],
                                            userPermission: newValue.value,
                                          },
                                        )
                                      }

                                      return updatedUserAccountProfiles
                                    },
                                  },
                                })
                              }
                            },
                          })

                          if (!errors) {
                            logAction({
                              variables: {
                                action: 'update-user-permission',
                                functionName: 'addUserToAccountBulk',
                                pagePath: 'settings',
                                websiteSection: 'Users',
                                extra: JSON.stringify({
                                  userID,
                                  userEmail,
                                  workspaceID,
                                  oldPermission:
                                    workspacePermissions[workspaceID]
                                      .permission,
                                  newPermission: newValue.value,
                                }),
                              },
                            })

                            setUpdatePermissionSuccess(true)

                            window.setTimeout(() => {
                              setUpdatePermissionSuccess(false)
                            }, 3000)
                          }
                        }}
                      />
                    )}
                  </Tooltip>
                </div>
              )
            })}
          </>
          {updatePermissionSuccess && (
            <SuccessText>User permissions updated.</SuccessText>
          )}
          {!!revokeInviteError && (
            <ErrorMessage showSupport>Failed to revoke invite.</ErrorMessage>
          )}
          {!!errorUpdatingPermission && (
            <ErrorMessage showSupport>
              Failed to update user permissions.
            </ErrorMessage>
          )}
          {remainingAvailableWorkspaces.length > 0 && (
            <>
              {showAddWorkspaces ? (
                <AddExistingUserToWorkspaces
                  email={user.userEmail}
                  availableWorkspaces={remainingAvailableWorkspaces}
                  onCancel={(success) => {
                    setShowAddWorkspaces(false)

                    if (success) {
                      setAddWorkspacesSuccess(true)

                      window.setTimeout(() => {
                        setAddWorkspacesSuccess(false)
                      }, 3000)
                    }
                  }}
                />
              ) : (
                <Button
                  variant="text"
                  className={styles.addToWorkspaceButton}
                  onPress={() => setShowAddWorkspaces(true)}
                >
                  Add to another workspace +
                </Button>
              )}
            </>
          )}
          {addWorkspacesSuccess && (
            <SuccessText>User added to workspaces.</SuccessText>
          )}
        </td>
        {loggedInUserIsFullAdmin && (
          <td className={styles.deleteColumn}>
            {!userIsPartialSupport && (
              <DeleteButtonWithConfirmation
                containerRef={userRowRef}
                confirmationClassName={styles.deleteConfirmContainer}
                confirmMessage={
                  <>
                    Remove <strong>{userEmail}</strong>
                    {hasMultipleWorkspaces ? ' from all workspaces' : ''}?
                  </>
                }
                onConfirm={async () => {
                  if (inviteOpen) {
                    await revokeInviteCallback()

                    return
                  }

                  const { errors } = await removeUserFromAllWorkspaces(
                    userID,
                    Object.keys(workspacePermissions),
                    companyID,
                  )

                  if (!errors) {
                    logAction({
                      variables: {
                        action: 'remove-user-from-all-workspace',
                        functionName: 'removeUserFromAllWorkspaces',
                        pagePath: 'settings',
                        websiteSection: 'Users',
                        extra: JSON.stringify({
                          userID,
                          userEmail,
                          workspaceIDs: Object.keys(workspacePermissions),
                        }),
                      },
                    })
                  }
                }}
              />
            )}
          </td>
        )}
      </>
      {confirmRemoveUserModal && (
        <Modal
          setIsOpen={() => setConfirmRemoveUserModal(null)}
          modalHeader={`Remove ${userEmail} from workspace?`}
          headerColor="pink"
          isWarning
          yesButtonLoading={removingUserFromWorkspace}
          yesButtonDisabled={!!removeUserFromWorkspaceError}
          yesText="Remove"
          onYes={async () => {
            const { errors } = await removeUserFromWorkspace({
              variables: {
                accountID: confirmRemoveUserModal.workspaceID,
                userID,
              },
              update: (cache, { data }) => {
                if (!data) return

                cache.modify({
                  id: `Account:{"accountID":"${confirmRemoveUserModal.workspaceID}"}`,
                  fields: {
                    userAccountProfiles: (existingUserAccountProfiles) => {
                      const updatedUserAccountProfiles = _.cloneDeep(
                        existingUserAccountProfiles,
                      )

                      const userIndex = updatedUserAccountProfiles.findIndex(
                        (userAccountProfile) =>
                          userAccountProfile.userID === userID,
                      )

                      if (userIndex !== -1) {
                        updatedUserAccountProfiles.splice(userIndex, 1)
                      }

                      return updatedUserAccountProfiles
                    },
                  },
                })

                // If this was the only workspace the user had access to, update userCount in cached company object
                if (Object.keys(workspacePermissions).length === 1) {
                  cache.modify({
                    id: `Company:{"companyID":"${companyID}"}`,
                    fields: {
                      userCount: (existingUserCount) => {
                        return existingUserCount - 1
                      },
                    },
                  })
                }
              },
            })

            if (!errors) {
              logAction({
                variables: {
                  action: 'remove-user-from-workspace',
                  functionName: 'removeUserFromWorkspace',
                  pagePath: 'settings',
                  websiteSection: 'Users',
                  extra: JSON.stringify({
                    userID,
                    userEmail,
                    workspaceID: confirmRemoveUserModal.workspaceID,
                  }),
                },
              })

              setConfirmRemoveUserModal(null)
            }
          }}
          footerContent={
            removeUserFromWorkspaceError ? (
              <ErrorMessage showSupport>Failed to remove user.</ErrorMessage>
            ) : undefined
          }
        >
          <p>
            They will no longer have access to{' '}
            {confirmRemoveUserModal.workspaceName}.
          </p>
        </Modal>
      )}
    </>
  )
}

/** Sorts by last login date, falling back to created date if not found */
const lastLoginSort: TableSortFn<TransformedUser> = ({
  lastLogin,
  created,
}) => {
  return lastLogin ? new Date(lastLogin) : new Date(created)
}

interface DownloadedUser {
  Email: string
  'Last login date': string
  'Date added': string
  'Registration link expired': string
  'Workspace permissions': string
}

const transformUserList = (
  users: TransformedUser[],
  hasMultipleWorkspaces?: boolean,
): DownloadedUser[] => {
  return users.map(
    ({ userEmail, lastLogin, created, inviteOpen, workspacePermissions }) => {
      let workspacePermissionsText = ''

      if (hasMultipleWorkspaces) {
        Object.keys(workspacePermissions).forEach((workspaceID) => {
          workspacePermissionsText += `${workspacePermissions[workspaceID].workspaceName}: ${workspacePermissions[workspaceID].permission}\n`
        })
      } else {
        workspacePermissionsText =
          workspacePermissions[Object.keys(workspacePermissions)[0]]
            ?.permission || 'N/A'
      }

      return {
        Email: userEmail,
        'Last login date': lastLogin
          ? moment(new Date(lastLogin)).format('YYYY-MM-DD HH:MM:SS')
          : 'N/A',
        'Date added': moment(new Date(created)).format('YYYY-MM-DD HH:MM:SS'),
        'Registration link expired': inviteOpen ? 'N' : 'Y',
        'Workspace permissions': workspacePermissionsText,
      }
    },
  )
}

const getUserListDetails = (userList: DownloadedUser[]) => {
  return {
    numberOfUsers: userList.length,
    numberOfAdmin: userList.filter(
      (user) => user['Workspace permission'] === 'admin',
    ).length,
    numberOfRegular: userList.filter(
      (user) => user['Workspace permission'] === 'regular',
    ).length,
    numberPending: userList.filter(
      (user) =>
        user['Last login date'] === 'N/A' &&
        user['Registration link expired'] === 'N',
    ).length,
    numberExpired: userList.filter(
      (user) => user['Registration link expired'] === 'Y',
    ).length,
  }
}

interface CurrentUsersProps {
  loading?: boolean
  hasMultipleWorkspaces?: boolean
  totalUserCount: number
  users: TransformedUser[]
  loggedInUserWorkspaces: {
    [workspaceID: string]: {
      workspaceName: string
      permission: PermissionLevel | null
    }
  } | null
  error?: boolean
}

const CurrentUsers = ({
  loading,
  hasMultipleWorkspaces,
  totalUserCount,
  users,
  loggedInUserWorkspaces,
  error,
}: CurrentUsersProps) => {
  const { companyName } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const [searchTerm, setSearchTerm] = useState('')

  /** Used to check if current user should be able to delete other users entirely from the company */
  const loggedInUserIsFullAdmin = useMemo(() => {
    if (!loggedInUserWorkspaces) return false

    return Object.values(loggedInUserWorkspaces).every(
      ({ permission }) =>
        permission &&
        ['admin', 'support', 'whitelabelSupport'].includes(permission),
    )
  }, [loggedInUserWorkspaces])

  const [permissionFilter, setPermissionFilter] = useState(permissionOptions)

  const workspaceFilterOptions = useMemo(() => {
    if (!loggedInUserWorkspaces) return []

    return Object.keys(loggedInUserWorkspaces).map((workspaceID) => ({
      label: loggedInUserWorkspaces[workspaceID].workspaceName,
      value: workspaceID,
    }))
  }, [loggedInUserWorkspaces])

  const [workspaceFilter, setWorkspaceFilter] = useState(workspaceFilterOptions)

  const filteredList = useMemo(() => {
    const filteredUsers = users
      // Don't show full support users, and exclude them from the count
      .filter(({ workspacePermissions }) => {
        return !Object.keys(workspacePermissions).every((workspaceID) =>
          ['support', 'whitelabelSupport'].includes(
            workspacePermissions[workspaceID].permission,
          ),
        )
      })
      // Filter by permission, including support users as admins
      .filter(({ workspacePermissions }) => {
        if (permissionFilter.length === 1) {
          return Object.values(workspacePermissions).some(({ permission }) => {
            if (['support', 'whitelabelSupport'].includes(permission)) {
              return permissionFilter[0].value === 'admin'
            }

            return permission === permissionFilter[0].value
          })
        }

        return true
      })
      // Filter by workspace
      .filter(({ workspacePermissions }) => {
        if (
          workspaceFilter.length === workspaceFilterOptions.length ||
          workspaceFilter.length === 0
        ) {
          return true
        }

        return workspaceFilter.some(({ value }) => {
          return !!workspacePermissions[value]
        })
      })

    return fuzzySearch(filteredUsers, 'userEmail', searchTerm)
  }, [users, permissionFilter, workspaceFilter, searchTerm])

  const tableHeaderColumns = useMemo(() => {
    const columns: TableHeaderColumn<TransformedUser>[] = [
      {
        id: 'userEmail',
        content: 'User',
        className: styles.emailColumn,
        columnSortKey: 'userEmail',
      },
      {
        id: 'lastLogin',
        content: 'Last login',
        className: styles.lastLoginColumn,
        columnSortKey: 'lastLogin',
        customSortFn: lastLoginSort,
      },
      { id: 'roles', content: 'Roles', className: styles.permissionsColumn },
    ]

    if (loggedInUserIsFullAdmin) {
      columns.push({
        id: 'delete',
        content: '',
        className: styles.deleteColumn,
      })
    }

    return columns
  }, [loggedInUserIsFullAdmin, users])

  return (
    <>
      <div className={styles.currentUsersHeader}>
        <Heading type={3} align="left">
          Current users
        </Heading>
      </div>
      <div className={styles.tableActions}>
        <SearchInput
          containerClassName={styles.emailSearchInput}
          name="emailSearch"
          loading={loading}
          loadingLabel="Fetching users"
          value={searchTerm}
          onChange={(value) => setSearchTerm(value || '')}
        >
          <p className={styles.greyText}>
            {filteredList.length === totalUserCount
              ? ''
              : `${filteredList.length}/`}
            {totalUserCount} total users
          </p>
        </SearchInput>
        {hasMultipleWorkspaces && (
          <SelectBoxChecklist
            variant="grey"
            className={styles.rhsDropdown}
            allLabel="All workspaces"
            excludeNone
            showOnlyButtons
            isClearable={false}
            value={
              workspaceFilter.length === 0
                ? workspaceFilterOptions
                : workspaceFilter
            }
            options={workspaceFilterOptions}
            onChange={(newValue) => {
              if (newValue.length === 0) return

              setWorkspaceFilter([...newValue])
            }}
            styles={{
              menu: (css) => ({ ...css, minWidth: 275 }),
            }}
          />
        )}
        <SelectBoxChecklist
          variant="grey"
          className={hasMultipleWorkspaces ? undefined : styles.rhsDropdown}
          allLabel="All roles"
          excludeAny
          excludeNone
          isClearable={false}
          value={permissionFilter}
          options={permissionOptions}
          onChange={(newValue) => {
            if (newValue.length === 0) return

            setPermissionFilter([...newValue])
          }}
        />
        <Button
          variant="secondary"
          isDisabled={loading}
          onPress={async () => {
            const selectedUserList = filteredList.flat()

            if (selectedUserList.length === 0) return

            const transformedUserList = transformUserList(
              selectedUserList,
              hasMultipleWorkspaces,
            )

            const csv = getCsvString(transformedUserList)

            await downloadUsersCsv(csv, companyName)

            const interactionDetails = JSON.stringify(
              getUserListDetails(transformedUserList),
            )

            logAction({
              variables: {
                action: 'download-user-list-csv',
                functionName: 'downloadUserList',
                pagePath: '/settings',
                websiteSection: 'Users',
                extra: interactionDetails,
              },
            })
          }}
        >
          Download users
        </Button>
      </div>
      <Table
        tableClassName={styles.usersTable}
        initialSort={{
          sortKey: 'lastLogin',
          sortAsc: false,
          customSortFn: lastLoginSort,
        }}
        headerColumns={tableHeaderColumns}
        loading={loading}
        error={error || (!loggedInUserWorkspaces && !loading)}
        tableData={filteredList}
        rowIDKey="userID"
        noDataMsg="No users matching your filters."
      >
        {(user, userIndex, userRowRef) =>
          loggedInUserWorkspaces ? (
            <UserRow
              hasMultipleWorkspaces={hasMultipleWorkspaces}
              user={user}
              userRowRef={userRowRef}
              loggedInUserWorkspaces={loggedInUserWorkspaces}
              loggedInUserIsFullAdmin={loggedInUserIsFullAdmin}
            />
          ) : (
            <></>
          )
        }
      </Table>
    </>
  )
}

const SettingsUserManagement = () => {
  const { userID, userPermission: loggedInUserPermission } = useReactiveVar(
    currentUserDetails,
  )

  const {
    data: companyUsersData,
    loading: loadingUsers,
    error: errorFetchingUsers,
  } = useQuery(getCompanyUsers)

  /** Transforms data into list of users and the permissions they have at each workspace in the company */
  const {
    fullUserList,
    hasMultipleWorkspaces,
    totalUserCount,
  } = useMemo(() => {
    if (!companyUsersData)
      return {
        fullUserList: [],
        hasMultipleWorkspaces: false,
        totalUserCount: 1,
      }

    const transformedUsersList: TransformedUser[] = []

    companyUsersData.currentCompany.accountList.forEach(
      ({ accountID, accountName, userAccountProfiles }) => {
        userAccountProfiles.forEach((userAccountProfile) => {
          const userIndex = transformedUsersList.findIndex(
            (user) => user.userID === userAccountProfile.userID,
          )

          if (userIndex === -1) {
            transformedUsersList.push({
              userID: userAccountProfile.userID,
              userEmail: userAccountProfile.userEmail,
              created: userAccountProfile.created,
              expired: userAccountProfile.expired,
              inviteOpen: userAccountProfile.inviteOpen,
              lastLogin: userAccountProfile.lastLogin,
              workspacePermissions: {
                [accountID]: {
                  workspaceName: accountName,
                  permission: userAccountProfile.userPermission as PermissionLevel,
                },
              },
            })
          } else {
            // Show lastLogin date for any workspace
            const currentLogin = transformedUsersList[userIndex].lastLogin

            if (
              currentLogin === '' ||
              moment(userAccountProfile.lastLogin).isAfter(currentLogin)
            ) {
              transformedUsersList[userIndex].lastLogin =
                userAccountProfile.lastLogin
            }

            transformedUsersList[userIndex].workspacePermissions[accountID] = {
              workspaceName: accountName,
              permission: userAccountProfile.userPermission as PermissionLevel,
            }
          }
        })
      },
    )

    // Only support users should see other support users
    const filteredUsersList = transformedUsersList.filter((user) => {
      return (
        ['support', 'whitelabelSupport'].includes(loggedInUserPermission) ||
        !Object.values(user.workspacePermissions).every(({ permission }) =>
          ['support', 'whitelabelSupport'].includes(permission),
        )
      )
    })

    return {
      fullUserList: filteredUsersList,
      hasMultipleWorkspaces: companyUsersData.currentCompany.accountCount > 1,
      totalUserCount: companyUsersData.currentCompany.userCount,
    }
  }, [companyUsersData])

  /** Used to check which workspaces the current user can add/edit users to/for */
  const loggedInUserWorkspaces = useMemo(() => {
    if (!companyUsersData) return null

    let loggedInUser = fullUserList.find((user) => user.userID === userID)

    // If current user is support they might not be listed on the company, but they should have access to all workspaces
    if (
      !loggedInUser &&
      ['support', 'whitelabelSupport'].includes(loggedInUserPermission)
    ) {
      loggedInUser = {
        userID,
        userEmail: 'Support',
        // No need to set these
        created: '',
        lastLogin: '',
        expired: false,
        inviteOpen: false,
        workspacePermissions: companyUsersData.currentCompany.accountList.reduce(
          (acc, { accountID, accountName }) => {
            acc[accountID] = {
              workspaceName: accountName,
              permission: 'support',
            }
            return acc
          },
          {},
        ),
      }
    }

    if (!loggedInUser) return null

    const userWorkspaces: {
      [workspaceID: string]: {
        workspaceName: string
        permission: PermissionLevel | null
      }
    } = {}

    companyUsersData.currentCompany.accountList.forEach(
      ({ accountID, accountName }) => {
        userWorkspaces[accountID] = {
          workspaceName: accountName,
          permission:
            loggedInUser?.workspacePermissions[accountID]?.permission || null,
        }
      },
    )

    return userWorkspaces
  }, [companyUsersData, fullUserList])

  return (
    <>
      <Heading type={3} align="left">
        Add users
      </Heading>
      <AddNewUsers
        loading={loadingUsers}
        totalUserCount={totalUserCount}
        loggedInUserWorkspaces={loggedInUserWorkspaces}
        existingUsers={fullUserList.map(({ userEmail }) => userEmail)}
      />
      <CurrentUsers
        loading={loadingUsers}
        hasMultipleWorkspaces={hasMultipleWorkspaces}
        totalUserCount={totalUserCount}
        users={fullUserList}
        loggedInUserWorkspaces={loggedInUserWorkspaces}
        error={!!errorFetchingUsers}
      />
    </>
  )
}

export default SettingsUserManagement
