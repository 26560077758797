import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useQuery } from '@apollo/client'
import { nanoid } from 'nanoid'
import classNames from 'classnames'

import Button, { ClearButton } from './button'
import { BiLine } from './counter'
import FileDragAndDrop from './file-drag-and-drop'
import Input from './input'
import { InputLabel } from './input-v2'
import Modal from './modal'
import QRCodePreview from './qr-code'
import TwoColumns, { Column } from './two-columns'
import { getCurrentAccountQRDetails } from '../api/graphql/company-client'
import { messages, qrSwatchColors } from '../core/constants'
import { getItemByKeyValue, returnUnique } from '../helpers'
import {
  QRFormats,
  defaultBgColour,
  defaultFgColour,
  downloadQRCode,
  qrToBlob,
  sizes,
} from '../helpers/qr-code'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/qr-code-modal.module.scss'

interface ColourPickerProps {
  color: string
  setColor: (val: string) => void
  defaultColour: string
  allowTransparent?: boolean
  onChange?: (val: string) => void
}

const ColourPicker = ({
  color,
  setColor,
  defaultColour,
  allowTransparent = false,
}: ColourPickerProps) => {
  const [key, setKey] = useState(nanoid())
  const [colorHasTransparency, setColorHasTransparency] = useState(false)

  const useColours: string[] = (returnUnique([
    defaultColour,
    ...qrSwatchColors,
  ]) as string[])
    .filter((c) => !['#FFF', '#000'].includes(c))
    .slice(0, 4)

  useEffect(() => {
    setKey(nanoid())
  }, [useColours.length])

  return (
    <>
      <div
        className={classNames(styles.swatchContainerWrapper, {
          [styles.hasWarning]: colorHasTransparency,
        })}
      >
        <div className={styles.swatchContainer}>
          {useColours.map((presetColor) => (
            <Button
              key={presetColor}
              variant="plainBox"
              onPress={() => setColor(presetColor)}
              className={styles.swatch}
              style={{
                backgroundColor: presetColor,
              }}
            />
          ))}
        </div>
        <div className={styles.colorField}>
          <Input
            key={key}
            name="color"
            id="color"
            value={color}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const { value: val } = event.target

              const hexPattern = allowTransparent
                ? // Matches 3, 4, 6 or 8 characters for RGB, RGBA, RRGGBB and RRGGBBAA
                  /^#([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$/i
                : // Matches 3 or 6 characters for RGB and RRGGBB only
                  /^#([0-9A-F]{3}|[0-9A-F]{6})$/i

              if (hexPattern.test(val)) {
                setColor(val)

                setColorHasTransparency(
                  /^#([0-9A-F]{4}|[0-9A-F]{8})$/i.test(val),
                )
              }
            }}
          />
        </div>
      </div>
      {colorHasTransparency && (
        <p className={styles.warningText}>
          This colour includes transparency. Make sure to test your QR code.
        </p>
      )}
    </>
  )
}

interface QRCodeModalProps {
  code: string
  fileDownloadName?: string
  setShowModal: Dispatch<SetStateAction<boolean>>
  section?: string
  showDestination?: boolean
  disableDownload?: boolean
  initialFgColour?: string
  initialBgColour?: string
  onChangeColour?: (type: 'fg' | 'bg', colour: string) => void
}

const QRCodeModal = ({
  code,
  fileDownloadName,
  setShowModal,
  section = 'track-create',
  showDestination = true,
  disableDownload,
  initialFgColour = '',
  initialBgColour = '',
  onChangeColour,
}: QRCodeModalProps) => {
  const logAction = useLogAction()

  const { data: qrData } = useQuery(getCurrentAccountQRDetails)

  const qrCodeRef = useRef<HTMLDivElement>(null)

  const [selectedType, setSelectedType] = useState<QRFormats>('png')
  const [selectedSize, setSelectedSize] = useState('300')
  const [showLogo, setShowLogo] = useState(true)
  const [logoImage, setLogoImage] = useState('')
  const [fgColour, setFgColour] = useState(initialFgColour)
  const [bgColour, setBgColour] = useState(initialBgColour)
  const [showUploader, setShowUploader] = useState(false)
  const [uploadError, setUploadError] = useState('')

  const codeIsTooLong = useMemo(() => {
    return code.length > 1024
  }, [code])

  // Get colours for swatches
  const { accountFgColour, accountBgColour } = useMemo(() => {
    if (
      !qrData ||
      !qrData.currentAccount.qrSettings ||
      qrData.currentAccount.qrSettings.length === 0
    ) {
      return {
        accountFgColour: defaultFgColour,
        accountBgColour: defaultBgColour,
      }
    }

    const { qrSettings } = qrData.currentAccount

    const useFgColour = qrSettings.find((s) => s.name === 'fgColour')
    const useBgColour = qrSettings.find((s) => s.name === 'bgColour')

    return {
      accountFgColour: useFgColour?.value || defaultFgColour,
      accountBgColour: useBgColour?.value || defaultBgColour,
    }
  }, [qrData])

  return (
    <Modal
      setIsOpen={setShowModal}
      modalHeader="Customise QR code"
      yesText={codeIsTooLong ? undefined : 'Download QR code'}
      yesButtonDisabled={codeIsTooLong || code === ''}
      onYes={
        codeIsTooLong || disableDownload
          ? undefined
          : async () => {
              if (qrCodeRef && qrCodeRef.current) {
                const blob = await qrToBlob(
                  qrCodeRef.current,
                  selectedType,
                  bgColour || accountBgColour,
                )

                const downloaded = await downloadQRCode(
                  blob,
                  selectedType,
                  fileDownloadName || code,
                )

                if (downloaded) {
                  const fileSize = getItemByKeyValue(
                    sizes,
                    'value',
                    selectedSize,
                  )

                  logAction({
                    variables: {
                      action: `qr-code-download-${section}`,
                      extra: JSON.stringify({
                        fgColour,
                        bgColour,
                        showLogo,
                        fileType: selectedType,
                        dimensions: fileSize !== -1 ? fileSize.label : '',
                      }),
                      websiteSection: 'track',
                      pagePath: window.location.pathname,
                      functionName: 'download',
                    },
                  })
                }
              }
            }
      }
      footerContent={
        !codeIsTooLong && !disableDownload ? (
          <BiLine arrowRight>
            Always test your <strong>QR code</strong> before use!
          </BiLine>
        ) : undefined
      }
    >
      {codeIsTooLong ? (
        <p>
          Your url is longer than maximum characters we can support (max 1024
          characters). Please use a short link.
        </p>
      ) : (
        <TwoColumns>
          <Column main transparent equal>
            <InputLabel>Preview</InputLabel>
            <QRCodePreview
              ref={qrCodeRef}
              url={code}
              qrType={selectedType === 'svg' ? 'svg' : 'canvas'}
              size={
                selectedType === 'svg' ? 600 : window.parseInt(selectedSize, 10)
              }
              imageSrc={logoImage}
              showLogo={showLogo}
              fgColour={fgColour}
              bgColour={bgColour}
              disableCopy={disableDownload}
              siteSection={section}
            >
              {disableDownload && (
                <BiLine className={styles.qrPreviewText}>Preview</BiLine>
              )}
            </QRCodePreview>
            {!disableDownload && (
              <div
                className={classNames(styles.checkboxRow, styles.inlineTitle)}
              >
                <Input
                  prefix="Logo"
                  type="checkbox"
                  id="showLogo"
                  name="showLogo"
                  checked={showLogo}
                  label=" "
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = e.target as HTMLInputElement
                    setShowLogo(checked)

                    logAction({
                      variables: {
                        action: checked ? 'include-qr-logo' : 'exclude-qr-logo',
                        extra: '',
                        websiteSection: 'track',
                        pagePath: window.location.pathname,
                        functionName: 'includeExcludeQrLogo',
                      },
                    })
                  }}
                />
                {!showUploader && (
                  <Button
                    onPress={() => {
                      setShowUploader(true)
                    }}
                    variant="secondary"
                    style={{ height: 40 }}
                  >
                    Replace logo
                  </Button>
                )}
                {/* Images used in this modal are single use - do not save */}
                {showUploader && (
                  <div className={styles.fileUploader}>
                    <FileDragAndDrop
                      className={styles.dragAndDrop}
                      uploadButtonText="Upload image"
                      uploadError={uploadError}
                      onDrop={async (files) => {
                        setUploadError('')

                        if (files.length > 0) {
                          const file = files.pop()

                          if (file) {
                            const { type, size } = file

                            if (
                              type &&
                              size &&
                              type.indexOf('image/') !== -1 &&
                              size < 1038383
                            ) {
                              const reader = new FileReader()

                              reader.addEventListener('load', () => {
                                if (typeof reader.result === 'string') {
                                  setLogoImage(reader.result)
                                }

                                setShowUploader(false)
                              })

                              reader.readAsDataURL(file)
                            } else {
                              setUploadError(messages.fileUploadErrorImageOnly)
                            }
                          }
                        } else {
                          setUploadError(messages.fileUploadError)
                        }
                      }}
                    />
                    <ClearButton
                      className={styles.cancelButton}
                      onPress={() => setShowUploader(false)}
                    >
                      Cancel
                    </ClearButton>
                  </div>
                )}
              </div>
            )}
            <InputLabel>Shape colour</InputLabel>
            <ColourPicker
              defaultColour={accountFgColour}
              color={fgColour || accountFgColour}
              setColor={(c) => {
                setFgColour(c)

                if (onChangeColour) {
                  onChangeColour('fg', c)
                }

                logAction({
                  variables: {
                    action: 'update-qr-fg-colour',
                    extra: c,
                    websiteSection: 'track',
                    pagePath: window.location.pathname,
                    functionName: 'updateQrFgColour',
                  },
                })
              }}
            />
            <InputLabel>Background colour</InputLabel>
            <ColourPicker
              defaultColour={accountBgColour}
              allowTransparent
              color={bgColour || accountBgColour}
              setColor={(c) => {
                setBgColour(c)

                if (onChangeColour) {
                  onChangeColour('bg', c)
                }

                logAction({
                  variables: {
                    action: 'update-qr-bg-colour',
                    extra: c,
                    websiteSection: 'track',
                    pagePath: window.location.pathname,
                    functionName: 'updateQrBgColour',
                  },
                })
              }}
            />
          </Column>
          <Column main transparent equal>
            {showDestination && (
              <div className={styles.row}>
                <InputLabel>Destination</InputLabel>
                {code.length <= 140 ? (
                  <p className={styles.passiveInput}>{code}</p>
                ) : (
                  <Input
                    className={styles.passiveInput}
                    readOnly
                    name="code"
                    id="code"
                    value={code}
                  />
                )}
              </div>
            )}
            {!disableDownload && (
              <>
                <div className={styles.row}>
                  <InputLabel>File type</InputLabel>
                  <div className={styles.alignRow}>
                    <Input
                      name="type"
                      id={nanoid()}
                      label="PNG"
                      type="radio"
                      value="png"
                      checked={selectedType === 'png'}
                      onClick={(e): any => {
                        e.preventDefault()

                        setSelectedType('png')

                        logAction({
                          variables: {
                            action: 'update-qr-filetype-png',
                            extra: '',
                            websiteSection: 'track',
                            pagePath: window.location.pathname,
                            functionName: 'updateQrFileType',
                          },
                        })
                      }}
                    />
                    <Input
                      name="type"
                      id={nanoid()}
                      label="JPG"
                      type="radio"
                      value="jpg"
                      checked={selectedType === 'jpg'}
                      onClick={(e): any => {
                        e.preventDefault()

                        setSelectedType('jpg')

                        logAction({
                          variables: {
                            action: 'update-qr-filetype-jpg',
                            extra: '',
                            websiteSection: 'track',
                            pagePath: window.location.pathname,
                            functionName: 'updateQrFileType',
                          },
                        })
                      }}
                    />
                    <Input
                      name="type"
                      id={nanoid()}
                      label="SVG"
                      type="radio"
                      value="png"
                      checked={selectedType === 'svg'}
                      onClick={(e): any => {
                        e.preventDefault()

                        setSelectedType('svg')

                        logAction({
                          variables: {
                            action: 'update-qr-filetype-svg',
                            extra: '',
                            websiteSection: 'track',
                            pagePath: window.location.pathname,
                            functionName: 'updateQrFileType',
                          },
                        })
                      }}
                    />
                  </div>
                </div>
                {selectedType !== 'svg' && (
                  <div className={styles.row}>
                    <InputLabel>Image dimensions</InputLabel>
                    {sizes.map((size) => (
                      <Input
                        name="size"
                        id={nanoid()}
                        key={nanoid()}
                        label={size.label}
                        type="radio"
                        value={size.value}
                        checked={selectedSize === size.value}
                        onClick={() => {
                          setSelectedSize(size.value)

                          const fileSize = getItemByKeyValue(
                            sizes,
                            'value',
                            size.value,
                          )

                          logAction({
                            variables: {
                              action: `update-qr-dimension-${
                                fileSize !== -1 ? fileSize.size : ''
                              }`,
                              extra: fileSize !== -1 ? fileSize.label : '',
                              websiteSection: 'track',
                              pagePath: window.location.pathname,
                              functionName: 'updateQrSize',
                            },
                          })
                        }}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </Column>
        </TwoColumns>
      )}
    </Modal>
  )
}

export default QRCodeModal
