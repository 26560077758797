import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import classNames from 'classnames'
import _ from 'lodash'
import numeral from 'numeraljs'

import Button from './button'
import { ButtonRow } from './button-row'
import Loader from './loader'
import NoDataMessage from './no-data-message'
import { SelectBoxSimple } from './select-box'
import Tooltip from './tooltip'
import TopScrollbar from './top-scrollbar'
import { sortData } from '../helpers'
import useMobile from '../hooks/useMobile'
import useResize from '../hooks/useResize'
import styles from '../styles/table-v2.module.scss'

type RowsPerPage = 10 | 25 | 50 | 100 | 150

interface OrderArrowProps {
  currentKey: string
  sortKey: string
  orderAsc?: boolean
  className?: string
  /** Allow for bidirectional arror */
  isBidirectional?: boolean
}

const OrderArrow = ({
  currentKey,
  sortKey,
  orderAsc,
  isBidirectional,
}: OrderArrowProps) => {
  if (currentKey === sortKey) {
    const elementClassName = classNames(styles.orderArrow, {
      [styles.ascOrder]: orderAsc,
      [styles.arrowTypeBi]: isBidirectional,
    })
    return <span className={elementClassName} />
  }

  return null
}

interface TablePaginationPageProps {
  className?: string
  page?: number
  activePage?: number
  onChange?: any
}

const TablePaginationPage = ({
  className,
  page,
  activePage,
  onChange,
}: TablePaginationPageProps) => {
  return (
    <div
      className={classNames(className, styles.paginationElement, {
        [styles.activePagination]: page === activePage,
      })}
    >
      <Button color="none" onPress={() => onChange(page)}>
        {numeral(page).format('0,0')}
      </Button>
    </div>
  )
}

interface TablePaginationRowsPerPageProps {
  rowsPerPage: number
  activePage: number
  totalRows: number
  maxRowsPerPage?: Omit<RowsPerPage, 10>
  onChange: (newRowsPerPage: RowsPerPage) => void
}

const TablePaginationRowsPerPage = ({
  rowsPerPage,
  activePage,
  totalRows,
  maxRowsPerPage = 150,
  onChange,
}: TablePaginationRowsPerPageProps) => {
  const firstItemIndex = rowsPerPage ? rowsPerPage * (activePage - 1) + 1 : 1

  return (
    <span className={styles.rowsPerPageWrapper}>
      <span className={styles.rowsPerPageSummary}>
        {numeral(firstItemIndex).format('0,0')}-
        {numeral(
          numeral(Math.min(totalRows, firstItemIndex + rowsPerPage - 1)).format(
            '0,0',
          ),
        ).format('0,0')}{' '}
        of {numeral(totalRows).format('0,0')}
      </span>
      <SelectBoxSimple
        value={rowsPerPage.toString()}
        onChange={(newValue) => onChange(parseInt(newValue, 10) as RowsPerPage)}
      >
        <option value="10">10</option>
        {(maxRowsPerPage as number) >= 25 && totalRows > 10 && (
          <option value="25">25</option>
        )}
        {(maxRowsPerPage as number) >= 50 && totalRows > 25 && (
          <option value="50">50</option>
        )}
        {(maxRowsPerPage as number) >= 100 && totalRows > 50 && (
          <option value="100">100</option>
        )}
        {(maxRowsPerPage as number) >= 150 && totalRows > 100 && (
          <option value="150">150</option>
        )}
      </SelectBoxSimple>
    </span>
  )
}

interface TablePaginationProps {
  pages: number
  activePage: number
  onChangePage: (newPage: number) => void
  rowsPerPage: number
  maxRowsPerPage?: Omit<RowsPerPage, 10>
  totalRows: number
  onChangeRowsPerPage: (newRowsPerPage: RowsPerPage) => void
}

const TablePagination = ({
  pages,
  activePage,
  onChangePage,
  rowsPerPage,
  maxRowsPerPage,
  totalRows,
  onChangeRowsPerPage,
}: TablePaginationProps) => {
  let leftPart =
    activePage === 1 ? [1, 2, 3] : [activePage - 1, activePage, activePage + 1]

  let showMiddle = true

  if (activePage + 3 >= pages - 2) {
    leftPart = [pages - 6, pages - 5, pages - 4, pages - 3]
    showMiddle = false
  }

  const rightPart = [pages - 2, pages - 1, pages]

  return (
    <ButtonRow className={styles.paginationRow}>
      {pages > 1 && (
        <div className={classNames(styles.paginationWrapper)}>
          <Button
            variant="secondary"
            isDisabled={activePage === 1}
            onPress={() => onChangePage(1)}
          >
            First
          </Button>
          <div className={styles.paginationMiddle}>
            <Button
              color="none"
              isDisabled={activePage === 1}
              className={styles.previousButton}
              onPress={() => onChangePage(activePage - 1)}
            />
            <div className={styles.paginationMiddleMobile}>
              <span>
                {activePage}/{pages}
              </span>
            </div>
            <div className={styles.paginationMiddlePages}>
              {leftPart.map((page: number) => {
                if (page <= 0) return null

                return (
                  <TablePaginationPage
                    key={`page-${page}`}
                    page={page}
                    activePage={activePage}
                    onChange={onChangePage}
                  />
                )
              })}
              {showMiddle && (
                <div className={styles.paginationElement}>
                  <Button isDisabled>...</Button>
                </div>
              )}
              {rightPart.map((page: number) => {
                if (page <= 0) return null

                return (
                  <TablePaginationPage
                    key={`page-${page}`}
                    page={page}
                    activePage={activePage}
                    onChange={onChangePage}
                  />
                )
              })}
            </div>
            <Button
              color="none"
              isDisabled={activePage === pages}
              className={styles.nextButton}
              onPress={() => onChangePage(activePage + 1)}
            />
          </div>
          <Button
            variant="secondary"
            isDisabled={activePage === pages}
            onPress={() => onChangePage(pages)}
          >
            Last
          </Button>
        </div>
      )}
      {rowsPerPage && (
        <TablePaginationRowsPerPage
          rowsPerPage={rowsPerPage}
          activePage={activePage}
          totalRows={totalRows}
          maxRowsPerPage={maxRowsPerPage}
          onChange={onChangeRowsPerPage}
        />
      )}
    </ButtonRow>
  )
}

interface TableLoadingRowsProps {
  colCount?: number
}

const TableLoadingRows = ({ colCount = 4 }: TableLoadingRowsProps) => {
  const placeholderRow = (key: string, rowIndex: number) => {
    return (
      <tr
        key={key}
        className={classNames(styles.placeholderRow, {
          [styles.oddRow]: rowIndex % 2 === 0,
        })}
      >
        {Array.from(new Array(colCount), (col, index) => index).map((item) => {
          return (
            <td key={`loadingCol-${item}`}>
              <Loader className={styles.loadingText} />
            </td>
          )
        })}
      </tr>
    )
  }

  return (
    <>
      {[0, 1, 2, 3, 4].map((item, rowIndex) => {
        return placeholderRow(`loadingRow-${item}`, rowIndex)
      })}
    </>
  )
}

export type TableSortFn<RowData> = (
  row: RowData,
  orderAsc?: boolean,
  key?: string,
) => number | string | Date

export interface TableHeaderColumn<RowData extends {}> {
  className?: string
  id: string
  content: string | React.ReactElement
  /** Appears on hover on the table */
  tooltip?: string | React.ReactElement
  tooltipClassName?: string
  columnSortKey?: string
  bidirectionalSortKeys?: string[]
  /** Apply custom logic to how data should be sorted on this column. Should include bidirectional handling */
  customSortFn?: TableSortFn<RowData>
}

interface TableProps<RowData extends {}> {
  containerClassName?: string
  tableClassName?: string
  tableRowClassName?: string
  initialSort?: {
    sortKey: string
    sortAsc: boolean
    bidirectionalSortKey?: string
    customSortFn?: TableSortFn<RowData>
  }
  fixedHeader?: boolean
  fixedFooter?: boolean
  /** Array of the keys of RowData that should be used as columns */
  headerColumns: TableHeaderColumn<RowData>[]
  loading?: boolean
  error?: boolean
  tableData: RowData[]
  rowIDKey: keyof RowData
  startingRowsPerPage?: RowsPerPage
  maxRowsPerPage?: Omit<RowsPerPage, 10>
  /** Custom handler for when sort is applied */
  onSort?: (sortedData: {
    sortKey: string
    sortAsc: boolean
    bidirectionalSortKey?: string
    sortedData: RowData[]
  }) => void
  noDataMsg?: string | React.ReactElement
  /** Custom rows (&lt;tr&gt;) that can appear before rows from tableData */
  topRows?: React.ReactNode
  /** Custom rows (&lt;tr&gt;) that can appear after rows from tableData */
  footerRows?: React.ReactNode
  /** Should be used to apply custom handlers/logic to table pagination. Useful e.g. when table content is loaded asynchronously with pagination but total rows is known */
  customPagination?: {
    activePage?: number
    forceTotalRows?: number
    onChangePage?: (newPage: number) => void
    onChangeRowsPerPage?: (newRowsPerPage: RowsPerPage) => void
  }
  /** Represents one row in the table. All elements of tableData will be mapped to this */
  children: (
    rowItem: RowData,
    rowIndex: number,
    rowRef: React.RefObject<HTMLTableRowElement>,
  ) => React.ReactElement
  /** Quick switch to turn off virtualization */
  virtualized?: boolean
}

/** Styled table with built-in pagination and sorting */
const Table = <RowData extends {}>({
  containerClassName,
  tableClassName,
  tableRowClassName,
  initialSort,
  fixedHeader,
  fixedFooter,
  headerColumns,
  loading,
  error,
  tableData,
  rowIDKey,
  startingRowsPerPage = 10,
  maxRowsPerPage,
  onSort,
  noDataMsg,
  topRows,
  footerRows,
  customPagination,
  children,
  virtualized,
}: TableProps<RowData>) => {
  const hasMobileHeader = useMobile()
  const windowHeight = useResize('height')

  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)
  const tableHeaderRef = useRef<HTMLTableSectionElement>(null)
  const fixedScrollRef = useRef<HTMLDivElement>(null)

  const [showFixedFooter, setShowFixedFooter] = useState(false)

  // Show the fixed footer only if the table runs off the bottom of the screen
  useEffect(() => {
    if (!fixedFooter || !tableContainerRef.current) return

    const { offsetHeight, offsetTop } = tableContainerRef.current

    setShowFixedFooter(offsetTop + offsetHeight > window.innerHeight - 100)
  }, [loading, fixedFooter, tableRef.current?.offsetHeight, windowHeight])

  const [isFixedScroll, setIsFixedScroll] = useState(false)

  const fixedScroll = (e) => {
    if (isFixedScroll && tableContainerRef.current) {
      const bitToScroll = tableContainerRef.current.querySelector(
        'div[class*="doubleScroll"]',
      )

      if (bitToScroll) {
        bitToScroll.scrollLeft = e.target.scrollLeft
      }
    }
  }

  const mainScroll = (e) => {
    if (!isFixedScroll && fixedScrollRef.current && fixedScrollRef.current) {
      fixedScrollRef.current.scrollLeft = e.target.scrollLeft
    }
  }

  const [currentSort, setCurrentSort] = useState(initialSort || null)
  const [activePage, setActivePage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(startingRowsPerPage)

  const [sortedTableData, setSortedTableData] = useState<RowData[]>([])

  // Reset sorted data when tableData changes
  useEffect(() => {
    if (currentSort) {
      const newSortedData = sortData(
        tableData,
        currentSort.sortKey,
        currentSort.sortAsc,
        currentSort.customSortFn,
        true,
      )

      setSortedTableData(newSortedData)
    } else {
      setSortedTableData(tableData)
    }
  }, [tableData])

  // Chunks the sorted data into pages
  const orderedList = useMemo(() => {
    const _orderedList: RowData[][] = []

    for (let index = 0; index < sortedTableData.length; index += rowsPerPage) {
      _orderedList.push(sortedTableData.slice(index, index + rowsPerPage))
    }

    return _orderedList
  }, [rowsPerPage, sortedTableData])

  const totalPages = useMemo(() => {
    if (customPagination && customPagination.forceTotalRows) {
      return Math.ceil(customPagination.forceTotalRows / rowsPerPage)
    }

    return orderedList.length
  }, [orderedList, rowsPerPage, customPagination])

  // Fix table header to top of screen on scroll
  useEffect(() => {
    if (!fixedHeader) return () => null

    const onScroll = () => {
      if (
        tableContainerRef.current &&
        tableRef.current &&
        tableHeaderRef.current
      ) {
        const fromTop = tableContainerRef.current.getBoundingClientRect().top

        let offset = fromTop

        if (hasMobileHeader) offset -= 60

        const topScrollbar = tableContainerRef.current.querySelector(
          "div[class*='doubleScroll']",
        )

        if (
          tableRef.current.offsetWidth > tableContainerRef.current.offsetWidth
        ) {
          if (topScrollbar) {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ;(topScrollbar as HTMLElement).style.setProperty(
              'transform',
              `translateY(${-offset}px)`,
            )
          }
        }

        if (fromTop < 0) {
          tableHeaderRef.current.style.setProperty(
            'transform',
            `translateY(${-offset}px)`,
          )
        } else {
          tableHeaderRef.current.style.removeProperty('transform')

          if (topScrollbar) {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ;(topScrollbar as HTMLElement).style.removeProperty('transform')
          }
        }
      }
    }

    // clean up listeners
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, {
      passive: true,
    })
    return () => window.removeEventListener('scroll', onScroll)
  }, [
    fixedHeader,
    hasMobileHeader,
    tableContainerRef.current,
    tableRef.current,
    tableHeaderRef.current,
  ])

  /* ********************************************************************************** */
  // Virtualization
  /* ********************************************************************************** */
  const topRowsRefs = useRef<React.RefObject<HTMLElement>[]>([])
  const footerRowsRefs = useRef<React.RefObject<HTMLElement>[]>([])

  const [extraRowsHeight, setExtraRowsHeight] = useState(0)

  // Add extra rows height to the table
  useEffect(() => {
    let topRowsHeight = 0
    let footerRowsHeight = 0

    if (topRows) {
      const topRowsChildren = React.Children.toArray(
        topRows,
      ) as React.ReactElement[]
      topRowsRefs.current = topRowsChildren.map(() =>
        React.createRef<HTMLElement>(),
      )

      const heights = topRowsRefs.current.map(
        (ref) => ref.current?.offsetHeight || 60,
      )
      topRowsHeight = heights.reduce((acc, height) => acc + height, 0)
    }

    if (footerRowsHeight) {
      const footerRowsChildren = React.Children.toArray(
        footerRows,
      ) as React.ReactElement[]
      footerRowsRefs.current = footerRowsChildren.map(() =>
        React.createRef<HTMLElement>(),
      )

      const heights = footerRowsRefs.current.map(
        (ref) => ref.current?.offsetHeight || 60,
      )
      footerRowsHeight = heights.reduce((acc, height) => acc + height, 0)
    }

    setExtraRowsHeight(topRowsHeight + footerRowsHeight)
  }, [topRows, footerRows])

  const virtualizer = useWindowVirtualizer({
    count: orderedList[activePage - 1]?.length ?? startingRowsPerPage,
    estimateSize: () => 60,
    overscan: 2,
    // getScrollElement: () => tableContainerRef.current,
    scrollMargin: tableContainerRef.current?.offsetTop ?? 0,
  })

  // All elements in the rendered table should be translated based on the position of the first rendered row
  const firstItem = virtualizer.getVirtualItems()[0]
  const translateY = firstItem?.start ?? 0
  /* ********************************************************************************** */

  return (
    <div className={classNames({ [styles.fixedFooter]: showFixedFooter })}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        ref={tableContainerRef}
        className={classNames(containerClassName, styles.tableContainer)}
        onMouseDown={() => setIsFixedScroll(false)}
      >
        <TopScrollbar
          scrollInnerRef={tableRef}
          scrollHandle={mainScroll}
          disabled={
            (error || sortedTableData.length === 0) && !topRows && !footerRows
          }
        >
          <div
            style={
              virtualized
                ? {
                    height:
                      !loading && !error
                        ? `${
                            virtualizer.getTotalSize() +
                            (tableHeaderRef.current?.offsetHeight ?? 60) +
                            extraRowsHeight
                          }px`
                        : undefined,
                  }
                : undefined
            }
          >
            <table
              ref={tableRef}
              className={classNames(tableClassName, styles.table)}
            >
              <thead
                ref={tableHeaderRef}
                className={classNames({
                  [styles.fixedHeader]: fixedHeader,
                })}
              >
                <tr className={classNames(tableRowClassName)}>
                  {headerColumns.map((headerColumn) => {
                    const { sortKey, sortAsc } = currentSort || {}

                    const {
                      className: columnClassName,
                      id,
                      content,
                      tooltip,
                      tooltipClassName,
                      columnSortKey,
                      bidirectionalSortKeys,
                      customSortFn,
                    } = headerColumn

                    return (
                      <th
                        key={id}
                        id={id}
                        className={classNames(
                          columnClassName,
                          styles.headerColumn,
                          {
                            [styles.sortable]: !!columnSortKey,
                          },
                        )}
                        onClick={
                          columnSortKey
                            ? () => {
                                // Switching sort columns should initialise them to descending
                                let newSortAsc = false

                                let newBidirectionalSortKey:
                                  | string
                                  | null = null

                                if (bidirectionalSortKeys) {
                                  // Bidirectional should always be ascending
                                  newSortAsc = true

                                  // Move to the next key in the bidirectional array
                                  const currentIndex = bidirectionalSortKeys.indexOf(
                                    currentSort?.bidirectionalSortKey || '',
                                  )

                                  newBidirectionalSortKey =
                                    bidirectionalSortKeys[
                                      (currentIndex + 1) %
                                        bidirectionalSortKeys.length
                                    ]
                                } else if (columnSortKey === sortKey) {
                                  // Swap the order if current column is already sorted
                                  newSortAsc = !sortAsc
                                }

                                const newSortedData = sortData(
                                  tableData,
                                  columnSortKey,
                                  newSortAsc,
                                  // Should handle bidrectional sorts - not possible from this component
                                  customSortFn,
                                  true,
                                )

                                setActivePage(1)
                                setCurrentSort({
                                  sortKey: columnSortKey,
                                  sortAsc: newSortAsc,
                                  bidirectionalSortKey:
                                    newBidirectionalSortKey || undefined,
                                  customSortFn,
                                })
                                setSortedTableData(newSortedData)

                                // Apply custom sort handler
                                if (onSort) {
                                  onSort({
                                    sortKey: columnSortKey,
                                    sortAsc: newSortAsc,
                                    bidirectionalSortKey:
                                      newBidirectionalSortKey || undefined,
                                    sortedData: newSortedData,
                                  })
                                }
                              }
                            : undefined
                        }
                      >
                        <div>
                          <Tooltip
                            id={`${id}-tooltip`}
                            useIcon
                            tooltipClassName={tooltipClassName}
                            tooltipPosition="bottom"
                            tooltipPositionStrategy="fixed"
                            tooltipMessage={tooltip}
                          >
                            {content}
                          </Tooltip>
                          {columnSortKey && sortKey && (
                            <OrderArrow
                              currentKey={columnSortKey}
                              sortKey={sortKey}
                              orderAsc={sortAsc}
                              isBidirectional={!!bidirectionalSortKeys}
                            />
                          )}
                        </div>
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {loading && !error ? (
                  <TableLoadingRows colCount={headerColumns.length || 1} />
                ) : (
                  <>
                    {topRows}
                    {sortedTableData.length > 0 && !error ? (
                      <>
                        {virtualized ? (
                          <>
                            {virtualizer.getVirtualItems().map((virtualRow) => {
                              const rowItem =
                                orderedList[activePage - 1][virtualRow.index]
                              const rowRef = React.createRef<
                                HTMLTableRowElement
                              >()

                              return (
                                <tr
                                  key={rowItem[rowIDKey] as string}
                                  data-index={virtualRow.index}
                                  ref={virtualizer.measureElement}
                                  className={classNames(tableRowClassName, {
                                    [styles.oddRow]: virtualRow.index % 2 === 0,
                                  })}
                                  style={{
                                    height: `${virtualRow.size}px`,
                                    transform: `translateY(${
                                      translateY -
                                      virtualizer.options.scrollMargin
                                    }px)`,
                                  }}
                                >
                                  {children(rowItem, virtualRow.index, rowRef)}
                                </tr>
                              )
                            })}
                          </>
                        ) : (
                          <>
                            {orderedList[activePage - 1].map(
                              (rowItem, rowIndex) => {
                                const rowRef = React.createRef<
                                  HTMLTableRowElement
                                >()

                                return (
                                  <tr
                                    key={rowItem[rowIDKey] as string}
                                    ref={rowRef}
                                    className={classNames(tableRowClassName, {
                                      [styles.oddRow]: rowIndex % 2 === 0,
                                    })}
                                  >
                                    {children(rowItem, rowIndex, rowRef)}
                                  </tr>
                                )
                              },
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {!topRows && !footerRows && (
                          <tr>
                            <td colSpan={100}>
                              <div
                                style={{
                                  width:
                                    tableContainerRef?.current?.offsetWidth,
                                }}
                              >
                                <NoDataMessage
                                  className={styles.noData}
                                  errorMsg={noDataMsg || 'No data available.'}
                                  showSupportLink={false}
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                    {footerRows}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </TopScrollbar>
      </div>
      {totalPages > 1 && (
        <div className={styles.paginationContainer}>
          {fixedFooter && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              className={styles.stickyScroll}
              ref={fixedScrollRef}
              onScroll={fixedScroll}
              onMouseDown={() => setIsFixedScroll(true)}
            >
              <div
                className={styles.topScroll}
                style={{ width: `${tableRef.current?.offsetWidth ?? 0}px` }}
              />
            </div>
          )}
          <TablePagination
            pages={totalPages}
            activePage={customPagination?.activePage || activePage}
            rowsPerPage={rowsPerPage}
            totalRows={customPagination?.forceTotalRows || tableData.length}
            onChangePage={(index) => {
              if (customPagination && customPagination.onChangePage) {
                customPagination.onChangePage(index)

                // Do not change this component's active page if custom pagination is used
                // The page might not exist in the orderedList
                // We therefore use customPagination.activePage to determine the active page
                return
              }

              setActivePage(index)
            }}
            onChangeRowsPerPage={(newRowsPerPage) => {
              setActivePage(1)
              setRowsPerPage(newRowsPerPage)

              if (customPagination && customPagination.onChangeRowsPerPage) {
                customPagination.onChangeRowsPerPage(newRowsPerPage)
              }
            }}
            maxRowsPerPage={maxRowsPerPage}
          />
        </div>
      )}
    </div>
  )
}

export default Table
