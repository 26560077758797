import React, { useEffect, useMemo, useRef, useState } from 'react'
import Select, {
  ClearIndicatorProps,
  GroupBase,
  GroupHeadingProps,
  MenuListProps,
  MultiValue,
  MultiValueProps,
  OptionProps,
  Props,
  SingleValueProps,
  components,
} from 'react-select'
import { useVirtualizer } from '@tanstack/react-virtual'
import classNames from 'classnames'
import _ from 'lodash'

import Button from './button'
import { LoadingLabel, Preloader } from './loader'
import Tooltip from './tooltip'
import ClearIcon from '../assets/icon-close-grey.svg'
import styles from '../styles/select-box.module.scss'

interface SelectBoxSimpleProps
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> {
  label?: string
  onChange?: (val: string) => void
}

export function SelectBoxSimple({
  label = '',
  onChange,
  className,
  ...props
}: SelectBoxSimpleProps) {
  const inner = () => (
    <span className={styles.innerWrapper}>
      <select
        {...props}
        className={styles.selector}
        onChange={(e) => {
          if (onChange) {
            const { value: val } = e.target

            onChange(val)
          }
        }}
      />
    </span>
  )

  return label ? (
    <label
      className={classNames(styles.selectorWrapper, className)}
      htmlFor={props.name}
    >
      <span className={styles.label}>{label}</span>
      {inner()}
    </label>
  ) : (
    <span className={classNames(styles.selectorWrapper, className)}>
      {inner()}
    </span>
  )
}

export type PillColors = 'primary' | 'green' | 'red' | 'grey'

interface OptionTypeBase {
  label?: string
  value?: string
  icon?: string
  tooltip?: React.ReactNode
  pillColor?: PillColors
  [key: string]: any
}

interface GroupTypeBase<T> extends GroupBase<T> {
  icon?: string
}

function optionIsGroup(
  option: OptionTypeBase | GroupTypeBase<OptionTypeBase>,
): option is GroupTypeBase<OptionTypeBase> {
  return Object.prototype.hasOwnProperty.call(option, 'options')
}

const CustomClearIndicator = <
  Option extends OptionTypeBase,
  IsMulti extends boolean = false
>(
  props: ClearIndicatorProps<Option, IsMulti>,
) => {
  return (
    <components.ClearIndicator {...props} className={styles.clearIcon}>
      <img src={ClearIcon} alt="Clear" />
    </components.ClearIndicator>
  )
}

interface CustomSingleValueProps<
  Option extends OptionTypeBase,
  IsMulti extends boolean = boolean
> extends SingleValueProps<Option, IsMulti> {
  hasIcon?: boolean
}

const CustomSingleValue = <
  Option extends OptionTypeBase,
  IsMulti extends boolean = false
>({
  hasIcon = false,
  children,
  getValue,
  ...props
}: CustomSingleValueProps<Option, IsMulti>) => {
  const iconValue = useMemo(() => {
    if (!hasIcon) return null

    return getValue()[0].icon
  }, [hasIcon])

  return (
    <components.SingleValue getValue={getValue} {...props}>
      {iconValue && (
        <img src={iconValue} alt="icon path" className={styles.icon} />
      )}
      {children}
    </components.SingleValue>
  )
}

interface CustomOptionProps<
  Option extends OptionTypeBase,
  IsMulti extends boolean = boolean
> extends OptionProps<Option, IsMulti> {
  valueKey?: string
  hasIcon?: boolean
  hasTooltip?: boolean
}

const CustomOption = <
  Option extends OptionTypeBase,
  IsMulti extends boolean = false
>({
  valueKey = 'value',
  hasIcon,
  hasTooltip,
  children,
  data,
  ...props
}: CustomOptionProps<Option, IsMulti>) => {
  const parentRef = useRef<HTMLDivElement>(null)

  const iconValue = useMemo(() => {
    if (!hasIcon) return null

    return data.icon
  }, [hasIcon])

  const tooltipValue = useMemo(() => {
    if (!hasTooltip) return null

    return data.tooltip
  }, [hasTooltip])

  return (
    <components.Option data={data} {...props}>
      <div
        ref={parentRef}
        className={styles.optionInner}
        style={{ maxWidth: '100%' }}
      >
        {iconValue && (
          <img src={iconValue} alt="icon path" className={styles.icon} />
        )}
        <div>
          <span
            onMouseOver={(e) => {
              if (
                parentRef.current &&
                parentRef.current.offsetWidth <=
                  e.currentTarget.offsetWidth + 20
              ) {
                const padding =
                  (parentRef.current.parentElement as HTMLElement).offsetWidth -
                  parentRef.current.offsetWidth

                const translateX =
                  e.currentTarget.offsetWidth -
                  parentRef.current.offsetWidth +
                  padding +
                  2

                e.currentTarget.style.transform = `translateX(${-translateX}px)`
              }
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = ''
            }}
          >
            {tooltipValue ? (
              <>
                <Tooltip
                  id={`select-box-tooltip-${data[valueKey]}`}
                  tooltipMessage={tooltipValue}
                  tooltipClassName={styles.tooltip}
                  tooltipPositionStrategy="fixed"
                  useIcon
                >
                  {children}
                </Tooltip>
              </>
            ) : (
              children
            )}
          </span>
        </div>
      </div>
    </components.Option>
  )
}

interface CustomGroupHeadingProps<
  Option extends OptionTypeBase,
  IsMulti extends boolean = boolean,
  Group extends GroupTypeBase<Option> = GroupTypeBase<Option>
> extends GroupHeadingProps<Option, IsMulti, Group> {
  hasIcon?: boolean
}

const CustomGroupHeading = <
  Option extends OptionTypeBase,
  IsMulti extends boolean = false
>({
  hasIcon = false,
  children,
  data,
  ...props
}: CustomGroupHeadingProps<Option, IsMulti>) => {
  const iconValue = useMemo(() => {
    if (!hasIcon) return null

    return data.icon
  }, [hasIcon])

  return (
    <components.GroupHeading data={data} {...props}>
      <span>
        {iconValue && (
          <img src={iconValue} alt="icon path" className={styles.icon} />
        )}
        {children}
      </span>
    </components.GroupHeading>
  )
}

const CustomLoadingIndicator = () => {
  return <Preloader className={styles.loadingIndicator} />
}

interface CustomMenuListProps<
  Option extends OptionTypeBase,
  IsMulti extends boolean = boolean
> extends MenuListProps<Option, IsMulti> {
  valueKey?: string
  virtualized?: boolean
  additionalChildren?: React.ReactNode
}

const CustomMenuList = <
  Option extends OptionTypeBase,
  IsMulti extends boolean = false
>({
  valueKey = 'value',
  virtualized,
  children,
  additionalChildren,
  ...props
}: CustomMenuListProps<Option, IsMulti>) => {
  const menuListRef = useRef<HTMLDivElement>(null)

  const childrenArray = useMemo(() => React.Children.toArray(children), [
    children,
  ])

  // https://tanstack.com/virtual/latest/docs/framework/react/examples/dynamic
  const rowVirtualizer = useVirtualizer({
    count: childrenArray.length + (additionalChildren ? 1 : 0),
    getScrollElement: () => menuListRef.current,
    // Estimate the largest possible size of items. Ensures smooth-scrolling will work correctly
    estimateSize: () => 60,
    overscan: 5,
  })

  // Ensures scrolling via up/down keys keeps the focused option in view
  useEffect(() => {
    if (!virtualized || !props.focusedOption) return

    let focusedOptionIsInGroup = false

    const scrollToIndex = props.options.findIndex((option) => {
      if (optionIsGroup(option)) {
        const optionFound = option.options.find(
          (groupOption) =>
            groupOption[valueKey] === props.focusedOption[valueKey],
        )

        if (optionFound) {
          focusedOptionIsInGroup = true
        }

        return optionFound
      }

      return option[valueKey] === props.focusedOption[valueKey]
    })

    if (scrollToIndex !== -1) {
      const indexInChildrenArray =
        childrenArray.findIndex((child) =>
          (child as React.ReactElement).key?.includes(
            `${focusedOptionIsInGroup ? 'group' : 'option'}-${scrollToIndex}`,
          ),
        ) || scrollToIndex

      // If not groups, just scroll to the focused index
      if (!focusedOptionIsInGroup) {
        rowVirtualizer.scrollToIndex(indexInChildrenArray)

        return
      }

      // Get height and current scroll position of parent
      const viewportHeight = rowVirtualizer.scrollElement?.clientHeight || 300
      const scrollTop = rowVirtualizer.scrollElement?.scrollTop || 0

      // Get full size of group and assess scrolling options
      const virtualItem = rowVirtualizer
        .getVirtualItems()
        .find(({ index }) => index === indexInChildrenArray)

      if (virtualItem) {
        const { start: itemStart, end: itemEnd, size: itemSize } = virtualItem

        // If group is larger than dropdown viewport, we need to manually scroll to the focused item's offset
        if (virtualItem && itemSize > viewportHeight) {
          const optionIndexInGroup = props.options[
            scrollToIndex
          ].options.findIndex(
            (option) => option[valueKey] === props.focusedOption[valueKey],
          )

          const groupRef = menuListRef.current?.querySelectorAll(
            `div[class*='virtualItem'][data-index='${indexInChildrenArray}'] div[role='option']`,
          )

          if (groupRef) {
            const optionRef = groupRef[optionIndexInGroup]

            if (optionRef) {
              const {
                offsetTop: offsetWithinGroup,
                offsetHeight,
              } = optionRef as HTMLElement

              const positionInParent = itemStart + offsetWithinGroup

              if (positionInParent >= Math.ceil(scrollTop + viewportHeight)) {
                rowVirtualizer.scrollToOffset(positionInParent + offsetHeight, {
                  align: 'end',
                })
              } else if (positionInParent < Math.floor(scrollTop)) {
                rowVirtualizer.scrollToOffset(positionInParent, {
                  align: 'start',
                })
              }
            }
          }

          return
        }

        // Checks if the group is totally or partially out of view
        // We know group is smaller than viewport, so scrolling to index is fine
        const isOutOfView =
          itemStart < Math.floor(scrollTop) ||
          itemEnd > Math.ceil(scrollTop + viewportHeight)

        // Only scroll to index if the option group is not fully in view
        if (isOutOfView) {
          rowVirtualizer.scrollToIndex(indexInChildrenArray)
        }
      } else {
        // If the virtual item doesn't exist, we have jumped to the start/end of the list
        const totalHeight = rowVirtualizer.getTotalSize()

        rowVirtualizer.scrollToOffset(
          indexInChildrenArray === 0 ? 0 : totalHeight,
        )
      }
    }
  }, [props.focusedOption])

  return (
    <components.MenuList
      {...props}
      innerRef={virtualized ? menuListRef : props.innerRef}
    >
      {virtualized ? (
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => (
            <div
              key={virtualRow.index}
              data-index={virtualRow.index}
              ref={rowVirtualizer.measureElement}
              className={styles.virtualItem}
              style={{
                height: `${childrenArray[virtualRow.index]}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {virtualRow.index < childrenArray.length ? (
                childrenArray[virtualRow.index]
              ) : (
                <div className={styles.additionalOptions}>
                  {additionalChildren}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          {children}
          <div className={styles.additionalOptions}>{additionalChildren}</div>
        </>
      )}
    </components.MenuList>
  )
}

export const ALL_DROPDOWN_STYLES = ['white', 'grey'] as const
type DropdownStyleTuple = typeof ALL_DROPDOWN_STYLES
type DropdownStyleVariants = DropdownStyleTuple[number]

interface SelectBoxProps<
  Option extends OptionTypeBase,
  IsMulti extends boolean = boolean,
  Group extends GroupTypeBase<Option> = GroupTypeBase<Option>
> extends Props<Option, IsMulti, Group> {
  variant?: DropdownStyleVariants
  /** The key to be used as the option label */
  labelKey?: string
  /** The key to be used as the option value */
  valueKey?: string
  virtualized?: boolean
  error?: boolean
  /** When only one group is rendered, determine if it should show or not */
  hideOrphanGroupLabel?: boolean
  /** If only one option is selected on blur of the field, make it look like text input */
  noPillForSingleValue?: boolean
  children?: React.ReactNode
}

/** Documentation: https://react-select.com/props#select-props */
const SelectBox = <
  Option extends OptionTypeBase,
  IsMulti extends boolean = false
>({
  variant = 'white',
  labelKey = 'label',
  valueKey = 'value',
  virtualized,
  error,
  hideOrphanGroupLabel = true,
  noPillForSingleValue,
  children,
  ...props
}: SelectBoxProps<Option, IsMulti, GroupTypeBase<Option>>) => {
  const [hidePill, setHidePill] = useState(
    noPillForSingleValue && props.value?.length < 2,
  )

  return (
    <Select
      noOptionsMessage={() => 'No options found.'}
      loadingMessage={() => <LoadingLabel label="Loading" />}
      blurInputOnSelect={!props.isMulti}
      closeMenuOnSelect={!props.isMulti}
      tabSelectsValue={!props.isMulti}
      {...props}
      onFocus={(e) => {
        if (noPillForSingleValue && hidePill) {
          setHidePill(false)
        }

        if (props.onFocus) {
          props.onFocus(e)
        }
      }}
      onBlur={(e) => {
        // Logic to make single value look like text input
        if (noPillForSingleValue && props.value?.length < 2) {
          setHidePill(true)
        }

        if (props.onBlur) {
          props.onBlur(e)
        }
      }}
      getOptionLabel={(option) => (labelKey ? option[labelKey] : option.label)}
      getOptionValue={(option) => (valueKey ? option[valueKey] : option.value)}
      // Adds specific classNames to each part of the dropdown
      classNames={{
        container: () => styles.container,
        control: (state) =>
          classNames(styles.control, {
            [styles.isFocused]: state.isFocused,
            [styles.menuIsOpen]: state.menuIsOpen,
            [styles.grey]: variant === 'grey',
            [styles.error]: error,
          }),
        singleValue: () =>
          classNames(styles.singleValue, {
            [styles.grey]: variant === 'grey',
          }),
        valueContainer: () => styles.valueContainer,
        multiValue: (multiValueProps) => {
          return classNames(styles.multiValue, {
            [styles.noPill]:
              noPillForSingleValue && props.value?.length === 1 && hidePill,
            [styles.green]: multiValueProps.data.pillColor === 'green',
            [styles.red]: multiValueProps.data.pillColor === 'red',
            [styles.grey]: multiValueProps.data.pillColor === 'grey',
          })
        },
        multiValueLabel: () => styles.multiValueLabel,
        multiValueRemove: () => styles.multiValueRemove,
        dropdownIndicator: () => styles.dropdownIndicator,
        indicatorSeparator: () => styles.indicatorSeparator,
        group: (state) => {
          const { options } = state.selectProps

          return hideOrphanGroupLabel &&
            options.length === 1 &&
            optionIsGroup(options[0])
            ? styles.hideGroupPadding
            : ''
        },
        groupHeading: (state) => {
          const { options } = state.selectProps

          return classNames(styles.groupHeading, {
            [styles.hideGroupLabel]:
              hideOrphanGroupLabel &&
              options.length === 1 &&
              optionIsGroup(options[0]),
          })
        },
        menu: () =>
          classNames(styles.menu, {
            [styles.grey]: variant === 'grey',
          }),
        menuList: () => styles.menuList,
        option: (state) =>
          classNames(styles.option, {
            [styles.isSelected]: state.isSelected,
            [styles.isFocused]: state.isFocused,
            [styles.isDisabled]: state.isDisabled,
          }),
        placeholder: () =>
          classNames(styles.placeholder, {
            [styles.grey]: variant === 'grey',
          }),
        loadingMessage: () => styles.loadingMessage,
        noOptionsMessage: () => styles.noOptionsMessage,
        ...props.classNames,
      }}
      components={{
        GroupHeading: (groupHeadingProps) => (
          <CustomGroupHeading hasIcon {...groupHeadingProps} />
        ),
        LoadingIndicator: CustomLoadingIndicator,
        ClearIndicator: CustomClearIndicator,
        Option: (optionProps) => (
          <CustomOption
            valueKey={valueKey}
            hasIcon
            hasTooltip
            {...optionProps}
          />
        ),
        SingleValue: (singleValueProps) => (
          <CustomSingleValue hasIcon {...singleValueProps} />
        ),
        MenuList: (menuListProps) => (
          <CustomMenuList
            valueKey={valueKey}
            virtualized={virtualized}
            additionalChildren={children}
            {...menuListProps}
          />
        ),
        ...props.components,
      }}
    />
  )
}

interface ChecklistMultiValueProps<Option extends OptionTypeBase>
  extends MultiValueProps<Option, true> {
  allLabel?: string
  controlLabel?: string
  labelKey?: string
}

const ChecklistMultiValue = <Option extends OptionTypeBase>({
  allLabel = 'Any',
  controlLabel,
  labelKey,
  ...props
}: ChecklistMultiValueProps<Option>) => {
  const selectedOptionsCount = props.getValue().length

  const tooltipMessage = useMemo(() => {
    if (props.index > 0) return ''

    const fullValue = props
      .getValue()
      .map((option) => option[labelKey || 'label'])

    return fullValue.join(', ')
  }, [labelKey])

  // Only the first value should show, with '+ for the rest'
  if (props.index > 0) return null

  if (
    selectedOptionsCount === props.options.length &&
    props.options.length > 1
  ) {
    return (
      <components.MultiValue {...props}>
        {controlLabel ? (
          <Tooltip
            id="all-label-tooltip"
            className={styles.checklistValueTooltip}
            tooltipMessage={tooltipMessage}
            tooltipPositionStrategy="fixed"
          >
            {controlLabel}
            {allLabel ? `: ${allLabel}` : ''}
          </Tooltip>
        ) : (
          allLabel
        )}
      </components.MultiValue>
    )
  }

  return (
    <components.MultiValue {...props}>
      {controlLabel ? (
        <Tooltip
          id={`${controlLabel}-tooltip`}
          className={styles.checklistValueTooltip}
          tooltipMessage={tooltipMessage}
          tooltipPositionStrategy="fixed"
        >
          {controlLabel}: {props.children}
          {selectedOptionsCount > 1 ? ` + ${selectedOptionsCount - 1}` : ''}
        </Tooltip>
      ) : (
        <>
          {props.children}
          {selectedOptionsCount > 1 ? ` + ${selectedOptionsCount - 1}` : ''}
        </>
      )}
    </components.MultiValue>
  )
}

interface ChecklistMenuListProps<Option extends OptionTypeBase>
  extends MenuListProps<Option, true> {
  valueKey?: string
  virtualized?: boolean
  excludeAny?: boolean
  excludeNone?: boolean
  allLabel?: string
  noneLabel?: string
}

const ChecklistMenuList = <Option extends OptionTypeBase>({
  valueKey = 'value',
  virtualized,
  excludeAny = false,
  excludeNone = false,
  allLabel = 'Any',
  noneLabel = 'None',
  children,
  ...props
}: ChecklistMenuListProps<Option>) => {
  const menuListRef = useRef<HTMLDivElement>(null)

  const childrenArray = useMemo(() => React.Children.toArray(children), [
    children,
  ])

  // https://tanstack.com/virtual/latest/docs/framework/react/examples/dynamic
  const rowVirtualizer = useVirtualizer({
    count: childrenArray.length + Number(excludeAny || excludeNone),
    getScrollElement: () => menuListRef.current,
    // Estimate the largest possible size of items. Ensures smooth-scrolling will work correctly
    estimateSize: () => 60,
    overscan: 5,
  })

  // Ensures scrolling via up/down keys keeps the focused option in view
  useEffect(() => {
    if (!virtualized || !props.focusedOption) return

    let focusedOptionIsInGroup = false

    const scrollToIndex = props.options.findIndex((option) => {
      if (optionIsGroup(option)) {
        const optionFound = option.options.find(
          (groupOption) =>
            groupOption[valueKey] === props.focusedOption[valueKey],
        )

        if (optionFound) {
          focusedOptionIsInGroup = true
        }

        return optionFound
      }

      return option[valueKey] === props.focusedOption[valueKey]
    })

    if (scrollToIndex !== -1) {
      const indexInChildrenArray =
        childrenArray.findIndex((child) =>
          (child as React.ReactElement).key?.includes(
            `${focusedOptionIsInGroup ? 'group' : 'option'}-${scrollToIndex}`,
          ),
        ) || scrollToIndex

      // If not groups, just scroll to the focused index
      if (!focusedOptionIsInGroup) {
        rowVirtualizer.scrollToIndex(indexInChildrenArray)

        return
      }

      // Get height and current scroll position of parent
      const viewportHeight = rowVirtualizer.scrollElement?.clientHeight || 300
      const scrollTop = rowVirtualizer.scrollElement?.scrollTop || 0

      // Get full size of group and assess scrolling options
      const virtualItem = rowVirtualizer
        .getVirtualItems()
        .find(({ index }) => index === indexInChildrenArray)

      if (virtualItem) {
        const { start: itemStart, end: itemEnd, size: itemSize } = virtualItem

        // If group is larger than dropdown viewport, we need to manually scroll to the focused item's offset
        if (virtualItem && itemSize > viewportHeight) {
          const optionIndexInGroup = props.options[
            scrollToIndex
          ].options.findIndex(
            (option) => option[valueKey] === props.focusedOption[valueKey],
          )

          const groupRef = menuListRef.current?.querySelectorAll(
            `div[class*='virtualItem'][data-index='${indexInChildrenArray}'] div[role='option']`,
          )

          if (groupRef) {
            const optionRef = groupRef[optionIndexInGroup]

            if (optionRef) {
              const {
                offsetTop: offsetWithinGroup,
                offsetHeight,
              } = optionRef as HTMLElement

              const positionInParent = itemStart + offsetWithinGroup

              if (positionInParent >= Math.ceil(scrollTop + viewportHeight)) {
                rowVirtualizer.scrollToOffset(positionInParent + offsetHeight, {
                  align: 'end',
                })
              } else if (positionInParent < Math.floor(scrollTop)) {
                rowVirtualizer.scrollToOffset(positionInParent, {
                  align: 'start',
                })
              }
            }
          }

          return
        }

        // Checks if the group is totally or partially out of view
        // We know group is smaller than viewport, so scrolling to index is fine
        const isOutOfView =
          itemStart < Math.floor(scrollTop) ||
          itemEnd > Math.ceil(scrollTop + viewportHeight)

        // Only scroll to index if the option group is not fully in view
        if (isOutOfView) {
          rowVirtualizer.scrollToIndex(indexInChildrenArray)
        }
      } else {
        // If the virtual item doesn't exist, we have jumped to the start/end of the list
        const totalHeight = rowVirtualizer.getTotalSize()

        rowVirtualizer.scrollToOffset(
          indexInChildrenArray === 0 ? 0 : totalHeight,
        )
      }
    }
  }, [props.focusedOption])

  const valueCount = props.getValue().length

  const allSelected = valueCount === props.options.length
  const noneSelected = valueCount === 0

  return (
    <components.MenuList
      {...props}
      innerRef={virtualized ? menuListRef : props.innerRef}
    >
      {virtualized ? (
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => (
            <div
              key={virtualRow.index}
              data-index={virtualRow.index}
              ref={rowVirtualizer.measureElement}
              className={styles.virtualItem}
              style={{
                height: `${childrenArray[virtualRow.index]}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {!(excludeAny && excludeNone) && virtualRow.index === 0 ? (
                <div className={styles.anyNoneContainer}>
                  {!excludeAny && (
                    <>
                      {/* TODO: Pressing this button closes the menu. It shouldn't */}
                      <Button
                        variant="text"
                        className={styles.checklistOptionRow}
                        onPressStart={() => {
                          const allOptions: any[] = []

                          props.options.forEach((option) => {
                            if (optionIsGroup(option)) {
                              allOptions.push(...option.options)
                            } else {
                              allOptions.push(option)
                            }
                          })

                          props.setValue(
                            allOptions as MultiValue<Option>,
                            allSelected ? 'select-option' : 'deselect-option',
                          )
                        }}
                      >
                        <div
                          className={classNames(styles.checkbox, {
                            [styles.isSelected]: allSelected,
                          })}
                        />
                        <div>{allLabel}</div>
                      </Button>
                    </>
                  )}
                  {!excludeNone && (
                    <>
                      {/* TODO: Pressing this button closes the menu. It shouldn't */}
                      <Button
                        variant="text"
                        className={styles.checklistOptionRow}
                        onPressStart={() => {
                          props.setValue(
                            [],
                            noneSelected ? 'select-option' : 'deselect-option',
                          )
                        }}
                      >
                        <div
                          className={classNames(styles.checkbox, {
                            [styles.isSelected]: noneSelected,
                          })}
                        />
                        <div>{noneLabel}</div>
                      </Button>
                    </>
                  )}
                </div>
              ) : (
                childrenArray[virtualRow.index]
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          {(!excludeAny || !excludeNone) && (
            <div className={styles.anyNoneContainer}>
              {!excludeAny && (
                <>
                  {/* TODO: Pressing this button closes the menu. It shouldn't */}
                  <Button
                    variant="text"
                    className={styles.checklistOptionRow}
                    onPressStart={() => {
                      const allOptions: any[] = []

                      props.options.forEach((option) => {
                        if (optionIsGroup(option)) {
                          allOptions.push(...option.options)
                        } else {
                          allOptions.push(option)
                        }
                      })

                      props.setValue(
                        allOptions as MultiValue<Option>,
                        allSelected ? 'select-option' : 'deselect-option',
                      )
                    }}
                  >
                    <div
                      className={classNames(styles.checkbox, {
                        [styles.isSelected]: allSelected,
                      })}
                    />
                    <div>{allLabel}</div>
                  </Button>
                </>
              )}
              {!excludeNone && (
                <>
                  {/* TODO: Pressing this button closes the menu. It shouldn't */}
                  <Button
                    variant="text"
                    className={styles.checklistOptionRow}
                    onPressStart={() => {
                      props.setValue(
                        [],
                        noneSelected ? 'select-option' : 'deselect-option',
                      )
                    }}
                  >
                    <div
                      className={classNames(styles.checkbox, {
                        [styles.isSelected]: noneSelected,
                      })}
                    />
                    <div>{noneLabel}</div>
                  </Button>
                </>
              )}
            </div>
          )}
          {children}
        </>
      )}
    </components.MenuList>
  )
}

interface ChecklistOptionProps<Option extends OptionTypeBase>
  extends Omit<CustomOptionProps<Option, true>, 'hasIcon'> {
  showOnlyButtons?: boolean
}

const ChecklistOption = <Option extends OptionTypeBase>({
  valueKey = 'value',
  hasTooltip,
  showOnlyButtons = false,
  children,
  ...props
}: ChecklistOptionProps<Option>) => {
  const checkboxRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)

  const tooltipValue = useMemo(() => {
    if (!hasTooltip) return null

    return props.data.tooltip
  }, [hasTooltip])

  return (
    <components.Option {...props}>
      <div className={styles.checklistOptionRow}>
        <div ref={checkboxRef} className={styles.checkbox} />
        <div
          ref={parentRef}
          className={styles.optionInner}
          style={{
            maxWidth: `calc(100% - ${
              checkboxRef.current?.offsetWidth || 25
            }px)`,
          }}
        >
          <div>
            <span
              onMouseOver={(e) => {
                if (
                  parentRef.current &&
                  parentRef.current.offsetWidth <=
                    e.currentTarget.offsetWidth + 20
                ) {
                  const padding =
                    (parentRef.current.parentElement as HTMLElement)
                      .offsetWidth - parentRef.current.offsetWidth

                  const translateX =
                    e.currentTarget.offsetWidth -
                    parentRef.current.offsetWidth +
                    padding +
                    2

                  e.currentTarget.style.transform = `translateX(${-translateX}px)`
                }
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = ''
              }}
            >
              {tooltipValue ? (
                <Tooltip
                  id={`${props.data[valueKey]}-tooltip`}
                  tooltipMessage={tooltipValue}
                  tooltipClassName={styles.tooltip}
                  tooltipPositionStrategy="fixed"
                  useIcon
                >
                  {children}
                </Tooltip>
              ) : (
                children
              )}
            </span>
          </div>
        </div>
        {showOnlyButtons && (
          <Button
            variant="plainBox"
            className={styles.onlyButton}
            onPressStart={() => {
              props.setValue(
                [props.data],
                props.isSelected ? 'deselect-option' : 'select-option',
              )
            }}
          >
            Only
          </Button>
        )}
      </div>
    </components.Option>
  )
}

interface SelectBoxChecklistProps<
  Option extends OptionTypeBase,
  Group extends GroupTypeBase<Option> = GroupTypeBase<Option>
>
  extends Omit<
    SelectBoxProps<Option, true, Group>,
    | 'isMulti'
    | 'closeMenuOnSelect'
    | 'tabSelectsValue'
    | 'blurOnInputSelect'
    | 'getOptionLabel'
    | 'getValueLabel'
    | 'hideSelectedOptions'
    | 'children'
  > {
  virtualized?: boolean
  /** Exclude 'Any' checkbox at the top of the menu */
  excludeAny?: boolean
  /** Exclude 'None' checkbox at the top of the menu */
  excludeNone?: boolean
  /** Text used for 'Any' checkbox (defaults to 'Any'). Not used if excludeAny is true */
  allLabel?: string
  /** Text used for 'None' checkbox (defaults to 'None'). Not used if excludeNone is true */
  noneLabel?: string
  /** Makes it possible to select a single item in the menu with one click */
  showOnlyButtons?: boolean
  /** Static string value to show in the control field - overrides default placeholder and usual display of selected values */
  controlLabel?: string
}

export const SelectBoxChecklist = <Option extends OptionTypeBase>({
  valueKey = 'value',
  virtualized,
  excludeAny = false,
  excludeNone = false,
  allLabel = 'Any',
  noneLabel = 'None',
  showOnlyButtons = false,
  controlLabel,
  labelKey = 'label',
  isSearchable = false,
  ...props
}: SelectBoxChecklistProps<Option, GroupTypeBase<Option>>) => {
  return (
    <SelectBox
      placeholder={props.placeholder || controlLabel || 'None'}
      labelKey={labelKey}
      valueKey={valueKey}
      isSearchable={isSearchable}
      {...props}
      hideSelectedOptions={false}
      isMulti
      classNames={{
        valueContainer: () => styles.checklistValueContainer,
        multiValue: () =>
          classNames(styles.checklistMultiValue, {
            [styles.grey]: props.variant === 'grey',
          }),
        menu: () => classNames(styles.menu, styles.checklistMenu),
        menuList: () => styles.menuList,
      }}
      components={{
        MultiValueRemove: () => null,
        MultiValue: (multiValueProps) => (
          <ChecklistMultiValue
            allLabel={allLabel}
            controlLabel={controlLabel}
            labelKey={labelKey}
            {...multiValueProps}
          />
        ),
        MenuList: (menuListProps) => (
          <ChecklistMenuList
            virtualized={virtualized}
            valueKey={valueKey}
            excludeAny={excludeAny}
            excludeNone={excludeNone}
            allLabel={allLabel}
            noneLabel={noneLabel}
            {...menuListProps}
          />
        ),
        Option: (optionProps) => (
          <ChecklistOption
            valueKey={valueKey}
            hasTooltip
            showOnlyButtons={showOnlyButtons}
            {...optionProps}
          />
        ),
      }}
    />
  )
}

export default SelectBox
