import React, { useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'

import { passwordState } from '../api/apollo/variables'
import Button from '../components/button'
import Carousel from '../components/carousel'
import DisplayError from '../components/display-error'
import Footer from '../components/footer'
import Input from '../components/input'
import Link from '../components/link'
import Message from '../components/login-message'
import SiteLogo, { LogoTagline } from '../components/site-logo'
import { Heading } from '../components/typography'
import { supportEmail } from '../core/constants'
import { validatePassword } from '../helpers/forms'
import useAuthenticate from '../hooks/useAuthenticate'
import styles from '../styles/reset-password.module.scss'

interface ResetPasswordFormProps {
  token: string
}

const ResetPasswordForm = ({ token }: ResetPasswordFormProps) => {
  const { resetPassword } = useAuthenticate()

  const [response, setResponse] = useState('')
  const [attempts, setAttempts] = useState(0)
  const [password, setPassword] = useState('')
  const [reTypePassword, setReTypePassword] = useState('')

  return (
    <div>
      <SiteLogo link className={styles.svg} />
      <LogoTagline />
      <form
        className={styles.resetPasswordForm}
        onSubmit={(e: React.SyntheticEvent): void => {
          e.preventDefault()

          if (password !== reTypePassword) {
            setAttempts(attempts + 1)
            setResponse('Passwords do not match')

            return
          }

          const validated = validatePassword(password)

          if (validated === '') {
            setAttempts(0)

            resetPassword(token, password)
          } else {
            setAttempts(attempts + 1)
          }
          setResponse(validated)
        }}
      >
        {response && (
          <DisplayError attempts={attempts}>{response}</DisplayError>
        )}
        <Input
          type="password"
          name="password"
          label="New password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>): any =>
            setPassword(event.target.value)
          }
          value={password}
          required
        />
        <Input
          type="password"
          name="re-type-password"
          label="Re-type password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>): any =>
            setReTypePassword(event.target.value)
          }
          value={reTypePassword}
          required
        />
        <Button type="submit" style={{ marginBottom: 16 }}>
          Set new password
        </Button>
      </form>
      <h2>Don't have an account?</h2>
      <Link href="/create-account" newTab={false}>
        Sign up here
      </Link>
    </div>
  )
}

const ResetPasswordResult = () => {
  const currentPasswordState = useReactiveVar(passwordState)

  if (currentPasswordState.resetPassword.success) {
    return (
      <div>
        <SiteLogo link className={styles.svg} />
        <Heading type={2}>Your new password has been set</Heading>
        <Link href="/" newTab={false}>
          Login
        </Link>
      </div>
    )
  }

  return (
    <div>
      <SiteLogo link className={styles.svg} />
      <h2 style={{ marginBottom: 32 }}>Your password has not been updated.</h2>
      <Message
        message={`Please try [resetting your password](/forgot-password) again.  
If you are still experiencing issues, please contact us by emailing [${supportEmail}](mailto:${supportEmail}).`}
      />
    </div>
  )
}

interface ResetPasswordProps {
  location: Location
}

const ResetPassword = ({ location }: ResetPasswordProps) => {
  const currentPasswordState = useReactiveVar(passwordState)

  const { search } = location
  const searchParams = new URLSearchParams(search)
  const token = searchParams.get('token')

  useEffect(() => {
    passwordState({
      ...currentPasswordState,
      forgotPassword: {
        requested: false,
        success: false,
      },
    })
  }, [])

  return (
    <div className={styles.ssoWrapperOuter}>
      <section className={styles.ssoWrapper}>
        <Carousel
          containerClassName={styles.resetPasswordContainer}
          currentProgress={
            !token || currentPasswordState.resetPassword.requested ? 1 : 0
          }
          items={[
            {
              key: 1,
              component: <ResetPasswordForm token={token || ''} />,
            },
            {
              key: 2,
              component: <ResetPasswordResult />,
            },
          ]}
        />
      </section>
      <Footer />
    </div>
  )
}

export default ResetPassword
