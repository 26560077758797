import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import Button from './button'
import { BetaLabel } from './counter'
import SiteSelector from './workspace-selector'
import { currentUserDetails } from '../api/apollo/variables'
import UpgradeIcon from '../assets/svgs/upgrade-rocket.svg'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import styles from '../styles/intro.module.scss'

interface IntroProps {
  title?: string | React.ReactElement
  showUpgrade?: boolean
  isBeta?: boolean
  className?: string
  children?: React.ReactNode | string
}
export default function Intro({
  title,
  showUpgrade = true,
  isBeta = false,
  className,
  children,
}: IntroProps) {
  const { companySubscriptionLevel } = useReactiveVar(currentUserDetails)

  const history = useHistory()

  const logAction = useLogAction()

  const { isEnterprise } = useSubscriptionLevel()

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.headerWrapper}>
        <h1 className={styles.header}>
          {title}
          {isBeta && <BetaLabel className={styles.beta} />}
        </h1>
        <div className={styles.selectorWrapper}>
          {showUpgrade && companySubscriptionLevel && !isEnterprise && (
            <Button
              style={{ height: 40 }}
              icon={{
                src: UpgradeIcon,
                alt: 'Upgrade',
                iconAfter: true,
              }}
              onPress={() => {
                // @ts-ignore
                if (window.dataLayer && window.dataLayer.push) {
                  // @ts-ignore
                  window.dataLayer.push({
                    event: 'click-upgrade-header-button',
                  })
                }

                logAction({
                  variables: {
                    action: 'click-upgrade-header-button',
                    websiteSection: 'upgrade',
                    pagePath: window.location.pathname,
                    functionName: 'clickUpgrade',
                  },
                })

                history.push('/upgrade', {
                  from: window.location.pathname,
                })
              }}
            >
              Upgrade
            </Button>
          )}
          <div className={styles.siteSelector}>
            <SiteSelector />
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}
