import React, { useState } from 'react'
import { useTransition, animated } from 'react-spring'
import classNames from 'classnames'

import styles from '../styles/carousel.module.scss'

interface CarouselProps<
  T extends { key: string | number; component: React.ReactElement }
> {
  items: T[]
  currentProgress: number
  containerClassName?: string
}

const Carousel = <
  T extends { key: string | number; component: React.ReactElement }
>({
  items,
  currentProgress,
  containerClassName,
}: CarouselProps<T>) => {
  const [storeItems] = useState(items)

  const transitions = useTransition(
    storeItems[currentProgress],
    (item) => item.key,
    {
      from: { transform: 'translateX(100vw)' },
      enter: { transform: 'translateX(0)' },
      leave: { transform: 'translateX(-100vw)' },
    },
  )

  return (
    <div className={classNames(containerClassName, styles.container)}>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props} className={styles.items}>
          {React.cloneElement(item.component)}
        </animated.div>
      ))}
    </div>
  )
}

export default Carousel
