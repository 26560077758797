import React, { useState, useMemo, useCallback } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'

import AddWorkspaceModal from './add-workspace-modal'
import Button, { DeleteButton } from './button'
import { ConnectAnalyticsModal } from './connect-analytics-modal'
import Input from './input'
import Link from './link'
import Loader from './loader'
import Modal from './modal'
import Table from './table-v2'
import Tooltip from './tooltip'
import { SuccessText } from './typography'
import { RequestMoreWorkspacesModal } from './upgrade-modals'
import { currentUserDetails } from '../api/apollo/variables'
import { getCompanyDetails } from '../api/graphql/company-client'
import { getUserAccounts } from '../api/graphql/user-client'
import {
  deleteWorkspace,
  getCompanyWorkspacesDataSources,
} from '../api/graphql/workspace-client'
import adobeAnalytics from '../assets/logos/adobe-analytics.svg'
import googleAnalytics from '../assets/logos/google-analytics-icon.svg'
import { accountTypes, supportEmail } from '../core/constants'
import { isAdminUser, isSupportUser, setToken } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel, {
  subscriptionLevelDetails,
} from '../hooks/useSubscriptionLevel'
import integrationsData, { isGenericConnector } from '../integrations/data'
import styles from '../styles/manage-workspaces.module.scss'

const ManageWorkspaces = () => {
  const { workspaceID, userPermission } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const {
    loading: loadingSubscriptionLevel,
    isTeam,
    isEnterprise,
  } = useSubscriptionLevel()

  const { data: companyData } = useQuery(getCompanyDetails)
  const { data: dataSourcesData, loading: loadingDataSources } = useQuery(
    getCompanyWorkspacesDataSources,
    {
      notifyOnNetworkStatusChange: true,
    },
  )
  const { data: accountProfilesData } = useQuery(getUserAccounts)

  const [removeWorkspace, { loading: removingWorkspace }] = useMutation(
    deleteWorkspace,
    {
      refetchQueries: [getCompanyWorkspacesDataSources, getUserAccounts],
    },
  )

  const [addNew, setAddNew] = useState(false)
  const [connectId, setConnectId] = useState('')
  const [connectModal, setConnectModal] = useState(false)
  const [showWorkspaceUpgradeModal, setShowWorkspaceUpgradeModal] = useState(
    false,
  )
  const [workspaceToDelete, setWorkspaceToDelete] = useState<string | null>(
    null,
  )

  const workspaceCount = useMemo(() => {
    return companyData?.currentCompany.accountCount || 0
  }, [companyData])

  const maxWorkspaces = useMemo(() => {
    if (isEnterprise || loadingSubscriptionLevel) return Infinity

    // TODO: Use the actual tier max workspaces when available
    return isTeam ? subscriptionLevelDetails.startup.maxWorkspaces : 0
  }, [isTeam, isEnterprise, loadingSubscriptionLevel])

  const canAddWorkspaces = useMemo(() => {
    return isEnterprise || workspaceCount < maxWorkspaces
  }, [isEnterprise, workspaceCount, maxWorkspaces])

  const validWorkspacesDataSources = useMemo(() => {
    if (!dataSourcesData || !accountProfilesData) return []

    // Only show workspaces for which the current user is an admin
    const userAdminWorkspaces = accountProfilesData.currentUser.userAccountProfiles
      .filter((account) => isAdminUser(account.userPermission))
      .map(({ accountID }) => accountID)

    return dataSourcesData.currentCompany.accountList.filter((account) => {
      return userAdminWorkspaces.indexOf(account.accountID) > -1
    })
  }, [accountProfilesData, dataSourcesData])

  const getIconPath = useCallback((homepage: string | null): string => {
    return homepage
      ? `https://www.google.com/s2/favicons?domain=${homepage}`
      : `/favicon.png`
  }, [])

  return (
    <>
      <Table
        containerClassName={styles.tableContainer}
        headerColumns={[
          {
            id: 'workspace',
            content: 'Workspace',
            tooltip:
              'Segregated areas with independent user permissions, link parameter rules and connections.',
            columnSortKey: 'accountName',
          },
          {
            id: 'analytics',
            content: 'Analytics',
            tooltip:
              'Where we import onsite metrics data from. Each workspace can be only connected to one view / report suite, but you can have multiple workspaces connected to the same view / report suite.',
          },
          { id: 'actions', content: 'Actions' },
        ]}
        rowIDKey="accountID"
        loading={loadingDataSources || removingWorkspace}
        tableData={validWorkspacesDataSources}
      >
        {({ accountID, accountName, homepage, dataSource }) => {
          let reportSourceName = ''
          let reportSourceID = ''
          let isAdobe = false

          if (dataSource && accountTypes[dataSource.kind]) {
            switch (true) {
              case !!dataSource.adobeReportSuiteName:
                reportSourceName = `Report suite: ${dataSource.adobeReportSuiteName}`
                reportSourceID = dataSource.adobeReportID || ''
                isAdobe = true
                break
              case dataSource.kind === 'GA4_PROP' &&
                !!dataSource.ga4PropertyName:
                reportSourceName = `Property: ${dataSource.ga4PropertyName}`
                reportSourceID = dataSource.ga4PropertyID || ''
                break
              case !!dataSource.gaPropertyName:
                reportSourceName = `Property: ${dataSource.gaPropertyName} - View ${dataSource.gaViewName}`
                reportSourceID = dataSource.gaPropertyID || ''
                break
              default:
                break
            }
          }

          return (
            <>
              <td>
                <div className={styles.columnContainer}>
                  <img
                    src={getIconPath(homepage)}
                    alt="favicon"
                    className={styles.homepageIcon}
                  />
                  <div>
                    <p>
                      {accountName}{' '}
                      {workspaceID === accountID ? (
                        <span className={styles.subText}>(current)</span>
                      ) : (
                        ''
                      )}
                    </p>
                    {homepage && <p className={styles.subText}>{homepage}</p>}
                  </div>
                </div>
              </td>
              <td>
                {dataSource && accountTypes[dataSource.kind] ? (
                  <div className={styles.columnContainer}>
                    <img
                      src={isAdobe ? adobeAnalytics : googleAnalytics}
                      className={styles.analyticsLogo}
                      alt={isAdobe ? 'Adobe Analytics' : 'Google Analytics'}
                    />
                    <div>
                      <p>{accountTypes[dataSource.kind].short}</p>
                      {reportSourceName && (
                        <p>
                          <Tooltip
                            id={`${accountID}-reportSource`}
                            tooltipMessage={reportSourceID}
                            useIcon
                          >
                            {reportSourceName}
                          </Tooltip>
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <p className={styles.subText}>Not connected</p>
                )}
              </td>
              <td>
                <div className={styles.columnContainer}>
                  {dataSource?.kind === 'BLANK' ? (
                    <Button
                      onPress={() => {
                        setConnectId(accountID)
                        setConnectModal(true)
                      }}
                    >
                      Connect
                    </Button>
                  ) : (
                    <>
                      {dataSource?.connected === undefined ? (
                        <Loader className={styles.loadingText} />
                      ) : (
                        <>
                          {dataSource?.connected ? (
                            <SuccessText>Connected</SuccessText>
                          ) : (
                            <Button
                              variant="secondary"
                              onPress={async () => {
                                try {
                                  const connector = integrationsData.find(
                                    (integration) =>
                                      integration.connectorID ===
                                      (dataSource.kind === 'AA_REPORT'
                                        ? 'adobeAnalytics'
                                        : 'googleAnalytics'),
                                  )

                                  if (
                                    !connector ||
                                    !isGenericConnector(
                                      connector.connectButton,
                                    ) ||
                                    !connector.connectButton.reconnectFn
                                  )
                                    throw new Error('Unable to connect')

                                  await connector.connectButton.reconnectFn(
                                    connectId,
                                  )

                                  logAction({
                                    variables: {
                                      action: `reconnect-${connectId}`,
                                      pagePath: '/settings',
                                      websiteSection: 'settings',
                                      functionName: 'reconnect',
                                    },
                                  })
                                } catch {
                                  console.error('Failed to reconnect account')
                                }
                              }}
                            >
                              Reconnect
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {/* Only non-orphan enterprise workspaces can be deleted, and only by support users */}
                  {validWorkspacesDataSources.length > 1 &&
                    (!isEnterprise ||
                      (isEnterprise && isSupportUser(userPermission))) && (
                      <DeleteButton
                        className={styles.deleteButton}
                        onPress={async () => setWorkspaceToDelete(accountID)}
                      />
                    )}
                </div>
              </td>
            </>
          )
        }}
      </Table>
      <Button
        className={styles.addWorkspaceButton}
        onPress={() => {
          if (canAddWorkspaces) {
            setAddNew((s) => !s)
          } else {
            // @ts-ignore
            if (window.dataLayer && window.dataLayer.push) {
              // @ts-ignore
              window.dataLayer.push({
                event: 'click-add-workspaces-upgrade-blocker',
                is_paddle: isTeam,
              })
            }

            logAction({
              variables: {
                action: 'click-add-workspaces-upgrade-blocker',
                websiteSection: 'settings',
                pagePath: '/settings',
                functionName: 'clickUpgrade',
                extra: JSON.stringify({ isTeam }),
              },
            })

            setShowWorkspaceUpgradeModal(true)
          }
        }}
      >
        Create new workspace
      </Button>
      <Input
        type="checkbox"
        name="sync-company-generators"
        id="sync-company-generators"
        className={styles.checkboxItem}
        disabled
        checked={false}
        label="Sync link taxonomy, parameters, dropdowns and rules"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          console.log('TBC')
        }}
      >
        <Tooltip
          id="sync-company-generators"
          useIcon
          tooltipMessage={
            <p>
              Email{' '}
              <Link
                className={styles.supportLink}
                href={`mailto:${supportEmail}`}
              >
                {supportEmail}
              </Link>{' '}
              to use one link generator with the same taxonomy, parameters,
              dropdowns and rules across workspaces.
            </p>
          }
        />
      </Input>
      {addNew && <AddWorkspaceModal closeModal={setAddNew} />}
      {workspaceToDelete && (
        <Modal
          setIsOpen={() => setWorkspaceToDelete(null)}
          modalHeader="Delete workspace"
          headerColor="pink"
          isWarning
          yesText="Delete workspace"
          onYes={async () => {
            const { data } = await removeWorkspace({
              variables: {
                workspaceID: workspaceToDelete,
              },
            })

            if (data) {
              const { newToken } = data.userAccountSettings.deleteAccount

              // A new token is provided when the user's current workspace is deleted
              if (newToken) {
                setToken(newToken)
                window.location.reload()
              }
            }

            setWorkspaceToDelete(null)
          }}
        >
          <p>Are you sure you want to permanently delete this workspace?</p>
        </Modal>
      )}
      {connectModal && (
        <ConnectAnalyticsModal
          toggleModal={setConnectModal}
          connectId={connectId}
        />
      )}
      {showWorkspaceUpgradeModal && (
        <RequestMoreWorkspacesModal
          onHideModal={setShowWorkspaceUpgradeModal}
        />
      )}
    </>
  )
}

export default ManageWorkspaces
