import _ from 'lodash'
import FileSaver from 'file-saver'
import numeral from 'numeraljs'
import moment from 'moment'

import { getItemByKeyValue, removeByKeyValue, replaceByKeyValue } from './index'
import { RecentlyValidatedUrl } from '../api/apollo/variables'
import { GeneratorFields, ValidationChecks } from '../api/types'
import { brandName, messages } from '../core/constants'
import { MinimalCodeList } from '../__gql-types__/graphql'

export const defaultShortLinkDomain = 'upl.inc'
export const defaultAppDeepLinkDomain = 'default'
export const defaultAppLinkDomain = 'app.upl.inc'

export const minBatchShortLinks = 11
/** Form gets sluggish after 2500 combinations, so block submission */
export const maxBatchShortLinks = 2500

export interface FullValidationCheck extends ValidationChecks {
  showCheckbox?: boolean
  ruleTitle?: string
  helpText?: string
  requireUpgrade?: boolean
  logAction?: string
  fieldType?: 'input' | 'textarea' | 'select' | 'multiselect'
}

// One for each accordion section on Track>Edit page
type ValidationCategories =
  | 'Landing page URL'
  | 'Landing page validation'
  | 'Parameter validation'
  | 'Short link preferences'
  | 'Email preferences'
  | 'Advanced options'

export interface ValidationChecksCategory {
  category: ValidationCategories
  validationChecks: FullValidationCheck[]
}

/** If the rule is not present in CampaignCodeGenerator object, this is used to set the rule's default state */
export const defaultEnabled = {
  REQUIRE_LANDING_PAGE: true,
  SHOW_LANDING_PAGE: true,
  NO_SPECIAL_CHARS_LANDING_PAGE: false,
  CHECK_URL_EXISTS: true,
  CHECK_URL_SPEED: true,
  CHECK_URL_REDIRECT: true,
  CHECK_URL_ANALYTICS: false,
  ALL_LOWER_CASE: false,
  NO_SPECIAL_CHARS: true,
  REPLACE_SPACES_WITH: true,
  LIMIT_URL_LENGTH: true,
  LIMIT_QUERY_LENGTH: true,
  FORCE_SHORT_LINK: false,
  FORCE_CUSTOM_DOMAIN: false,
  INCLUDE_PREFIX_WITH_COPY_FROM: false,
  COPY_FROM_SEPARATOR: true,
  INCLUDE_EMPTY_VALUES: false,
  ALLOW_APP_LINKS: true,
}

/** If the rule is not present in CampaignCodeGenerator object, this is used to set the rule's default values */
export const defaultValidationChecksValues = {
  ALL_LOWER_CASE: /[A-Z]/,
  NO_SPECIAL_CHARS: /[?=&]/,
  NO_SPECIAL_CHARS_LANDING_PAGE: /[?=&]/,
  LIMIT_URL_LENGTH: '1024',
  LIMIT_QUERY_LENGTH: '255',
}

export const defaultValidationChecksFull: ValidationChecksCategory[] = [
  {
    category: 'Landing page URL',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Require',
        name: 'REQUIRE_LANDING_PAGE',
        logAction: 'update-generator-rules-landing-page-required',
        value: null,
        helpText:
          'If checked, users will have to enter a landing page URL to create a campaign code link.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Show this field',
        name: 'SHOW_LANDING_PAGE',
        logAction: 'update-generator-rules-landing-page-show-field',
        value: null,
        helpText:
          'If unchecked, the landing page URL input box will be removed (not recommended).',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle:
          'No special characters or existing query string parameters (?=&)',
        name: 'NO_SPECIAL_CHARS_LANDING_PAGE',
        logAction: 'update-generator-rules-landing-page-no-special-characters',
        value: null,
        helpText:
          'If checked, users will not be able to enter a URL with ?=&amp;, including existing query string parameters. Turn this off if deep linking uses extra query string parameters instead of hashes (#).',
      },
    ],
  },
  {
    category: 'Landing page validation',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Check it exists',
        name: 'CHECK_URL_EXISTS',
        logAction: 'update-generator-rules-landing-page-exists',
        value: null,
        helpText: 'If checked the validation will check if the page exists.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Check it loads fast',
        name: 'CHECK_URL_SPEED',
        logAction: 'update-generator-rules-landing-page-loads-fast',
        value: null,
        helpText:
          'If checked the validation will check if the page loads under 5 seconds.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Check it redirects',
        name: 'CHECK_URL_REDIRECT',
        logAction: 'update-generator-rules-landing-page-redirects',
        value: null,
        helpText: 'If checked the validation will check for page redirects.',
      },
      {
        showCheckbox: true,
        enabled: false,
        ruleTitle: 'Check it has analytics',
        name: 'CHECK_URL_ANALYTICS',
        logAction: 'update-generator-rules-landing-page-analytics',
        value: null,
        helpText:
          'If checked the validation will check if the page has Google or Adobe analytics.',
      },
    ],
  },
  {
    category: 'Parameter validation',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Lowercase only',
        name: 'ALL_LOWER_CASE',
        logAction: 'update-generator-rules-lowercase',
        value: null,
        helpText:
          'If checked, all text in links will be automatically lowercased. This makes data easier to read in analytics platforms.\n\n**If unchecked, lowercase validation is controlled individually above for each parameter.**',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'No special characters (?=&)',
        name: 'NO_SPECIAL_CHARS',
        logAction: 'update-generator-rules-no-special-characters',
        value: null,
        helpText:
          "If checked, users' text input cannot contain special characters. This stops users creating campaign codes which interfere with campaign code tracking. Does not apply to meta parameters.",
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Replace spaces with',
        name: 'REPLACE_SPACES_WITH',
        logAction: 'update-generator-rules-replace-spaces-with',
        value: '_',
        helpText:
          'If checked, spaces will be replaced with this character when typing into free text parameters or dropdown codes. The default recommended value is underscore _. This stops ugly %20 characters from showing in your analytics tools. Does not apply to meta parameters.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Max link length',
        name: 'LIMIT_URL_LENGTH',
        logAction: 'update-generator-rules-max-link-length',
        value: '1024',
        helpText:
          'Maximum number of characters in the landing page and generated tracking code. Recommended value is 1024 characters.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Max query length',
        name: 'LIMIT_QUERY_LENGTH',
        logAction: 'update-generator-rules-max-query-length',
        value: '255',
        helpText:
          'Maximum number of generated tracking code. Recommended value is 255 characters.',
      },
    ],
  },
  {
    category: 'Short link preferences',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Allow app deep links',
        name: 'ALLOW_APP_LINKS',
        logAction: 'update-app-link-visibility',
        value: null,
        helpText:
          'Show/hide the option to create app deep links in the tool. App deep links are links that open a specific page in a mobile app.',
      },
      {
        showCheckbox: false,
        enabled: true,
        ruleTitle: 'Link type preferences',
        name: 'FORCE_SHORT_LINK',
        logAction: 'update-generator-short-link-force-short-link',
        value: `[
          {
            "optionName": "Recommend basic links",
            "optionValue": "recommend-long-links",
            "selected": true
          },
          {
            "optionName": "Recommend short links",
            "optionValue": "recommend-short-links",
            "selected": false
          },
          {
            "optionName": "Force basic links",
            "optionValue": "force-long-links",
            "selected": false
          },
          {
            "optionName": "Force short links",
            "optionValue": "force-short-links",
            "selected": false
          }
        ]`,
        helpText:
          'Shows/hides the short or basic link options or determines which one is pre-selected',
        fieldType: 'select',
      },
      {
        showCheckbox: false,
        enabled: true,
        ruleTitle: 'Only show domains',
        name: 'FORCE_CUSTOM_DOMAIN',
        logAction: 'update-short-link-custom-domain',
        value: `[
          {
            "optionName": "${defaultShortLinkDomain}",
            "optionValue": "${defaultShortLinkDomain}",
            "selected": true
          }
        ]`,
        helpText:
          'Pick which of your short link domains your users will be able to select',
        requireUpgrade: true,
        fieldType: 'multiselect',
      },
    ],
  },
  {
    category: 'Email preferences',
    validationChecks: [
      {
        showCheckbox: false,
        enabled: true,
        ruleTitle: 'Only replace email links which contain:',
        name: 'EMAIL_DOMAIN_LIST',
        logAction: 'update-generator-rules-email-default',
        value: '',
        fieldType: 'textarea',
        helpText:
          "When creating links in the 'Email' tab, we will only find and replace links which contain these domains.",
      },
    ],
  },
  {
    category: 'Advanced options',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: false,
        ruleTitle: "Include prefix with 'copy from' fields",
        name: 'INCLUDE_PREFIX_WITH_COPY_FROM',
        logAction: 'update-generator-rules-copy-from-include-prefix',
        value: null,
        helpText:
          'If checked, any parameters copied from another parameter will include both the parameter prefix and the value.',
      },
      {
        showCheckbox: false,
        enabled: true,
        ruleTitle: 'Copy from separator',
        name: 'COPY_FROM_SEPARATOR',
        logAction: 'update-generator-rules-copy-from-separator',
        value: '|',
        helpText:
          "If a parameters copies from multiple other parameters, they will be separated by this value. The default value is a pipe '|'.",
      },
      {
        showCheckbox: true,
        enabled: false,
        ruleTitle: 'Include empty optional values',
        name: 'INCLUDE_EMPTY_VALUES',
        logAction: 'update-generator-rules-include-empty',
        value: null,
        helpText:
          'If checked, empty input values will still be shown in the tool with their prefix.',
      },
    ],
  },
]

export const getDomain = (str: string) => {
  const domainFound = str.match(/https:\/\/?([^\/]+)/gi)

  if (domainFound !== null && domainFound.length > 0) {
    return domainFound[0]
  }

  return ''
}

// return default domain ('upl.inc') or custom domain ID
export const getCustomDomainID = (customDomainID?: string | null) => {
  return !customDomainID ||
    customDomainID === '' ||
    customDomainID === defaultShortLinkDomain ||
    customDomainID === `https://${defaultShortLinkDomain}`
    ? null
    : customDomainID
}

export const getValidationCheck = (
  validationChecks: FullValidationCheck[] | null,
  key: string,
  defaultValue = false,
): FullValidationCheck => {
  const defaultValidation = {
    enabled: defaultValue,
    name: key,
    value: null,
  }

  if (!validationChecks) {
    return defaultValidation
  }

  const found = validationChecks.find((item) => item.name === key)

  if (key === 'REPLACE_SPACES_WITH') {
    if (!found) {
      return { enabled: true, name: 'REPLACE_SPACES_WITH', value: '_' }
    }
    const value = Object.prototype.hasOwnProperty.call(found, 'value')
      ? found.value
      : '_'
    return { ...found, value }
  }

  if (key === 'LIMIT_URL_LENGTH') {
    if (!found) {
      return {
        enabled: true,
        name: 'LIMIT_URL_LENGTH',
        value: defaultValidationChecksValues.LIMIT_URL_LENGTH.toString(),
      }
    }
    const value = Object.prototype.hasOwnProperty.call(found, 'value')
      ? found.value
      : '_'
    return { ...found, value }
  }

  if (key === 'LIMIT_QUERY_LENGTH') {
    if (!found) {
      return {
        enabled: true,
        name: 'LIMIT_QUERY_LENGTH',
        value: defaultValidationChecksValues.LIMIT_QUERY_LENGTH,
      }
    }
    const value = Object.prototype.hasOwnProperty.call(found, 'value')
      ? found.value
      : '_'
    return { ...found, value }
  }

  if (key === 'COPY_FROM_SEPARATOR') {
    if (!found) {
      return { enabled: true, name: 'COPY_FROM_SEPARATOR', value: '|' }
    }
    const value = Object.prototype.hasOwnProperty.call(found, 'value')
      ? found.value
      : '|'
    return { ...found, value }
  }

  if (!found) {
    return defaultValidation
  }

  return found
}

export const getValidationChecksObject = (
  generatedStructure,
  isHomepage = false,
  override: {
    name: string
    enabled: boolean
    value?: string
  }[] = [],
) => {
  if (!generatedStructure) {
    return null
  }

  const { validationChecks } = generatedStructure

  if (isHomepage) {
    return [
      getValidationCheck(validationChecks, 'NO_SPECIAL_CHARS_LANDING_PAGE'),
    ]
  }

  // remove landing page validation options
  let useChecks = removeByKeyValue(
    validationChecks,
    'name',
    'NO_SPECIAL_CHARS_LANDING_PAGE',
  )

  override.forEach((item) => {
    useChecks = replaceByKeyValue(useChecks, 'name', item.name, item, true)
  })

  const rsw = getValidationCheck(validationChecks, 'REPLACE_SPACES_WITH')

  return replaceByKeyValue(
    useChecks,
    'name',
    'REPLACE_SPACES_WITH',
    rsw,
    true,
  ) as FullValidationCheck[]
}

interface GetUrlMessagesProps {
  validatedUrl?: RecentlyValidatedUrl | null
  createdToday?: boolean
  hasMetricData: boolean
  hasNoLandingPage?: boolean
  checkSpeed?: boolean
  checkAnalytics?: boolean
  checkRedirect?: boolean
}

export const getUrlMessages = ({
  validatedUrl,
  createdToday,
  hasMetricData,
  hasNoLandingPage,
  checkSpeed,
  checkAnalytics,
  checkRedirect,
}: GetUrlMessagesProps) => {
  const hasSlowLandingPage = checkSpeed ? validatedUrl?.slowLandingPage : false
  const hasAnalyticsTag = checkAnalytics ? !validatedUrl?.noAnalyticsTag : true
  const hasRedirect = checkRedirect
    ? validatedUrl?.redirectedLandingPage
    : false

  const is403Url = validatedUrl?.statusCode === 403
  const isBadUrl = validatedUrl && validatedUrl.badUrl
  const isGoodUrl = !!(
    !isBadUrl &&
    validatedUrl &&
    !hasSlowLandingPage &&
    hasAnalyticsTag &&
    !hasRedirect
  )

  const urlMessages: string[] = []

  if (hasNoLandingPage) {
    urlMessages.push(messages.validateUrlMessage.noUrl)
    return urlMessages.join('')
  }

  if (validatedUrl) {
    if (is403Url) {
      urlMessages.push(messages.validateUrlMessage.forbidden)
    } else if (isGoodUrl) {
      let goodUrlMessage = `This landing page is live`

      if (checkAnalytics) {
        goodUrlMessage += ', has a Google or Adobe analytics tag'
      }

      if (checkSpeed) {
        goodUrlMessage += ' and loads in under 4 seconds'
      }

      goodUrlMessage += `. [Info](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-).`

      urlMessages.push(goodUrlMessage)
    } else if (isBadUrl) {
      urlMessages.push(messages.validateUrlMessage.badUrl)
    } else if (hasSlowLandingPage) {
      urlMessages.push(messages.validateUrlMessage.slowLandingPage)
    } else if (!hasAnalyticsTag) {
      urlMessages.push(messages.validateUrlMessage.noAnalyticsTag)
    } else if (hasRedirect) {
      urlMessages.push(messages.validateUrlMessage.redirectedLandingPage)
    }
  } else if (createdToday) {
    urlMessages.push(messages.validateUrlMessage.validating)
  } else if (!hasMetricData) {
    urlMessages.push(messages.validateUrlMessage.noData)
  } else {
    urlMessages.push(messages.validateUrlMessage.unknownUrl)
  }

  return urlMessages.join('')
}

export const formatMetricValue = (value, units): string => {
  let formattedNumber = value

  if (typeof value === 'number') {
    if (units === 'percentage') {
      formattedNumber = value === -1 ? '' : `${numeral(value).format('0,0')}%`
    } else if (value !== 0) {
      if (units === 'duration') {
        const time = moment.utc(value * 1000).format('mm:ss')
        formattedNumber = `${time}s`
      } else {
        formattedNumber = `${numeral(value).format('0,0.[0]a')}`
      }
    }
  }

  return formattedNumber
}

export const getAnchorFromString = (url: string): string => {
  const hasAnchor = url.lastIndexOf('#') !== -1

  let useAnchor = ''

  if (hasAnchor) {
    // Exclude # used in hash based routing (/#/)
    const searchRegEx = new RegExp(`#(?!\/)([^?/])*`, 'i')

    const urlMatch = url.match(searchRegEx)

    if (urlMatch && urlMatch.length > 0) {
      useAnchor = urlMatch[0].replace('?', '')
    }
  }

  return useAnchor
}

export interface MinCodesByUserResult {
  codeID: string
  fullLink: string
  createdTime: string
  shortLink?: string
  params: {
    paramID: string
    paramName: string
    paramValue: string
  }[]
}

export const getCsvHeader = (codes: MinCodesByUserResult) => {
  const result: string[] = []
  result.push('User')
  result.push('Timestamp')
  result.push('Short link')
  result.push('Landing page with query parameters')

  codes.params.forEach((param) => {
    result.push(param.paramName)
  })

  return result.map((r) => `"${r.replace(/\"/gi, '""')}"`) // escape quotes
}

interface GetCsvRowsProps {
  userEmail: string
  codes: MinCodesByUserResult[]
  selectedCodes?: string[]
}

export const getCsvRows = ({
  userEmail,
  codes,
  selectedCodes = [],
}: GetCsvRowsProps) => {
  let result: string[][] = []

  const getCode = (code: MinCodesByUserResult) => {
    const row: string[] = []

    row.push(userEmail)
    row.push(code.createdTime)
    row.push(code.shortLink || '')
    row.push(code.fullLink)

    code.params.forEach((paramDef) => {
      row.push(paramDef.paramValue)
    })

    return row.map((r) => `"${r.replace(/\"/gi, '""')}"`) // escape quotes
  }

  if (selectedCodes.length === 0) {
    result = codes.map((code) => getCode(code))
  } else {
    codes.forEach((c) => {
      if (c && c.codeID && selectedCodes.indexOf(c.codeID) !== -1) {
        result.push(getCode(c))
      }
    })
  }
  return result
}

interface GetCsvStringProps {
  userEmail: string
  codes: MinCodesByUserResult[]
  selectedCodes?: string[]
}

export const getCsvString = ({
  userEmail,
  codes,
  selectedCodes = [],
}: GetCsvStringProps): string => {
  const csvRows: string[] = []

  csvRows.push(getCsvHeader(codes[0]).join(','))

  const rows = getCsvRows({
    userEmail,
    selectedCodes,
    codes,
  })

  rows.forEach((row) => {
    csvRows.push(row.join(','))
  })

  return csvRows.join('\n')
}

export const buildMinCodesByUserList = (
  minCodes: MinimalCodeList,
  limit = 1000,
): MinCodesByUserResult[] => {
  const {
    codeID,
    fullLink,
    shortLink,
    createdTime,
    minGenDef,
    codeDef,
  } = minCodes

  const minCodesByUser: MinCodesByUserResult[] = []

  codeID.slice(0, limit).forEach((cid, cidIndex) => {
    const minCodeByUser: MinCodesByUserResult = {
      codeID: cid,
      createdTime: moment
        .unix(parseInt(createdTime[cidIndex], 10))
        .toISOString(),
      fullLink: fullLink[cidIndex],
      shortLink: shortLink ? shortLink[cidIndex] : undefined,
      params: [],
    }

    minGenDef.forEach((paramDef, paramDefIndex) => {
      const { paramID, paramName } = paramDef

      minCodeByUser.params.push({
        paramID,
        paramName,
        paramValue: codeDef[cidIndex][paramDefIndex],
      })
    })

    minCodesByUser.push(minCodeByUser)
  })

  return minCodesByUser
}

export const getMultiCodesToCopy = (item: MinCodesByUserResult[]) => {
  if (item.length === 0) {
    return { code: '', all: [] as string[] }
  }

  const hasShortLink = item.some((i) => i.shortLink !== '')

  const copyHeader = [
    hasShortLink ? 'Short link' : null,
    'Landing page with query parameters',
    ...item[0].params.map(({ paramName }) => paramName).filter((i) => i !== ''),
  ]
    .filter((i) => i !== null)
    .join('\t')

  const code = item.map(({ fullLink, shortLink: sL, params }) => {
    const shortLink = sL && sL !== '' ? sL : ''

    return [
      hasShortLink ? shortLink : null,
      fullLink,
      params
        .map(({ paramValue }) => paramValue)
        .filter((i) => i !== '')
        .join('\t'),
    ]
      .filter((i) => i !== null)
      .join('\t')
  })

  return { code, all: [copyHeader, ...code] }
}

export const getShareEmails = (
  shareEmails: string[],
  typedValue: string,
): string[] => {
  if (typedValue === '') return shareEmails

  if (shareEmails.length > 0) {
    if (shareEmails[shareEmails.length - 1] === typedValue) return shareEmails

    return [...shareEmails, ...[typedValue]]
  }

  return [typedValue]
}

export const downloadSpecificCodes = async (
  data: string | ArrayBuffer,
  excelFile = false,
): Promise<any> => {
  const blob = new Blob(
    [excelFile ? new Uint8Array(data as ArrayBuffer) : `\ufeff${data}`],
    {
      type: excelFile
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        : 'text/plain;charset=utf-8-sig',
    },
  )
  const now = new Date(Date.now())

  await FileSaver.saveAs(
    blob,
    `${moment(now).format('YYYY-MM-DD')} ${brandName} Campaign Codes.${
      excelFile ? 'xlsx' : 'csv'
    }`,
  )

  return true
}

export const checkParamNameIsInvalid = (
  name: string,
  allParams: string[],
  callback?: any,
  currName?: string,
): boolean => {
  if (name === '') {
    if (callback) {
      callback(true)
    }
    return true
  }

  if (allParams.length > 0) {
    const paramExists =
      allParams.filter((param) => {
        return param === name && (!currName || param !== currName)
      }).length > 0
    if (callback) {
      callback(paramExists)
    }
    return paramExists
  }
  return false
}

export const getCopyFromValues = (paramDefs, paramDef: GeneratorFields) => {
  if (paramDef.fieldType === 'input')
    return paramDefs.filter(
      (item: GeneratorFields): boolean => item.fieldID !== paramDef.fieldID,
    )

  return []
}

export const parentOptionsAreEqual = (a: string[], b: string[]) => {
  if (a.length !== b.length) return false

  return !a.find((val) => b.indexOf(val) === -1)
}

// Ensures that parameters can't be dependent on each other
// Dependency chain must start with the ID of the initial parameter being checked
export const checkCircularDependency = (
  paramToCheck: GeneratorFields,
  allDropdownParams: GeneratorFields[],
  dependencyChain: string[],
) => {
  if (!paramToCheck.parameterDependsOn) return false

  const { parentFieldID } = paramToCheck.parameterDependsOn

  if (
    // Check it's not dependent on itself
    parentFieldID === paramToCheck.fieldID ||
    dependencyChain.indexOf(parentFieldID) > -1
  )
    return true

  // paramToCheck has a dependency but not one that's already in the chain
  // Recurse through until base cases are met
  const newDependencyChain = [...dependencyChain, parentFieldID]

  const newParamToCheck = getItemByKeyValue(
    allDropdownParams,
    'fieldID',
    parentFieldID,
  )

  if (newParamToCheck === -1) return false

  return checkCircularDependency(
    newParamToCheck,
    allDropdownParams,
    newDependencyChain,
  )
}

/** lastIndex needs to be reset if used, because it has global flag! https://stackoverflow.com/questions/15276873/is-javascript-test-saving-state-in-the-regex */
export const hrefRegex = /href=(3D)?((\\)?\'http(s)?:\/\/[^{}\s]+(\\)?\'|(\\)?\"http(s)?:\/\/[^{}\s]+(\\)?\")/g

/** Used for textContent object in Salesforce Marketing Cloud emails. Their API is a mess. Using line breaks (\n) here to separate links */
export const httpRegex = /http(s)?:\/\/[^{}\s\\]+/g

export const htmlHasLinks = (html: string, excludeHref = false) => {
  const out = (excludeHref ? httpRegex : hrefRegex).test(html)

  // Reset regex state
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test
  hrefRegex.lastIndex = 0
  httpRegex.lastIndex = 0

  return out
}
