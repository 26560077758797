import React, { useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { passwordState } from '../api/apollo/variables'
import Button from '../components/button'
import Carousel from '../components/carousel'
import Footer from '../components/footer'
import Input from '../components/input'
import Link from '../components/link'
import Message from '../components/login-message'
import SiteLogo, { LogoTagline } from '../components/site-logo'
import { supportEmail } from '../core/constants'
import useAuthenticate from '../hooks/useAuthenticate'
import styles from '../styles/forgot-password.module.scss'

export const ForgotPasswordForm = () => {
  const { resetPasswordRequest } = useAuthenticate()

  const history = useHistory()

  const [email, setEmail] = useState('')

  return (
    <div>
      <SiteLogo link className={styles.svg} />
      <LogoTagline />
      <form
        className={styles.forgotPasswordForm}
        onSubmit={async (e) => {
          e.preventDefault()

          await resetPasswordRequest(email)
        }}
      >
        <Input
          type="email"
          name="email"
          label="Email"
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
            setEmail(event.target.value)
          }
          value={email}
          required
        />
        <Button type="submit">Request password reset</Button>
      </form>
      <h2>Don't have an account?</h2>
      <Button
        color="grey"
        style={{ marginBottom: 16 }}
        onPress={() => history.push('/create-account')}
      >
        Sign up here
      </Button>
      <Link
        href="https://support.uplifter.ai/hc/en-us/articles/360018765078-I-can-t-login-to-Uplifter"
        className={styles.loginHelp}
      >
        Need help logging in?
      </Link>
    </div>
  )
}

export const ForgotPasswordResult = () => {
  const currentPasswordState = useReactiveVar(passwordState)

  const history = useHistory()

  if (currentPasswordState.forgotPassword.success) {
    return (
      <>
        <SiteLogo link className={styles.svg} />
        <LogoTagline />
        <Message message="Password reset email has been sent. Please check your inbox or junk/spam folders." />
        <Link
          className={styles.loginHelp}
          href="https://support.uplifter.ai/hc/en-us/articles/360018765078-I-can-t-login-to-Uplifter"
        >
          Need help logging in?
        </Link>
      </>
    )
  }

  return (
    <>
      <SiteLogo link className={styles.svg} />
      <LogoTagline />
      <Message
        message={`We are currently experiencing some issues. Please [try again](/login) or contact us by emailing [${supportEmail}](mailto:${supportEmail}).`}
      />
      <h2>Don't have an account?</h2>
      <Button
        color="grey"
        style={{ marginBottom: 16 }}
        onPress={() => history.push('/create-account')}
      >
        Sign up here
      </Button>
      <Link
        className={styles.link}
        href="https://support.uplifter.ai/hc/en-us/articles/360018765078-I-can-t-login-to-Uplifter"
      >
        Need help logging in?
      </Link>
    </>
  )
}

const ForgotPassword = () => {
  const currentPasswordState = useReactiveVar(passwordState)

  // Reset passwordState when component mounts
  useEffect(() => {
    passwordState({
      ...currentPasswordState,
      forgotPassword: {
        requested: false,
        success: false,
      },
    })
  }, [])

  return (
    <div className={styles.ssoWrapperOuter}>
      <section className={styles.ssoWrapper}>
        <Carousel
          containerClassName={styles.forgotPasswordContainer}
          currentProgress={
            currentPasswordState.forgotPassword.requested ? 1 : 0
          }
          items={[
            {
              key: 1,
              component: <ForgotPasswordForm />,
            },
            {
              key: 2,
              component: <ForgotPasswordResult />,
            },
          ]}
        />
      </section>
      <Footer />
    </div>
  )
}

export default ForgotPassword
