import _ from 'lodash'

import { isValidUrl } from '.'
import { CustomDomainType } from './custom-links'
import { getLocalItem, setLocalItem } from './local-client'
import { getAnchorFromString, hrefRegex, httpRegex } from './track-module'
import {
  AddCode,
  GetAvailableEloquaEmailGroupsQuery,
  GetAvailableEloquaTemplatesQuery,
  GetCampaignCodeGeneratorQuery,
  GetSalesforceEmailTemplatesQuery,
} from '../__gql-types__/graphql'

export const trackCreateTabs = {
  web: 'One or multiple',
  cloneAndEdit: 'Clone and edit',
  bulkCSV: 'Bulk CSV',
  email: 'Email',
}

export type SavedFormType = 'web' | 'email'

export function isSavedFormType(value: string): value is SavedFormType {
  return ['web', 'email'].includes(value)
}

export interface GeneratorParameterValues {
  [fieldID: string]: string[]
}

export interface AppValues {
  appGroupID: string
  appScreen: string
}

export type LinkToOptions = 'url' | 'app' | 'vCard'

export const vCardFieldDefs: {
  [fieldID: string]: {
    required: boolean
    label: string
    vCardName?: string
    tooltip?: string
  }
} = {
  firstName: {
    required: true,
    label: 'First name',
  },
  lastName: {
    required: true,
    label: 'Last name',
  },
  organisation: {
    required: false,
    label: 'Organisation',
    vCardName: 'ORG',
  },
  jobTitle: {
    required: false,
    label: 'Job title',
    vCardName: 'TITLE',
  },
  url: {
    required: false,
    label: 'Website',
    vCardName: 'URL',
  },
  email: {
    required: false,
    label: 'Email',
    vCardName: 'EMAIL',
  },
  mobilePhone: {
    required: false,
    label: 'Phone',
    vCardName: 'TEL;TYPE=voice,cell,pref',
  },
  street: {
    required: false,
    label: 'Street',
  },
  city: {
    required: false,
    label: 'City',
  },
  state: {
    required: false,
    label: 'State/Province',
  },
  country: {
    required: false,
    label: 'Country',
  },
  zipCode: {
    required: false,
    label: 'Zipcode/Postcode',
  },
}

export interface WebLinkForm {
  url: string[]
  linkTo: LinkToOptions
  appValues?: AppValues
  generatorParameterValues: GeneratorParameterValues
  vCardFieldValues?: {
    [fieldName: keyof typeof vCardFieldDefs]: string
  }
}

export const maxEmailHtmlLength = 100000

export type EmailSources =
  | 'emailHtml'
  | 'salesforceMC'
  | 'lightning'
  | 'classic'
  | 'eloqua'

// Ensure this is up to date with EmailSources type
export const emailSourcesArray: EmailSources[] = [
  'emailHtml',
  'salesforceMC',
  'lightning',
  'classic',
  'eloqua',
]

interface BaseEmailFields {
  emailHtml: string
  generatedEmailHtml?: string
}

interface HTMLEmailFields {
  emailSource: 'emailHtml'
}

export interface EloquaEmailFields {
  eloquaOrg: {
    eloquaOrgID: string
    eloquaOrgName: string
    eloquaOrgDisplayName: string
  } | null
  emailGroup:
    | GetAvailableEloquaEmailGroupsQuery['track']['eloquaQueries']['getAvailableEloquaEmailGroups']['eloquaEmailGroupList'][0]
    | {
        groupID: null
        name: '[Ungrouped - Search templates manually]'
      }
    | null
  emailTemplate:
    | GetAvailableEloquaTemplatesQuery['track']['eloquaQueries']['getAvailableEloquaTemplateList']['eloquaTemplateList'][0]
    | null
}

interface FullEloquaEmailFields {
  emailSource: 'eloqua'
  eloquaEmailFields?: EloquaEmailFields | null
}

export type SalesforceEmailTemplate =
  | GetSalesforceEmailTemplatesQuery['track']['availableSalesforcePardotEmailTemplates']['classicBuilder'][0]
  | GetSalesforceEmailTemplatesQuery['track']['availableSalesforcePardotEmailTemplates']['lightningBuilder'][0]

export interface SalesforcePardotEmailFields {
  businessUnitId: string | null
  emailTemplate: SalesforceEmailTemplate | null
}

interface FullSalesforcePardotEmailFields {
  emailSource: 'lightning' | 'classic'
  salesforcePardotEmailFields?: SalesforcePardotEmailFields | null
}

interface SalesforceMCEmailTemplateSlot {
  slotID: string
  hasLinks: boolean
  content: string
  blocks: {
    blockID: string
    content: string
  }[]
}

export type SalesforceMCTemplateType = 'template' | 'templatebasedemail'

export interface SalesforceMCEmailFields {
  businessUnitId: string | null
  businessUnitName: string | null
  templateID: string | null
  templateType: SalesforceMCTemplateType | null
  /** template only requires updating the HTML, templatebasedemail needs slotMap updated too */
  slotMap: SalesforceMCEmailTemplateSlot[] | null
  /** templatebasedemail needs textContent updated */
  textContent: string | null
}

interface FullSalesforceMCEmailFields {
  emailSource: 'salesforceMC'
  salesforceMCEmailFields?: SalesforceMCEmailFields
}

type PlatformSpecificEmailFields =
  | HTMLEmailFields
  | FullEloquaEmailFields
  | FullSalesforcePardotEmailFields
  | FullSalesforceMCEmailFields

export type EmailFields = BaseEmailFields & PlatformSpecificEmailFields

export interface EmailForm {
  emailFields: EmailFields
  generatorParameterValues: GeneratorParameterValues
}

export function formIsEloquaEmailFields(
  form: Partial<EmailFields>,
): form is BaseEmailFields & FullEloquaEmailFields {
  return form.emailSource === 'eloqua'
}

export function formIsSalesforcePardotEmailFields(
  form: Partial<EmailFields>,
): form is BaseEmailFields & FullSalesforcePardotEmailFields {
  return ['lightning', 'classic'].includes(form.emailSource || '')
}

export function formIsSalesforceMCEmailFields(
  form: Partial<EmailFields>,
): form is BaseEmailFields & FullSalesforceMCEmailFields {
  return form.emailSource === 'salesforceMC'
}

export interface WorkspaceForm {
  web: WebLinkForm
  email: EmailForm
  options: {
    active: SavedFormType
    shortLinkDomain: string
  }
}

export function formIsWebLinkForm(
  form: WebLinkForm | EmailForm,
): form is WebLinkForm {
  return Object.prototype.hasOwnProperty.call(form, 'url')
}

export interface UpdateFormValuesVars {
  url?: string[]
  linkTo?: LinkToOptions
  appValues?: {
    appGroupID?: string | null
    appScreen?: string | null
  } | null
  emailFields?: Partial<EmailFields> | null
  generatorParameterValues?: GeneratorParameterValues
  vCardFieldValues?: {
    [fieldName: string]: string
  } | null
}

interface TrackCreateLocalStorage {
  [workspaceID: string]: WorkspaceForm
}

export const emailSourceOptions: {
  emailSource: EmailSources
  emailSourceName: string
}[] = [
  {
    emailSource: 'eloqua',
    emailSourceName: 'Eloqua',
  },
  {
    emailSource: 'salesforceMC',
    emailSourceName: 'Salesforce Marketing Cloud',
  },
  {
    emailSource: 'classic',
    emailSourceName: 'Salesforce, MCAE (Pardot): Classic Builder',
  },
  {
    emailSource: 'lightning',
    emailSourceName: 'Salesforce, MCAE (Pardot): Lightning Builder',
  },
  {
    emailSource: 'emailHtml',
    emailSourceName: 'Other (Copy & paste HTML manually)',
  },
]

export const defaultFormData: WorkspaceForm = {
  web: { url: [], linkTo: 'url', generatorParameterValues: {} },
  email: {
    emailFields: { emailSource: 'emailHtml', emailHtml: '' },
    generatorParameterValues: {},
  },
  options: {
    active: 'web',
    shortLinkDomain: '',
  },
}

export const getTrackCreateFormData = (workspaceID: string): WorkspaceForm => {
  const savedData: TrackCreateLocalStorage =
    getLocalItem('track-create') || null

  if (!savedData) {
    const newData = {
      ...defaultFormData,
    }

    setLocalItem('track-create', {
      [workspaceID]: newData,
    })

    return newData
  }

  const workspaceData = savedData[workspaceID]

  // Set default value for empty object
  if (!workspaceData || Object.keys(workspaceData).length === 0) {
    const newData = {
      ...defaultFormData,
    }

    setLocalItem('track-create', {
      ...savedData,
      [workspaceID]: newData,
    })

    return newData
  }

  const { web, email, options } = workspaceData

  const newData: WorkspaceForm = {
    web: web || defaultFormData.web,
    email: email || defaultFormData.email,
    options,
  }

  let updatedSavedData = false

  // Set default values for missing fields
  if (
    !Object.prototype.hasOwnProperty.call(
      newData.web,
      'generatorParameterValues',
    )
  ) {
    newData.web = {
      ...newData.web,
      generatorParameterValues: defaultFormData.web.generatorParameterValues,
    }

    updatedSavedData = true
  }

  if (!Object.prototype.hasOwnProperty.call(newData.email, 'emailFields')) {
    newData.email = {
      ...newData.email,
      emailFields: defaultFormData.email.emailFields,
    }

    updatedSavedData = true
  }

  if (
    !Object.prototype.hasOwnProperty.call(
      newData.email,
      'generatorParameterValues',
    )
  ) {
    newData.email = {
      ...newData.email,
      generatorParameterValues: defaultFormData.email.generatorParameterValues,
    }

    updatedSavedData = true
  }

  // Replace existing value in localStorage
  if (updatedSavedData) {
    setLocalItem('track-create', {
      ...savedData,
      [workspaceID]: newData,
    })
  }

  return newData
}

/** Update the default tab the user is taken to on page reload */
export const saveTrackCreateTab = (
  workspaceID: string,
  formType: SavedFormType,
) => {
  if (!workspaceID) return

  const newData: TrackCreateLocalStorage = getLocalItem('track-create') || {}

  if (!newData[workspaceID]) {
    newData[workspaceID] = { ...defaultFormData }
  }

  const workspaceData = newData[workspaceID]

  workspaceData.options = {
    ...workspaceData.options,
    active: formType,
  }

  setLocalItem('track-create', newData)
}

/** Updates formData with values from options, preserving all unchanged fields */
export const updateTrackCreateFormData = (
  formData: WebLinkForm | EmailForm,
  options: UpdateFormValuesVars,
) => {
  const newFormData = _.cloneDeep(formData)

  const {
    url,
    linkTo,
    appValues,
    emailFields,
    generatorParameterValues,
    vCardFieldValues,
  } = options

  if (formIsWebLinkForm(newFormData)) {
    if (url) {
      newFormData.url = url
    }

    if (linkTo) {
      newFormData.linkTo = linkTo
    }

    if (appValues !== undefined) {
      if (appValues === null) {
        newFormData.appValues = undefined
      } else {
        const { appGroupID, appScreen } = appValues

        const newAppValues = {
          appGroupID:
            appGroupID === null
              ? ''
              : appGroupID || newFormData.appValues?.appGroupID || '',
          appScreen:
            appScreen === null
              ? ''
              : appScreen || newFormData.appValues?.appScreen || '',
        }

        newFormData.appValues = newAppValues
      }
    }

    if (vCardFieldValues !== undefined) {
      if (vCardFieldValues === null) {
        newFormData.vCardFieldValues = undefined
      } else {
        newFormData.vCardFieldValues = {
          ...newFormData.vCardFieldValues,
          ...vCardFieldValues,
        }
      }
    }
  } else if (emailFields !== undefined) {
    // Reset all fields
    if (emailFields === null) {
      newFormData.emailFields = {
        emailSource: newFormData?.emailFields?.emailSource || 'html',
        emailHtml: '',
      }
    } else {
      const { emailSource, emailHtml, generatedEmailHtml } = emailFields || {}

      // Set an initial value if not found
      if (!newFormData.emailFields) {
        newFormData.emailFields = {
          emailSource: emailSource || 'emailHtml',
          emailHtml: emailSource ? '' : emailHtml || '',
        }
      } else {
        // Email source is changed, all other values should be reset
        if (emailSource) {
          newFormData.emailFields = { emailSource, emailHtml: '' }
        }

        if (emailHtml !== undefined) {
          newFormData.emailFields = { ...newFormData.emailFields, emailHtml }
        }

        if (
          formIsEloquaEmailFields(emailFields) &&
          emailFields.eloquaEmailFields !== undefined
        ) {
          newFormData.emailFields = {
            ...newFormData.emailFields,
            eloquaEmailFields: emailFields.eloquaEmailFields,
          } as BaseEmailFields & FullEloquaEmailFields
        } else if (
          formIsSalesforcePardotEmailFields(emailFields) &&
          emailFields.salesforcePardotEmailFields !== undefined
        ) {
          newFormData.emailFields = {
            ...newFormData.emailFields,
            salesforcePardotEmailFields:
              emailFields.salesforcePardotEmailFields,
          } as BaseEmailFields & FullSalesforcePardotEmailFields
        } else if (
          formIsSalesforceMCEmailFields(emailFields) &&
          emailFields.salesforceMCEmailFields !== undefined
        ) {
          newFormData.emailFields = {
            ...newFormData.emailFields,
            salesforceMCEmailFields: emailFields.salesforceMCEmailFields,
          } as BaseEmailFields & FullSalesforceMCEmailFields
        }

        if (generatedEmailHtml) {
          newFormData.emailFields = {
            ...newFormData.emailFields,
            generatedEmailHtml,
          }
        }
      }
    }
  }

  if (generatorParameterValues) {
    newFormData.generatorParameterValues = {
      ...newFormData.generatorParameterValues,
      ...generatorParameterValues,
    }
  }

  return newFormData
}

export const saveTrackCreateFormData = (
  workspaceID: string,
  formType: SavedFormType,
  options: UpdateFormValuesVars,
) => {
  if (!workspaceID) return

  const newData: TrackCreateLocalStorage = getLocalItem('track-create') || {}

  if (!newData[workspaceID]) {
    newData[workspaceID] = { ...defaultFormData }
  }

  let formData = newData[workspaceID][formType] as WebLinkForm | EmailForm

  if (!formData) {
    formData =
      formType === 'web'
        ? { ...defaultFormData.web }
        : { ...defaultFormData.email }
  }

  setLocalItem('track-create', {
    ...newData,
    [workspaceID]: {
      ...newData[workspaceID],
      [formType]: updateTrackCreateFormData(formData, options),
      options: {
        ...newData[workspaceID].options,
        active: formType,
      },
    },
  })
}

export const makeLinkSecure = (link: string) => {
  if (
    link &&
    typeof link === 'string' &&
    link.search(/(https:\/\/)|(http:\/\/)/gi) === -1 &&
    link.search(/.\../i) !== -1
  ) {
    return `https://${link}`
  }
  return link
}

export const getExistingQueryString = (url: string) => {
  const existingQueryString = url.match(/\?[^#]+/)

  if (!existingQueryString) return ''

  return existingQueryString[existingQueryString.length - 1].replace('?', '')
}

export type LinkType = 'basic' | CustomDomainType

interface CombinationFieldValue {
  fieldID: string
  optionID: string
  /** [optionName, optionValue] */
  submitValue: [string, string]
  validParentSelectOptions?: { parentFieldID: string; validOptions: string[] }
  onlyShowIf?: { parentFieldID: string; validOptions: string[] }
}

export type Combination = CombinationFieldValue[]

export interface FullLinkResult extends Omit<AddCode, 'pDfs'> {
  pDfs: Combination
  urlWithHash: string
  selected: boolean
  urlLength: number
  queryLength: number
  /**
   * The initial link before hashes and existing params are handled.
   * Might be the same as urlWithHash. Investigate?
   */
  link: string
}

/** pDfs in this type will not have all params - copyFrom, fixed and unique are added later */
export type PreparedLinkResult = Pick<FullLinkResult, 'link' | 'pDfs'>

export type SoftWarning = 'no-url' | 'no-url-shortlink' | 'has-anchor'
type HardWarning = 'invalid-query-length' | 'invalid-landing-page-length'

export interface SoftWarningModalType {
  type: SoftWarning
  fullLinks: FullLinkResult[]
  ignoreWarnings?: SoftWarning[]
}

export interface HardWarningModalType {
  type: HardWarning
  fullLinks: FullLinkResult[]
  characterLimit: number
  charactersOverLimit: number
}

/** Takes full formValues object and returns how many combinations are possible, ignoring rules */
export const getTotalPossibleCombinations = (formVals: WebLinkForm) => {
  const urlValuesCount = formVals.url.length || 1

  const paramValuesCounts = Object.values(
    formVals.generatorParameterValues,
  ).map((param) => param.length || 1)

  return urlValuesCount * paramValuesCounts.reduce((acc, curr) => acc * curr, 1)
}

/**
 * Recursive function that builds all possible combinations of parameter values
 */
const buildFullParamCombinations = (
  paramDefs: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs'],
  paramVals: WebLinkForm['generatorParameterValues'],
  currentCombination: Combination = [],
  fullCombinations: Combination[] = [],
) => {
  // Base case: if no more parameters to process, add the current combination to fullCombinations
  if (paramDefs.length === 0) {
    fullCombinations.push(currentCombination)

    return fullCombinations
  }

  // Separate paramDefs into current and remaining
  const [currentParam, ...remainingParamDefs] = paramDefs

  const {
    fieldID,
    fieldType,
    copyFromField,
    selectFields,
    parameterDependsOn,
  } = currentParam

  const isCopyFromField = copyFromField && copyFromField.length > 0

  /**
   * * Values for fixed, unique, and copyFrom fields are only populated on form submit
   * They do not affect the number of valid link combinations (formVals length can only be 1) and make this function too heavy if included
   * The check should only be made once a full combination is built, because params can be dependent on other params with a greater index
   */
  if (isCopyFromField || ['fixed', 'unique'].includes(fieldType)) {
    const combinationFieldValue: CombinationFieldValue = {
      fieldID,
      optionID: '',
      submitValue: ['', ''],
    }

    // Add onlyShowIf to combinationFieldValue if it exists
    if (parameterDependsOn && parameterDependsOn.parentOptionIDs.length > 0) {
      combinationFieldValue.onlyShowIf = {
        parentFieldID: parameterDependsOn.parentFieldID,
        validOptions: parameterDependsOn.parentOptionIDs,
      }
    }

    // Recursive step: add field value to currentCombination
    buildFullParamCombinations(
      remainingParamDefs,
      paramVals,
      [...currentCombination, combinationFieldValue],
      fullCombinations,
    )
  } else {
    const fieldVals = paramVals[fieldID] || ['']

    // Ensure a value is always present
    if (fieldVals.length === 0) fieldVals.push('')

    fieldVals.forEach((fieldVal) => {
      const combinationFieldValue: CombinationFieldValue = {
        fieldID,
        optionID: '',
        submitValue: ['', ''],
      }

      // Add onlyShowIf to combinationFieldValue if it exists
      if (parameterDependsOn && parameterDependsOn.parentOptionIDs.length > 0) {
        combinationFieldValue.onlyShowIf = {
          parentFieldID: parameterDependsOn.parentFieldID,
          validOptions: parameterDependsOn.parentOptionIDs,
        }
      }

      let id = fieldVal || ''
      let name = fieldVal || ''
      let value = fieldVal || ''

      // Select fields should use optionName and optionValue (fieldVal is the optionID)
      if (
        fieldVal &&
        fieldType === 'select' &&
        selectFields &&
        selectFields.length > 0
      ) {
        const selectField = selectFields.find(
          (field) => field.optionID === fieldVal,
        )

        if (selectField) {
          id = selectField.optionID
          name = selectField.optionName
          value = selectField.optionValue

          // Add filter for quick reference
          if (selectField.optionFilter && selectField.optionFilter.length > 0) {
            const filter = selectField.optionFilter[0]

            combinationFieldValue.validParentSelectOptions = {
              parentFieldID: filter.parentFieldID,
              validOptions: filter.parentOptionIDs,
            }
          }
        }
      }

      combinationFieldValue.optionID = id
      combinationFieldValue.submitValue = [name, value]

      // Recursive step: add field value to currentCombination
      buildFullParamCombinations(
        remainingParamDefs,
        paramVals,
        [...currentCombination, combinationFieldValue],
        fullCombinations,
      )
    })
  }

  return fullCombinations
}

/**
 * Takes Track>Create form values and builds all valid combinations
 * Accounts for parent-child relationships in select fields
 */
const buildValidParamCombinations = (
  paramDefs: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs'],
  paramVals: WebLinkForm['generatorParameterValues'],
  workspaceID: string,
): Combination[] => {
  const fullParamCombinations = buildFullParamCombinations(paramDefs, paramVals)

  let removeDuplicates = false

  const formHasOnlyShowIfFields = paramDefs.find(
    ({ parameterDependsOn }) =>
      parameterDependsOn && parameterDependsOn.parentOptionIDs.length > 0,
  )

  // onlyShowIf fields should be handled here, after full combinations are built
  // They might depend on fields lower in the generator, so don't make sense to populate during recursion
  // If the field has >1 value, there could be duplicate combinations. They should be removed
  if (formHasOnlyShowIfFields) {
    fullParamCombinations.forEach((combination) => {
      const onlyShowIfFields = combination.filter((field) => field.onlyShowIf)

      onlyShowIfFields.forEach((combinationFieldValue) => {
        const parentValue =
          combination.find(
            (field) =>
              field.fieldID === combinationFieldValue.onlyShowIf?.parentFieldID,
          )?.optionID || ''

        // Special case for workspace dependency
        if (combinationFieldValue.onlyShowIf?.parentFieldID === 'account') {
          if (
            !combinationFieldValue.onlyShowIf?.validOptions.includes(
              workspaceID,
            )
          ) {
            combination.splice(
              combination.findIndex(
                (c) => c.fieldID === combinationFieldValue.fieldID,
              ),
              1,
              {
                ...combinationFieldValue,
                optionID: '',
                submitValue: ['', ''],
              },
            )

            removeDuplicates = true
          }

          return
        }

        // Remove values for onlyShowIf fields if condition is not met
        if (
          combinationFieldValue.optionID !== '' &&
          !combinationFieldValue.onlyShowIf?.validOptions.includes(parentValue)
        ) {
          combination.splice(
            combination.findIndex(
              (c) => c.fieldID === combinationFieldValue.fieldID,
            ),
            1,
            {
              ...combinationFieldValue,
              optionID: '',
              submitValue: ['', ''],
            },
          )

          removeDuplicates = true
        }
      })
    })
  }

  // With onlyShowIf fields now set, remove duplicates
  const uniqueCombinations = removeDuplicates
    ? fullParamCombinations.reduce((acc, curr) => {
        const codeString = JSON.stringify(curr)

        if (acc.find((existing) => JSON.stringify(existing) === codeString))
          return acc

        acc.push(curr)

        return acc
      }, [] as Combination[])
    : fullParamCombinations

  // Filter out invalid combinations based on parent-child relationships in select fields
  const validCombinations = uniqueCombinations.filter((combination) => {
    const childFields = combination.filter(
      (field) => field.validParentSelectOptions,
    )

    if (childFields.length === 0) return true

    const valid = childFields.every((childField) => {
      // Special case for workspace dependency
      if (childField.validParentSelectOptions?.parentFieldID === 'account') {
        return childField.validParentSelectOptions.validOptions.includes(
          workspaceID,
        )
      }

      const parentField = combination.find(
        (field) =>
          field.fieldID === childField.validParentSelectOptions?.parentFieldID,
      )

      if (!parentField) return false

      const validParentOptions =
        childField.validParentSelectOptions?.validOptions || []

      return (
        // If valid options is length 0 this child option has no parent restrictions
        // It was previously filtered, but filter was then removed. Annoying use case
        validParentOptions.length === 0 ||
        validParentOptions.includes(parentField.optionID)
      )
    })

    return valid
  })

  return validCombinations
}

/**
 * Builds paramValues ready for use in CreateNewLinks mutation, in the form [name, value]
 * Does NOT include values for copyFrom, fixed, or unique fields.
 * They are added on form submit to minimise network calls.
 */
export const prepareLinkCombinations = (
  formVals: WebLinkForm | EmailForm,
  generatedStructure:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']
    | null,
  workspaceID: string,
): PreparedLinkResult[] => {
  if (!generatedStructure) return []

  const { paramDefs } = generatedStructure

  const preparedLinks: PreparedLinkResult[] = []

  const validParamCombinations = buildValidParamCombinations(
    paramDefs,
    formVals.generatorParameterValues,
    workspaceID,
  )

  if (formIsWebLinkForm(formVals)) {
    const urlsToUse = [...formVals.url]

    if (urlsToUse.length === 0) urlsToUse.push('')

    urlsToUse.forEach((link) => {
      validParamCombinations.forEach((validParamCombination) => {
        preparedLinks.push({
          link,
          pDfs: validParamCombination,
        })
      })
    })
  } else {
    const { emailFields } = formVals

    const foundLinksFull = formVals.emailFields.emailHtml.match(hrefRegex)

    const additionalFoundLinks: string[] = []

    // Add links from slotMap and textContent to full list
    // Specific to Salesforce Marketing Cloud template emails
    if (
      formIsSalesforceMCEmailFields(emailFields) &&
      emailFields.salesforceMCEmailFields?.templateType === 'templatebasedemail'
    ) {
      const { slotMap, textContent } = emailFields.salesforceMCEmailFields

      if (slotMap) {
        const slotMapFoundLinks = slotMap
          .filter((slot) => slot.hasLinks)
          .reduce<string[]>((acc, curr) => {
            const contentLinks = curr.content.match(hrefRegex)

            if (contentLinks) acc.push(...contentLinks)

            const blockLinks = curr.blocks.reduce<string[]>(
              (accBlock, currBlock) => {
                const currBlockLinks = currBlock.content.match(hrefRegex)

                if (currBlockLinks) accBlock.push(...currBlockLinks)

                return accBlock
              },
              [],
            )

            acc.push(...blockLinks)

            return acc
          }, [])

        additionalFoundLinks.push(...slotMapFoundLinks)
      }

      if (textContent) {
        const textContentFoundLinks = textContent.match(httpRegex)

        if (textContentFoundLinks) {
          additionalFoundLinks.push(...textContentFoundLinks)
        }
      }
    }

    const uniqueAdditionalFoundLinks = [...new Set(additionalFoundLinks)]

    let foundLinks =
      foundLinksFull?.map((link) =>
        link.replaceAll(/(href=)?(3D)?['"]*/g, ''),
      ) || []

    foundLinks.push(...uniqueAdditionalFoundLinks)

    foundLinks = foundLinks.filter((link) => isValidUrl(link))

    foundLinks.forEach((link) => {
      validParamCombinations.forEach((validParamCombination) => {
        preparedLinks.push({
          link,
          pDfs: validParamCombination,
        })
      })
    })
  }

  return preparedLinks
}

/** Takes PreparedLinkResults and applies automated parameters (copyFrom, onlyShowIf) values, ready for final links */
export const addAutomatedParams = (
  currentPreparedLink: PreparedLinkResult,
  currentPreparedLinkIndex: number,
  generatedStructure: GetCampaignCodeGeneratorQuery['campaignCodeGenerator'],
  workspaceID: string,
  uniqueParamTotals: { [fieldID: string]: string } = {},
) => {
  const {
    masterPrefix,
    paramSeparator,
    paramDefs,
    validationChecks,
    existingParametersAddedToStart,
  } = generatedStructure

  /** Rule to include empty parameter values in built link */
  const includeEmptyValuesRule = validationChecks.find(
    (check) => check.name === 'INCLUDE_EMPTY_VALUES',
  )

  const includeEmptyValues = !!(
    includeEmptyValuesRule && includeEmptyValuesRule.enabled
  )

  const fullLinkPdfs = [...currentPreparedLink.pDfs]

  // Values to be used in URL
  const paramQueryValues: string[] = Array.from(
    new Array(paramDefs.length),
  ).map(() => '')

  // First pass: Add fixed and unique fields
  paramDefs.forEach((param, paramIndex) => {
    const {
      fieldID,
      fieldType,
      prefix,
      metaParameter,
      copyFromField,
      fixedValue,
    } = param

    if (['fixed', 'unique'].includes(fieldType)) {
      /** Checker for if values for this param should be added to the link */
      let addValue = true

      const { onlyShowIf } = fullLinkPdfs[paramIndex]

      // Check which values the parent can have for this field to be added
      if (onlyShowIf) {
        const { parentFieldID, validOptions } = onlyShowIf

        if (parentFieldID === 'account') {
          addValue = validOptions.includes(workspaceID)
        } else {
          // Get the parent field's value in the current combination
          const parentField = fullLinkPdfs.find(
            ({ fieldID: parentID }) => parentID === parentFieldID,
          )

          if (!parentField) {
            throw new Error('Parent field not found')
          }

          // Get value for current link
          // Parent field can only be a dropdown in onlyShowIf logic
          const parentFieldValue = parentField.optionID

          if (!validOptions.includes(parentFieldValue)) {
            addValue = false
          }
        }
      }

      // Only add parameter value if its dependencies are met
      if (!addValue) return

      if (fieldType === 'fixed') {
        if (!fixedValue) {
          throw new Error('Fixed value missing')
        }

        // Replace the placeholder array with the correct values
        fullLinkPdfs.splice(paramIndex, 1, {
          fieldID,
          optionID: fixedValue,
          submitValue: [fixedValue, fixedValue],
        })
      }

      if (fieldType === 'unique') {
        const currentUniqueParamValue = fullLinkPdfs[paramIndex].optionID

        // If this parameter already has a value, this function has already been run to add it
        // Resetting the value is problematic, so don't do it
        if (!currentUniqueParamValue) {
          const { uniqueIDEtag } = param

          if (!uniqueIDEtag || !uniqueParamTotals[fieldID]) {
            throw new Error('Unique ID missing')
          }

          const uniqueValToUse = (
            parseInt(uniqueParamTotals[fieldID], 16) +
            currentPreparedLinkIndex +
            1
          ).toString(16)

          fullLinkPdfs.splice(paramIndex, 1, {
            fieldID,
            optionID: uniqueValToUse,
            submitValue: [uniqueValToUse, uniqueValToUse],
          })
        }
      }
    }

    const isCopyFromField = copyFromField && copyFromField.length > 0

    // Only add to link's URL values if not a meta parameter and not empty (based on optionName)
    if (
      !isCopyFromField &&
      !metaParameter &&
      (includeEmptyValues || fullLinkPdfs[paramIndex].submitValue[0] !== '')
    ) {
      paramQueryValues.splice(
        paramIndex,
        1,
        `${prefix}${fullLinkPdfs[paramIndex].submitValue[1]}`,
      )
    }
  })

  // Last pass: Add copyFrom fields (We need to do 2 passes because these could include fixed and unique fields)
  paramDefs.forEach((param, paramIndex) => {
    const { fieldID, prefix, metaParameter, copyFromField } = param

    // copyFrom is last so fixed and unique fields can be copied too
    const isCopyFromField = copyFromField && copyFromField.length > 0

    if (!isCopyFromField) return

    /** Checker for if values for this param should be added to the link */
    let addValue = true

    const { onlyShowIf } = fullLinkPdfs[paramIndex]

    // Check which values the parent can have for this field to be added
    if (onlyShowIf) {
      const { parentFieldID, validOptions } = onlyShowIf

      if (parentFieldID === 'account') {
        addValue = validOptions.includes(workspaceID)
      } else {
        // Get the parent field's value in the current combination
        const parentField = fullLinkPdfs.find(
          ({ fieldID: parentID }) => parentID === parentFieldID,
        )

        if (!parentField) {
          throw new Error('Parent field not found')
        }

        // Get value for current link
        // Parent field can only be a dropdown in onlyShowIf logic
        const parentFieldValue = parentField.optionID

        if (!validOptions.includes(parentFieldValue)) {
          addValue = false
        }
      }
    }

    // Only add parameter value if its dependencies are met
    if (!addValue) return

    const copyFromIncludePrefixRule = validationChecks.find(
      (check) => check.name === 'INCLUDE_PREFIX_WITH_COPY_FROM',
    )

    /** If field is copied from others, should the copied field's prefix be part of the value? */
    const copyFromIncludePrefix =
      copyFromIncludePrefixRule && copyFromIncludePrefixRule.enabled

    /** If field is copied from other fields, how should multiple values be separated? */
    const copyFromSeparator =
      validationChecks.find((check) => check.name === 'COPY_FROM_SEPARATOR')
        ?.value || '|'

    // Fetch values of copied fields in the form
    const copyFromValue = copyFromField.reduce(
      (acc, { copyFromID, truncateToLength }, currentIndex) => {
        let fullCopiedValue = ''

        // Get full copied field
        const copiedFieldIndex = paramDefs.findIndex(
          (copiedParam) => copiedParam.fieldID === copyFromID,
        )

        let valueToCopy = fullLinkPdfs[copiedFieldIndex].submitValue[1]

        // Do not add separator before first copied value
        // * Special case: do not use copyFromSeparator if the copied param does not have a prefix
        // * The copied values might combine to a single value for the generator, so should not use copyFrom separator
        if (
          currentIndex > 0 &&
          paramDefs[copiedFieldIndex]?.prefix !== '' &&
          valueToCopy !== ''
        ) {
          fullCopiedValue += copyFromSeparator
        }

        // Add copied field's prefix if required
        if (copyFromIncludePrefix) {
          fullCopiedValue += paramDefs[copiedFieldIndex]?.prefix
        }

        if (valueToCopy.length > 0) {
          // Truncates copied values if necessary
          const valueSlice = truncateToLength || valueToCopy.length

          valueToCopy = valueToCopy.slice(0, valueSlice)
        }

        fullCopiedValue += valueToCopy

        return `${acc}${fullCopiedValue}`
      },
      '',
    )

    // Replace the placeholder array with the correct values
    fullLinkPdfs.splice(paramIndex, 1, {
      fieldID,
      optionID: copyFromValue,
      submitValue: [copyFromValue, copyFromValue],
    })

    // Only add to link's URL values if not a meta parameter and not empty
    if (!metaParameter && (includeEmptyValues || copyFromValue !== '')) {
      paramQueryValues.splice(paramIndex, 1, `${prefix}${copyFromValue}`)
    }
  })

  let queryString = paramQueryValues
    .filter((param) => param !== '')
    .join(paramSeparator)

  // Remove whitespace in the generated link, but only if the link is not empty
  if (currentPreparedLink.link !== '') {
    queryString = queryString.replaceAll(/\s/g, '%20')
  }

  const secureLink = makeLinkSecure(currentPreparedLink.link)

  // Add negative lookahead to accomodate hash based routing
  // https://example.com/#/# should not have the first hash removed
  const linkAnchor = getAnchorFromString(secureLink)
  const anchorReplaceRegex = new RegExp(
    `#(?!\/)${linkAnchor.replace('#', '')}`,
    'i',
  )

  const linkWithoutHash = secureLink.replace(anchorReplaceRegex, '')

  let fullCode: string

  const linkHasQueryString = linkWithoutHash.indexOf('?') !== -1

  // Move existing parameters to the end
  if (linkHasQueryString && !existingParametersAddedToStart) {
    const existingQueryString = getExistingQueryString(linkWithoutHash)

    const linkWithoutExistingParams = linkWithoutHash
      .replace(existingQueryString, '')
      .replace('?', '')

    fullCode = `${linkWithoutExistingParams}${masterPrefix}${queryString}${
      existingQueryString ? `&${existingQueryString}` : ''
    }${linkAnchor}`
  } else {
    const isPrefixLastCharacter =
      linkHasQueryString &&
      linkWithoutHash.indexOf('?') === linkWithoutHash.length - 1

    const prefixToReplace = isPrefixLastCharacter ? '' : '&'

    fullCode = `${linkWithoutHash}${
      linkHasQueryString
        ? masterPrefix.replace('?', prefixToReplace)
        : masterPrefix
    }${queryString}${linkAnchor}`
  }

  return { fullLinkPdfs, fullCode, queryString, secureLink, linkWithoutHash }
}

export interface CreateVCardArgs {
  title?: string
  firstName: string
  lastName: string
  street?: string
  city?: string
  state?: string
  country?: string
  zipCode?: string
  [vCardName: string]: string | undefined
}

export const createVCard = ({
  title,
  firstName,
  lastName,
  street,
  city,
  state,
  country,
  zipCode,
  ...rest
}: CreateVCardArgs) => {
  const includeAddress = street || city || state || country || zipCode

  return `
BEGIN:VCARD
VERSION:3.0
FN:${title ? `${title} ` : ''}${firstName} ${lastName}
N:${lastName};${firstName};;${title ? `${title};` : ''}
${Object.keys(rest)
  .map((vCardName) => {
    return `${vCardName}:${rest[vCardName]}`
  })
  .join('\n')}
${
  includeAddress ? `ADR:;;${street};${city};${state};${zipCode};${country}` : ''
}
END:VCARD
`
}
