import React, { useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'

import Button, { CopyButton } from './button'
import { FormField, FormLabel, FormRow } from './form'
import { PassiveInput } from './input'
import QRCodeModal from './qr-code-modal'
import Table from './table-v2'
import QRCodePreviewRow from './track-create-qr-preview-row'
import { InnerBox, OuterBox } from './two-columns'
import { Heading } from './typography'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import QRIcon from '../assets/icon-qr.svg'
import {
  downloadSpecificCodes,
  getCsvString,
  getMultiCodesToCopy,
  MinCodesByUserResult,
} from '../helpers/track-module'
import styles from '../styles/track-create-last-links.module.scss'

interface TrackCreateLastLinksTableProps {
  linksToShow: MinCodesByUserResult[]
  setShowQrModal: React.Dispatch<React.SetStateAction<string | null>>
}

export const TrackCreateLastLinksTable = ({
  linksToShow,
  setShowQrModal,
}: TrackCreateLastLinksTableProps) => {
  const previewTableHeaderColumns = useMemo(() => {
    const columns = [{ id: 'fullLink', content: 'Basic link' }]

    if (linksToShow[0].shortLink) {
      columns.unshift({ id: 'shortLink', content: 'Short link' })
    }

    // Show param columns, but only if at least one row has a value for that param
    linksToShow[0].params.forEach(({ paramID, paramName }, paramIndex) => {
      if (linksToShow.some(({ params }) => !!params[paramIndex].paramValue)) {
        columns.push({
          id: paramID,
          content: paramName,
        })
      }
    })

    return columns
  }, [linksToShow])

  return (
    <Table
      containerClassName={styles.previewTableContainer}
      headerColumns={previewTableHeaderColumns}
      rowIDKey="fullLink"
      tableData={linksToShow}
      maxRowsPerPage={50}
    >
      {({ shortLink, fullLink, params }) => {
        return (
          <>
            {shortLink && (
              <td className={styles.shortLinkColumn}>
                <div>
                  <p>{shortLink}</p>
                  <div className={styles.cellActions}>
                    <Button
                      variant="iconOnly"
                      icon={{
                        src: QRIcon,
                        alt: 'QR code',
                      }}
                      onPress={() => setShowQrModal(shortLink)}
                    />
                    <CopyButton value={shortLink} />
                  </div>
                </div>
              </td>
            )}
            <td className={styles.fullLinkColumn}>
              <div>
                <p>{fullLink}</p>
                <div className={styles.cellActions}>
                  <Button
                    variant="iconOnly"
                    icon={{
                      src: QRIcon,
                      alt: 'QR code',
                    }}
                    onPress={() => setShowQrModal(fullLink)}
                  />
                  <CopyButton value={fullLink} />
                </div>
              </div>
            </td>
            {params.map(({ paramID, paramValue }, paramIndex) => {
              if (
                linksToShow.some(
                  ({ params: rowParams }) => !!rowParams[paramIndex].paramValue,
                )
              ) {
                return (
                  <td key={paramID}>
                    <div>
                      <p>{paramValue}</p>
                      <div className={styles.cellActions}>
                        <CopyButton value={paramValue || ''} />
                      </div>
                    </div>
                  </td>
                )
              }

              return null
            })}
          </>
        )
      }}
    </Table>
  )
}

interface TrackCreateLastLinksProps {
  newlyCreatedLinks: MinCodesByUserResult[]
  qrColours: { fgColour: string; bgColour: string }
  onChangeQrColours: (type: 'fg' | 'bg', colour: string) => void
}

const TrackCreateLastLinks = ({
  newlyCreatedLinks,
  qrColours,
  onChangeQrColours,
}: TrackCreateLastLinksProps) => {
  const { userEmail } = useReactiveVar(currentUserDetails)

  const referToLinks = useReactiveVar(linkOrCode)

  const [showQrModal, setShowQrModal] = useState<string | null>(null)

  const fullCopyValue = useMemo(() => {
    return getMultiCodesToCopy(newlyCreatedLinks).all
  }, [newlyCreatedLinks])

  return (
    <>
      <OuterBox>
        <InnerBox>
          <FormRow
            className={styles.noPadding}
            includePaddingBottom={newlyCreatedLinks.length === 1}
            bottomBorder={newlyCreatedLinks.length === 1}
          >
            <FormLabel className={styles.lastCreatedLabel}>
              {newlyCreatedLinks.length === 1 ? (
                <>Your last created {referToLinks}</>
              ) : (
                <Heading type={3} align="left" style={{ margin: 0 }}>
                  Your last created {referToLinks}s
                </Heading>
              )}
            </FormLabel>
            <FormField>
              {newlyCreatedLinks.length === 1 ? (
                <div className={styles.linkPreview}>
                  <PassiveInput
                    id="generated-code"
                    value={
                      newlyCreatedLinks[0]?.shortLink ||
                      newlyCreatedLinks[0].fullLink
                    }
                  />
                  <CopyButton
                    value={
                      newlyCreatedLinks[0]?.shortLink ||
                      newlyCreatedLinks[0].fullLink
                    }
                  >
                    Copy
                  </CopyButton>
                </div>
              ) : (
                <div className={styles.copyAllButtons}>
                  <CopyButton value={fullCopyValue}>Copy all</CopyButton>
                  <div className={styles.divider} />
                  <Button
                    variant="secondary"
                    onPress={async () => {
                      const csv = getCsvString({
                        userEmail,
                        codes: newlyCreatedLinks,
                      })

                      await downloadSpecificCodes(csv)
                    }}
                  >
                    Download all
                  </Button>
                </div>
              )}
            </FormField>
          </FormRow>

          {newlyCreatedLinks.length === 1 ? (
            <QRCodePreviewRow
              linkToShow={
                newlyCreatedLinks[0]?.shortLink || newlyCreatedLinks[0].fullLink
              }
              showCopy
              showBiline
              showDownload
              qrColours={qrColours}
              onChangeColour={onChangeQrColours}
            />
          ) : (
            <FormRow>
              <TrackCreateLastLinksTable
                linksToShow={newlyCreatedLinks}
                setShowQrModal={setShowQrModal}
              />
            </FormRow>
          )}
        </InnerBox>
      </OuterBox>
      {showQrModal && (
        <QRCodeModal
          code={showQrModal}
          setShowModal={() => setShowQrModal(null)}
          initialFgColour={qrColours.fgColour}
          initialBgColour={qrColours.bgColour}
        />
      )}
    </>
  )
}

export default TrackCreateLastLinks
