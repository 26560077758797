import {
  useState,
  useMemo,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'
import _ from 'lodash'

import { sortData } from '../helpers'

export type SortFn<T> = (
  row: T,
  orderAsc?: boolean,
  key?: string,
) => number | string | Date

export interface SortFns<T> {
  [key: string]: SortFn<T>
}

export type SearchFn<T> = (data: T, search?: string) => T
export interface SearchFns<T> {
  [key: string]: SearchFn<T>
}

interface TableSortInput<
  InputCols extends UnknownObj,
  SecondaryInputCols extends UnknownObj = InputCols
> {
  inputList: InputCols[] | null | undefined
  startingSortKey?: string
  startingSearchKey?: string
  startingRowsPerPage?: number
  customSearches?: SearchFns<(InputCols | SecondaryInputCols)[]>
  useFuzzySearch?: boolean
  customSorts?: SortFns<InputCols | SecondaryInputCols>
  initialSortAsc?: boolean
  secondaryInputList?: SecondaryInputCols[]
}

interface TableSortOutput<
  InputCols extends UnknownObj,
  SecondaryInputCols extends UnknownObj
> {
  initialSort: boolean
  setInitialSort: Dispatch<SetStateAction<boolean>>
  setToBeSorted: Dispatch<SetStateAction<boolean>>
  orderAsc: boolean
  sortKey: string
  setSortOrder: (key: string, defSort?: boolean) => void
  rowsPerPage: number
  setRowsPerPage: Dispatch<SetStateAction<number>>
  pages: number
  setPages: Dispatch<SetStateAction<number>>
  activePage: number
  setActivePage: Dispatch<SetStateAction<number>>
  selectedSearchType: string
  setSelectedSearchType: Dispatch<SetStateAction<string>>
  searchTerm: string
  setSearchTerm: Dispatch<SetStateAction<string>>
  count: number
  orderedList: (InputCols | SecondaryInputCols)[][]
  total: number
}

/**
 * @description Returns a filtered, sorted & paginated (array of arrays) version of the input array data. Also includes states to update the output table.

  * @param inputList Required array of objects. At least some props should be columns in the table
  * @param startingSortKey Must be a key shared by input list items. Set to empty string to not sort on load.
  * @param startingSearchKey Must be a key shared by input list items. Uses generic search function if set to empty string.
  * @param startingRowsPerPage Optional. Set to 25 if not given.
  * @param customSearches Optional. Object of search keys with values being a custom search function. Will do a basic alphanumeric fuzzy match if not set.
  * @param useFuzzySearch Optional. Define whether to use fuzzy search or substring match for generic search function.
  * @param customSorts Optional. Object of sortKeys with values being the custom sort function that key has. Include an 'all' key for a custom all function, e.g. when one of the columns is a date. If not given, all fields in the inputList will be included in search. Use * for all keySorts
  * @param secondaryInputList Optional. Including this parameter ensures the two lists aren't mixed together when sorted. You should used custom search functions when a secondary list is present. E.g. existingUsers is the main input list, pending is secondary. For sorting users, pending should all go at the bottom initially.
*/

export default function useTableSortFilter<
  InputCols extends UnknownObj,
  SecondaryInputCols extends UnknownObj = InputCols
>({
  inputList,
  startingSortKey = '',
  startingSearchKey = '',
  startingRowsPerPage = 25,
  customSearches = {},
  useFuzzySearch = true,
  customSorts = {},
  initialSortAsc = true,
  secondaryInputList,
}: TableSortInput<InputCols, SecondaryInputCols>): TableSortOutput<
  InputCols,
  SecondaryInputCols
> {
  const [initialSort, setInitialSort] = useState(true)
  const [total, setTotal] = useState(0)
  const [count, setCount] = useState(0)
  const [orderAsc, setOrderAsc] = useState(initialSortAsc)
  const [sortKey, setSortKey] = useState(startingSortKey)
  const [pages, setPages] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [selectedSearchType, setSelectedSearchType] = useState(
    startingSearchKey,
  )
  const [searchTerm, setSearchTerm] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(startingRowsPerPage)
  const [toBeSorted, setToBeSorted] = useState(startingSortKey !== '')

  useEffect(() => {
    setSortKey(startingSortKey)
  }, [startingSortKey])

  const sortFn = useMemo(() => {
    if (Object.prototype.hasOwnProperty.call(customSorts, '*')) {
      return customSorts['*']
    }

    return customSorts[sortKey]
  }, [customSorts, sortKey])

  const searchFn = useMemo(() => {
    if (customSearches[selectedSearchType])
      return customSearches[selectedSearchType]

    if (
      customSearches.all &&
      (selectedSearchType === '' || selectedSearchType === 'all')
    )
      return customSearches.all

    // Fuzzy match function for alphanumeric strings only. Adapts for selectedSearchType
    const fn = (data, search = searchTerm) => {
      return data.concat().filter((item) => {
        const itemValues =
          selectedSearchType === '' ||
          selectedSearchType === 'all' ||
          !item[selectedSearchType]
            ? JSON.stringify(Object.values(item)).replace(/[\[\],\"]/g, '')
            : item[selectedSearchType]

        if (!useFuzzySearch)
          return itemValues.toLowerCase().indexOf(search) > -1

        const searchPattern = search
          .replace(/[^a-zA-Z0-9]/g, '')
          .split('')
          .join('.*')

        return (
          itemValues.toLowerCase().match(new RegExp(searchPattern, 'i')) !==
          null
        )
      })
    }

    return fn
  }, [customSearches, selectedSearchType])

  const orderedList: (InputCols | SecondaryInputCols)[][] = useMemo(() => {
    let finalList: (InputCols | SecondaryInputCols)[] = []
    let filteredSecondaryList: SecondaryInputCols[] = []

    if (
      inputList &&
      (inputList.length > 0 ||
        (secondaryInputList && secondaryInputList.length > 0))
    ) {
      finalList = _.cloneDeep(inputList)

      filteredSecondaryList = secondaryInputList
        ? _.cloneDeep(secondaryInputList)
        : []

      if (searchTerm !== '') {
        finalList = searchFn(finalList, searchTerm)

        filteredSecondaryList = searchFn(filteredSecondaryList, searchTerm)
      }

      if (initialSort) {
        const initialUserList = toBeSorted
          ? sortData(finalList, sortKey, orderAsc, sortFn)
          : _.cloneDeep(finalList)

        initialUserList.push(...filteredSecondaryList)

        finalList = initialUserList
      } else {
        finalList.push(...filteredSecondaryList)

        finalList = toBeSorted
          ? sortData(finalList, sortKey, orderAsc, sortFn)
          : _.cloneDeep(finalList)
      }
    }

    setPages(Math.ceil(finalList.length / rowsPerPage))
    setCount(finalList.length)

    const out: (InputCols | SecondaryInputCols)[][] = []

    // Chunks the sorted data into pages
    for (let index = 0; index < finalList.length; index += rowsPerPage) {
      out.push(finalList.slice(index, index + rowsPerPage))
    }

    return out
  }, [
    inputList,
    secondaryInputList,
    initialSort,
    sortKey,
    orderAsc,
    searchTerm,
    selectedSearchType,
    rowsPerPage,
  ])

  useEffect(() => {
    // Do not reset sort order if the input list is the same
    if (inputList && inputList.length === orderedList.flat().length) {
      return
    }

    setOrderAsc(initialSortAsc)
  }, [initialSortAsc, inputList])

  useEffect(() => {
    const t1 = Array.isArray(inputList) ? inputList.length : 0
    const t2 = Array.isArray(secondaryInputList) ? secondaryInputList.length : 0

    setTotal(t1 + t2)
  }, [inputList, secondaryInputList])

  const setSortOrder = useCallback(
    (key: string, defSort = true) => {
      if (key === sortKey) {
        setOrderAsc(!orderAsc)
      } else {
        setSortKey(key)
        setOrderAsc(defSort)
      }

      // Go to the start of pagination
      if (!toBeSorted) {
        setToBeSorted(true)
      }

      setActivePage(1)
    },
    [orderAsc, sortKey],
  )

  return {
    total,
    setToBeSorted,
    initialSort,
    setInitialSort,
    orderAsc,
    sortKey,
    setSortOrder,
    rowsPerPage,
    setRowsPerPage,
    pages,
    setPages,
    activePage,
    setActivePage,
    selectedSearchType,
    setSelectedSearchType,
    searchTerm,
    setSearchTerm,
    count,
    orderedList,
  }
}
