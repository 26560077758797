import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import moment from 'moment'
import classNames from 'classnames'
import numeral from 'numeraljs'

import { ButtonRow } from './button-row'
import Button, { CopyButton } from './button'
import DeleteButtonWithConfirmation from './delete-button-with-confirmation'
import Input from './input'
import Link from './link'
import Loader, { LoadingLabel } from './loader'
import Modal from './modal'
import QRCodeModal from './qr-code-modal'
import ShareCampaignCodesModal, {
  ShareModalState,
} from './share-campaign-codes-button'
import Tooltip from './tooltip'
import { TrackCreateLastLinksTable } from './track-create-last-links'
import { OuterBox, InnerBox } from './two-columns'
import { Heading } from './typography'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import { deleteBatchCodes } from '../api/graphql/track-actions-client'
import {
  AVAILABLE_CODE_IDS,
  getMinCodesQuick,
} from '../api/graphql/track-view-client'
import EyeIcon from '../assets/icon-eye-open-pink.svg'
import QRIcon from '../assets/icon-qr.svg'
import ShareIcon from '../assets/icon-share-white.svg'
import ShareIconHover from '../assets/icon-share.svg'
import {
  getCsvString,
  downloadSpecificCodes,
  buildMinCodesByUserList,
  defaultShortLinkDomain,
  getCustomDomainID,
  getDomain,
  MinCodesByUserResult,
  getMultiCodesToCopy,
} from '../helpers/track-module'
import useCustomLinks, { AvailableDomain } from '../hooks/useCustomLinks'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/track-recently-created-links.module.scss'
import { DeleteBatchCodesMutationVariables } from '../__gql-types__/graphql'

const YESTERDAY = moment().subtract(1, 'days').startOf('day')

interface GroupedCampaignCodes {
  item: MinCodesByUserResult[]
  time: string
  selected?: boolean
}

interface PreviousCodesRowProps {
  availableShortLinkDomains: AvailableDomain[]
  availableAppLinkDomains: AvailableDomain[]
  newlyCreatedLinks: MinCodesByUserResult[]
  group: GroupedCampaignCodes
  groupIndex: number
  onCheckUpdate: (checked: boolean) => void
  setQrModal: React.Dispatch<React.SetStateAction<string | null>>
  onDelete: (
    idsToDelete: string[],
    deleteObject: DeleteBatchCodesMutationVariables,
  ) => Promise<void>
}

const PreviousCodesRow = ({
  availableShortLinkDomains,
  availableAppLinkDomains,
  newlyCreatedLinks = [],
  group,
  groupIndex,
  onCheckUpdate,
  setQrModal,
  onDelete,
}: PreviousCodesRowProps) => {
  const { userEmail } = useReactiveVar(currentUserDetails)

  const referToLinks = useReactiveVar(linkOrCode)

  const containerRef = useRef<HTMLLIElement>(null)

  const { item, time } = group

  const groupID = item.map(({ codeID }) => codeID).join(',')

  let showTime = ''

  const mT = moment(time)
  const days = moment().diff(mT, 'days')
  const isYesterday = mT.isSame(YESTERDAY, 'd')

  // Makes top row be highlighted if new links are created
  const firstCreatedNow =
    !!newlyCreatedLinks && newlyCreatedLinks.length === group.item.length

  if (firstCreatedNow && groupIndex === 0) {
    showTime = 'just now'
  } else if (isYesterday) {
    showTime = 'yesterday'
  } else if (days === 0) {
    showTime = `at ${mT.format('h:mma')}`
  } else {
    showTime = `${mT.format('ddd Do, h:mma')}`
  }

  const [showCopyModal, setShowCopyModal] = useState(false)
  const [showQrModal, setShowQrModal] = useState<string | null>(null)

  const { all: multiCopy } = getMultiCodesToCopy(item)

  let valueToCopy: string | string[] = multiCopy

  if (item.length === 1) {
    valueToCopy = item[0].shortLink || item[0].fullLink
  }

  return (
    <>
      <li
        key={groupID}
        ref={containerRef}
        className={classNames(styles.codeListItem, {
          [styles.newlyCreatedLink]: showTime === 'just now',
        })}
      >
        <Tooltip
          id={`${groupID}-tooltip`}
          className={styles.linkItemTooltipContainer}
          tooltipClassName={styles.fullLinkTooltip}
          clickable
          maxWidth={300}
          tooltipPosition="left"
          tooltipMessage={
            item.length === 1 ? <p>{item[0].fullLink}</p> : undefined
          }
        >
          <div className={styles.linkItem}>
            <Input
              type="checkbox"
              id={groupID}
              name="selectItem"
              checked={group.selected}
              className={styles.linkCheckbox}
              label=" "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { checked } = e.target as HTMLInputElement

                onCheckUpdate(checked)
              }}
            />
            {item.length > 1 ? (
              <span className={styles.linkCode}>
                {numeral(item.length).format('0,0')} links created{' '}
              </span>
            ) : (
              <>
                {item[0].shortLink === '' ? (
                  <span className={styles.linkCode}>
                    {item[0].fullLink.replace(
                      /(^https:\/\/www\.)|(^https:\/\/)|(^http:\/\/)/i,
                      '',
                    )}
                  </span>
                ) : (
                  <span className={styles.linkCode}>{item[0].shortLink}</span>
                )}
              </>
            )}
            <div className={styles.inlineDate}>
              <span>{showTime}</span>
            </div>
          </div>
        </Tooltip>
        <div className={styles.buttonsContainer}>
          {item.length === 1 ? (
            <Button
              variant="iconOnly"
              icon={{
                src: QRIcon,
                alt: 'QR code',
              }}
              onPress={() => {
                const linkObj = item[0]

                setQrModal(linkObj.shortLink || linkObj.fullLink || null)
              }}
            />
          ) : (
            <Button
              variant="iconOnly"
              icon={{ src: EyeIcon, alt: 'View all' }}
              onPress={() => setShowCopyModal(true)}
            />
          )}
          <CopyButton className={styles.copyButton} value={valueToCopy} />
          <DeleteButtonWithConfirmation
            containerRef={containerRef}
            buttonClassName={styles.deleteButton}
            confirmMessage={`Delete ${
              item.length === 1 ? 'link' : `${item.length} links`
            }?`}
            onConfirm={async () => {
              const deleteObject: DeleteBatchCodesMutationVariables = {
                codeIDList: item.map(({ codeID }) => codeID),
              }

              const deepLinkServiceID =
                availableAppLinkDomains.find(({ domainName }) =>
                  item[0].shortLink?.includes(domainName),
                )?.domainID || null

              if (deepLinkServiceID) {
                deleteObject.deepLinkServiceID = deepLinkServiceID
              } else {
                const found = availableShortLinkDomains.find(
                  ({ domainName }) =>
                    domainName ===
                    (getCustomDomainID(
                      getDomain(item[0]?.shortLink || '').replace(
                        'https://',
                        '',
                      ),
                    ) || defaultShortLinkDomain),
                )

                if (found) {
                  deleteObject.customDomainID = getCustomDomainID(
                    found.domainID,
                  )
                }
              }

              await onDelete(
                item.map(({ codeID }) => codeID),
                deleteObject,
              )
            }}
          />
        </div>
      </li>
      {showCopyModal && (
        <Modal
          width="wide"
          setIsOpen={setShowCopyModal}
          modalHeader={`${numeral(item.length).format(
            '0,0',
          )} ${referToLinks}s created at ${moment(time).format(
            'hh:mm on DD/MM/YYYY',
          )}`}
          footerContent={
            <div className={styles.copyAllButtons}>
              <CopyButton value={valueToCopy}>Copy all</CopyButton>
              <div className={styles.divider} />
              <Button
                variant="secondary"
                onPress={async () => {
                  const csv = getCsvString({
                    userEmail,
                    codes: item,
                  })

                  await downloadSpecificCodes(csv)
                }}
              >
                Download all
              </Button>
            </div>
          }
        >
          <TrackCreateLastLinksTable
            linksToShow={item}
            setShowQrModal={setShowQrModal}
          />
        </Modal>
      )}
      {showQrModal && (
        <QRCodeModal
          code={showQrModal}
          setShowModal={() => setShowQrModal(null)}
        />
      )}
    </>
  )
}

interface PreviousCodesListProps {
  loading: boolean
  groupedLinks: GroupedCampaignCodes[]
  setGroupedLinks: React.Dispatch<React.SetStateAction<GroupedCampaignCodes[]>>
  newlyCreatedLinks?: MinCodesByUserResult[]
}

const PreviousCodesList = ({
  loading,
  groupedLinks,
  setGroupedLinks,
  newlyCreatedLinks = [],
}: PreviousCodesListProps) => {
  const referToLinks = useReactiveVar(linkOrCode)

  const containerRef = useRef<HTMLUListElement>(null)

  const logAction = useLogAction()

  const {
    availableShortLinkDomains,
    availableAppLinkDomains,
  } = useCustomLinks()

  const [deleteCodes] = useMutation(deleteBatchCodes)

  const [qrModal, setQrModal] = useState<string | null>(null)
  const [deleteError, setDeleteError] = useState(false)

  // Remove delete error after timeout
  useEffect(() => {
    if (deleteError) {
      setTimeout(() => {
        setDeleteError(false)
      }, 2000)
    }
  }, [deleteError])

  return (
    <>
      <Heading type={3} align="left">
        {loading ? (
          <LoadingLabel
            label="Retrieving links"
            className={styles.loadingHeader}
          />
        ) : (
          `Your recently created ${referToLinks}s`
        )}
      </Heading>
      <ul ref={containerRef} className={styles.codeList}>
        {loading ? (
          <li className={styles.codeListItem}>
            <Loader className={styles.loadingCodes} />
          </li>
        ) : (
          <>
            {groupedLinks.length === 0 ? (
              <li
                className={classNames(styles.codeListItem, styles.noLinksFound)}
              >
                No recently created {referToLinks}s in last 30 days
              </li>
            ) : (
              <>
                {groupedLinks.map(
                  (
                    group: GroupedCampaignCodes,
                    groupIndex: number,
                  ): React.ReactElement | null => {
                    return (
                      <PreviousCodesRow
                        key={group.time}
                        availableShortLinkDomains={availableShortLinkDomains}
                        availableAppLinkDomains={availableAppLinkDomains}
                        newlyCreatedLinks={newlyCreatedLinks}
                        group={group}
                        groupIndex={groupIndex}
                        onCheckUpdate={(checked) => {
                          setGroupedLinks((curr) => {
                            const newGroupedLinks = [...curr]

                            newGroupedLinks[groupIndex].selected = checked

                            return newGroupedLinks
                          })
                        }}
                        setQrModal={setQrModal}
                        onDelete={async (idsToDelete, deleteObject) => {
                          const { errors } = await deleteCodes({
                            variables: deleteObject,
                            optimisticResponse: {
                              track: {
                                deleteCodes: idsToDelete,
                                __typename: 'TrackMutations',
                              },
                            },
                            update(cache, { data }) {
                              if (data) {
                                const {
                                  track: { deleteCodes: deletedCodes },
                                } = data

                                const cacheItemID = `MinimalCodeList:{"filteredByCurrentUser":true,"dimensionFilter":null,"sortDirection":"DESC","sortField":"createdTime","versionHistoryLinkID":null,"isCodeIDList":false}`

                                // Get full codeID list from cache
                                const cachedData: {
                                  codeID: string[]
                                } | null = cache.readFragment({
                                  id: cacheItemID,
                                  fragment: AVAILABLE_CODE_IDS,
                                })

                                if (cachedData) {
                                  const { codeID: cachedCodeIDs } = cachedData

                                  // Get index of code to delete
                                  const existingCodeIndexes = deletedCodes.map(
                                    (value) => cachedCodeIDs.indexOf(value),
                                  )

                                  const spliceFn = (inputArray: string[]) => {
                                    const outArray = inputArray.filter(
                                      (val, valIndex) =>
                                        !existingCodeIndexes.includes(valIndex),
                                    )

                                    return outArray
                                  }

                                  // Remove deleted code from cached item's fields
                                  cache.modify({
                                    id: cacheItemID,
                                    fields: {
                                      author(existingCodes = []) {
                                        return spliceFn(existingCodes)
                                      },
                                      codeDef(existingCodes = []) {
                                        return spliceFn(existingCodes)
                                      },
                                      codeID(existingCodes = []) {
                                        return spliceFn(existingCodes)
                                      },
                                      createdTime(existingCodes = []) {
                                        return spliceFn(existingCodes)
                                      },
                                      fullLink(existingCodes = []) {
                                        return spliceFn(existingCodes)
                                      },
                                      shortLink(existingCodes = []) {
                                        return spliceFn(existingCodes)
                                      },
                                      versionNumber(existingCodes = []) {
                                        return spliceFn(existingCodes)
                                      },
                                      totalCodes(existing = 0) {
                                        return Math.max(
                                          existing - deletedCodes.length,
                                          0,
                                        )
                                      },
                                    },
                                  })
                                }
                              }
                            },
                          })

                          if (errors) {
                            setDeleteError(true)
                            return
                          }

                          logAction({
                            variables: {
                              action: 'bulk-delete-codes',
                              functionName: 'bulkDelete',
                              pagePath: '/track/create-links',
                              websiteSection: 'track',
                              extra: JSON.stringify(deleteObject),
                            },
                          })
                        }}
                      />
                    )
                  },
                )}
              </>
            )}
          </>
        )}
      </ul>
      {deleteError && (
        <p className={styles.deleteError}>
          Unable to delete codes. Please try again later.
        </p>
      )}
      {qrModal && (
        <QRCodeModal code={qrModal} setShowModal={() => setQrModal(null)} />
      )}
    </>
  )
}

interface TrackCreateRecentUserLinksProps {
  isStatic?: boolean
  newlyCreatedLinks?: MinCodesByUserResult[]
}

const TrackCreateRecentUserLinks = ({
  isStatic,
  newlyCreatedLinks,
}: TrackCreateRecentUserLinksProps) => {
  const { userEmail } = useReactiveVar(currentUserDetails)
  const referToLinks = useReactiveVar(linkOrCode)

  const [
    fetchUserLinks,
    { data: userLinksData, loading: loadingUsersCodesStatus },
  ] = useLazyQuery(getMinCodesQuick, {
    fetchPolicy: 'network-only',
  })

  const [groupedLinks, setGroupedLinks] = useState<GroupedCampaignCodes[]>([])
  const [shareModalState, setShareModalState] = useState<ShareModalState>({
    active: false,
    shared: false,
    typedValue: '',
    shareEmails: [],
    note: '',
    subject: '',
  })

  // Fetch user links created in the last 30 days
  useEffect(() => {
    if (isStatic || !userEmail) return

    fetchUserLinks({
      variables: {
        filterByCurrentUser: true,
        startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        limit: 1000,
      },
    })
  }, [isStatic, userEmail])

  const orderedUserLinks = useMemo(() => {
    if (!userLinksData) return []

    return buildMinCodesByUserList(userLinksData.track.minCodesQuick, 1000)
  }, [userLinksData])

  // Group codes by their batch ID
  // Or by the time they were created
  useEffect(() => {
    if (orderedUserLinks.length === 0) return

    const chunked: {
      [key: string]: GroupedCampaignCodes
    } = {}

    orderedUserLinks.forEach((item) => {
      const { createdTime } = item
      const key = createdTime.slice(0, 19) // remove seconds

      if (Object.prototype.hasOwnProperty.call(chunked, key)) {
        chunked[key] = {
          item: [...chunked[key].item, item],
          time: createdTime,
          selected: true,
        }
      } else {
        chunked[key] = {
          item: [item],
          time: createdTime,
          selected: true,
        }
      }
    })

    const codesOrdered: GroupedCampaignCodes[] = []

    // Only show the first 10 groups
    Object.keys(chunked)
      .slice(0, 10)
      .forEach((key: string) => {
        codesOrdered.push(chunked[key])
      })

    setGroupedLinks(codesOrdered)
  }, [orderedUserLinks])

  return (
    <>
      <OuterBox>
        <InnerBox>
          <PreviousCodesList
            loading={loadingUsersCodesStatus}
            groupedLinks={groupedLinks}
            setGroupedLinks={setGroupedLinks}
            newlyCreatedLinks={newlyCreatedLinks}
          />
          <ButtonRow className={styles.actionButtons} centerAlign>
            <Link type="arrowForward" href="/track/view-links" newTab={false}>
              View all {referToLinks}s
            </Link>
            <span className={styles.buttonWrapper}>
              <Button
                variant="secondary"
                loading={loadingUsersCodesStatus}
                isDisabled={
                  loadingUsersCodesStatus ||
                  groupedLinks.filter(({ selected }) => selected).length ===
                    0 ||
                  orderedUserLinks.length === 0
                }
                onPress={async () => {
                  const fullSelectedLinkIDs = groupedLinks
                    .filter(({ selected }) => selected)
                    .map(({ item }) => item.map(({ codeID }) => codeID))
                    .flat()

                  const csv = getCsvString({
                    userEmail,
                    codes: orderedUserLinks,
                    selectedCodes: fullSelectedLinkIDs,
                  })

                  await downloadSpecificCodes(csv)
                }}
              >
                Download
              </Button>
              <Button
                loading={loadingUsersCodesStatus}
                isDisabled={
                  loadingUsersCodesStatus ||
                  groupedLinks.filter(({ selected }) => selected).length ===
                    0 ||
                  orderedUserLinks.length === 0
                }
                className={styles.shareButton}
                icon={{
                  src: ShareIcon,
                  alt: 'Share',
                  iconAfter: true,
                  hoverImg: ShareIconHover,
                  imgHeight: 18,
                }}
                onPress={() => {
                  setShareModalState({
                    active: true,
                    shared: false,
                    typedValue: '',
                    shareEmails: [],
                    note: '',
                    subject: '',
                  })
                }}
              >
                Share
              </Button>
            </span>
          </ButtonRow>
        </InnerBox>
      </OuterBox>
      {shareModalState.active &&
        groupedLinks.filter(({ selected }) => selected).length > 0 && (
          <ShareCampaignCodesModal
            selectedCodes={groupedLinks
              .filter(({ selected }) => selected)
              .map(({ item }) => item.map(({ codeID }) => codeID))
              .flat()}
            shareModalState={shareModalState}
            setShareModalState={setShareModalState}
          />
        )}
    </>
  )
}

export default TrackCreateRecentUserLinks
