import React, { useRef } from 'react'
import classNames from 'classnames'

import Button from './button'
import { BiLine } from './counter'
import { FormField, FormLabel, FormRow } from './form'
import Input from './input'
import Link from './link'
import Tooltip from './tooltip'
import QRCodePreviewRow from './track-create-qr-preview-row'
import { ErrorMessage } from './typography'
import { vCardFieldDefs, WebLinkForm } from '../helpers/track-create'
import { UpdateFormOptions } from '../hooks/useTrackCreateSavedValues'
import styles from '../styles/track-create-parameter-fields.module.scss'

interface TrackCreateVCardFieldsProps {
  formValues?: WebLinkForm['vCardFieldValues']
  onChange: (
    fieldName: string,
    newVal: string,
    options?: UpdateFormOptions,
  ) => void
  showErrorMessages?: boolean
  fieldsWithErrors: string[]
  qrColours: { fgColour: string; bgColour: string }
  onChangeQrColours: (type: 'fg' | 'bg', colour: string) => void
  submitDisabled?: boolean
}

const TrackCreateVCardFields = ({
  formValues = {},
  onChange,
  showErrorMessages,
  fieldsWithErrors,
  qrColours,
  onChangeQrColours,
  submitDisabled,
}: TrackCreateVCardFieldsProps) => {
  const formRowRefs = useRef<(HTMLDivElement | null)[]>([])

  return (
    <>
      <FormRow includePaddingBottom>
        <FormLabel />
        <FormField>
          <BiLine className={styles.qrBiLine} arrowTop>
            Contact QR codes are not saved or tracked to protect PII.
            <br />
            <Link
              type="arrowForward"
              href="https://support.uplifter.ai/hc/en-us/articles/24586963673501-How-do-I-make-a-QR-code-to-share-a-contact-vCard"
            >
              Learn more
            </Link>
          </BiLine>
        </FormField>
      </FormRow>
      {Object.keys(vCardFieldDefs).map((fieldName, fieldIndex) => {
        const { required, label, tooltip } = vCardFieldDefs[fieldName]

        return (
          <FormRow
            key={fieldName}
            ref={(el) => {
              formRowRefs.current[fieldIndex] = el
            }}
            heading={
              fieldIndex === 0 ? (
                <Tooltip
                  id="fields-heading-tooltip"
                  useIcon
                  tooltipMessage="The contact information you want users to access and save when scanning a QR code."
                >
                  Fields
                </Tooltip>
              ) : undefined
            }
            includePaddingBottom={
              fieldIndex === Object.keys(vCardFieldDefs).length - 1
            }
          >
            <FormLabel id={`vcard-${fieldName}`} tooltip={tooltip}>
              {label}
            </FormLabel>
            <FormField>
              <Input
                id={`vcard-${fieldName}`}
                name={`vcard-${fieldName}`}
                className={styles.inputField}
                placeholder={`${
                  required ? '' : '(Optional) '
                }Type ${label.toLowerCase()}`}
                showClear
                value={formValues[fieldName] || ''}
                error={
                  showErrorMessages && fieldsWithErrors.includes(fieldName)
                }
                onValueChange={(newVal) => {
                  onChange(
                    fieldName,
                    newVal,
                    required && !newVal
                      ? { errorsToAdd: [fieldName] }
                      : { errorsToRemove: [fieldName] },
                  )
                }}
                onPaste={(e) => {
                  const newPastedText = e.clipboardData.getData('Text')

                  onChange(
                    fieldName,
                    newPastedText,
                    required && !newPastedText
                      ? { errorsToAdd: [fieldName] }
                      : { errorsToRemove: [fieldName] },
                  )
                }}
                onKeyDown={(e) => {
                  // If form is not ready to submit, tab to the next field instead
                  if (
                    e.key === 'Enter' &&
                    fieldsWithErrors &&
                    fieldsWithErrors.length > 0
                  ) {
                    e.preventDefault()

                    let nextRowIndex = fieldIndex + 1
                    let nextRow = formRowRefs.current[nextRowIndex]

                    while (nextRowIndex < Object.keys(vCardFieldDefs).length) {
                      if (nextRow) {
                        break
                      }

                      nextRowIndex += 1
                      nextRow = formRowRefs.current[nextRowIndex]
                    }

                    if (nextRow) {
                      const nextRowInput = nextRow.querySelector(
                        'input',
                      ) as HTMLInputElement | null

                      if (nextRowInput) {
                        nextRowInput.focus()
                      }
                    }
                  }
                }}
              />
              {showErrorMessages && fieldsWithErrors.includes(fieldName) && (
                <ErrorMessage>
                  You must enter a value for this field.
                </ErrorMessage>
              )}
            </FormField>
          </FormRow>
        )
      })}
      <QRCodePreviewRow
        linkToShow="Example QR code"
        optional
        small
        customiseWithinCanvas
        formRowTitle={
          <Tooltip
            id="presentation-heading-tooltip"
            useIcon
            tooltipMessage="How you want the link to look for your users before they click or scan. Branded short links and QR codes get increased clickthroughs from more users trusting the link."
          >
            Presentation
          </Tooltip>
        }
        qrColours={qrColours}
        onChangeColour={onChangeQrColours}
      />
      <FormRow>
        <FormLabel />
        <FormField>
          <Button
            type="submit"
            className={classNames(styles.submitButton, {
              [styles.softDisableButton]: fieldsWithErrors.length > 0,
            })}
            isDisabled={submitDisabled}
          >
            Create contact
          </Button>
        </FormField>
      </FormRow>
    </>
  )
}

export default TrackCreateVCardFields
