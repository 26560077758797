import React, { useRef, useState } from 'react'
import classNames from 'classnames'

import Button, { CopyButton } from './button'
import { BiLine } from './counter'
import { FormField, FormLabel, FormRow } from './form'
import QRCodePreview, { useCopyQrCode } from './qr-code'
import QRCodeModal from './qr-code-modal'
import { tooltips, uplifterWebsite } from '../core/constants'
import styles from '../styles/track-create-qr-preview-row.module.scss'

interface QRCodePreviewRowProps {
  className?: string
  linkToShow?: string
  qrColours: { fgColour: string; bgColour: string }
  onChangeColour?: (type: 'fg' | 'bg', colour: string) => void
  formRowTitle?: React.ReactElement
  isVCard?: boolean
  optional?: boolean
  showCopy?: boolean
  customiseWithinCanvas?: boolean
  showDownload?: boolean
  showBiline?: boolean
  small?: boolean
}

const QRCodePreviewRow = ({
  className,
  linkToShow = uplifterWebsite,
  qrColours,
  onChangeColour,
  formRowTitle,
  isVCard,
  optional,
  showCopy,
  customiseWithinCanvas,
  showDownload,
  showBiline,
  small,
}: QRCodePreviewRowProps) => {
  const qrCodeRef = useRef<HTMLDivElement>(null)

  const [showQrModal, setShowQrModal] = useState<string | null>(null)

  const copyQrCode = useCopyQrCode({
    copyRef: qrCodeRef,
    fgColourToUse: qrColours.fgColour,
    bgColourToUse: qrColours.bgColour,
    showLogo: true,
  })

  return (
    <>
      <FormRow className={className} heading={formRowTitle}>
        <FormLabel
          tooltip={tooltips.qrCode}
          optional={optional ? 'optional' : undefined}
        >
          QR code
        </FormLabel>
        <FormField>
          <div className={styles.qrContainer}>
            <QRCodePreview
              ref={qrCodeRef}
              className={classNames(styles.qrCodePreview, {
                [styles.small]: small,
              })}
              url={linkToShow}
              showLogo
              disableCopy
              fgColour={qrColours.fgColour}
              bgColour={qrColours.bgColour}
            >
              {customiseWithinCanvas && (
                <Button
                  variant="secondary"
                  className={styles.qrPreviewCustomiseButton}
                  onPress={() => setShowQrModal(linkToShow)}
                >
                  Customise
                </Button>
              )}
            </QRCodePreview>
            {(showCopy || !customiseWithinCanvas || showDownload) && (
              <div>
                {showCopy && (
                  <CopyButton style={{ margin: '8px 0' }} onPress={copyQrCode}>
                    Copy
                  </CopyButton>
                )}
                {!customiseWithinCanvas && (
                  <Button
                    variant="secondary"
                    className={styles.customiseButton}
                    onPress={() => setShowQrModal(linkToShow)}
                  >
                    Customise
                  </Button>
                )}
                {showDownload && (
                  <Button
                    variant="secondary"
                    className={styles.downloadButton}
                    onPress={() => setShowQrModal(linkToShow)}
                  >
                    Download
                  </Button>
                )}
              </div>
            )}
          </div>
          {showBiline && (
            <BiLine arrowTop className={styles.qrBiLine}>
              Always test your <strong>QR code</strong> before use!
            </BiLine>
          )}
        </FormField>
      </FormRow>
      {showQrModal && (
        <QRCodeModal
          code={showQrModal}
          fileDownloadName={
            isVCard
              ? showQrModal.split('FN:')[1]?.split(/\WN/)[0] || 'qr-code'
              : undefined
          }
          setShowModal={() => setShowQrModal(null)}
          showDestination={!isVCard}
          disableDownload={!showDownload}
          initialFgColour={qrColours.fgColour}
          initialBgColour={qrColours.bgColour}
          onChangeColour={onChangeColour}
        />
      )}
    </>
  )
}

export default QRCodePreviewRow
