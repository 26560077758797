import React from 'react'

import QRCodePreviewRow from './track-create-qr-preview-row'
import { InnerBox, OuterBox } from './two-columns'
import styles from '../styles/track-create-last-links.module.scss'

interface TrackCreateVCardPreviewProps {
  rawText: string
  qrColours: { fgColour: string; bgColour: string }
  onChangeQrColours: (type: 'fg' | 'bg', colour: string) => void
}

const TrackCreateVCardPreview = ({
  rawText,
  qrColours,
  onChangeQrColours,
}: TrackCreateVCardPreviewProps) => {
  return (
    <OuterBox>
      <InnerBox>
        <QRCodePreviewRow
          className={styles.noPadding}
          linkToShow={rawText}
          isVCard
          showCopy
          showBiline
          showDownload
          qrColours={qrColours}
          onChangeColour={onChangeQrColours}
        />
      </InnerBox>
    </OuterBox>
  )
}

export default TrackCreateVCardPreview
