import React, { useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'

import Input, { Label } from './input'
import Link from './link'
import Modal from './modal'
import SubscriptionComparison from './subscription-comparison'
import Tooltip from './tooltip'
import { BoxedText, Heading } from './typography'
import { linkOrCode } from '../api/apollo/variables'
import { sendFeatureRequest } from '../api/graphql/support-client'
import UpgradeRocket from '../assets/svgs/upgrade-rocket.svg'
import { dynamicTextValues, supportEmail } from '../core/constants'
import { CustomDomainType } from '../helpers/custom-links'
import useLogAction from '../hooks/useLogAction'
import { FeatureCategory } from '../hooks/useSubscriptionLevel'
import styles from '../styles/upgrade-modal.module.scss'

interface UpgradeModalProps {
  onHideModal: React.Dispatch<React.SetStateAction<boolean>>
}

interface GenericUpgradeModal extends UpgradeModalProps {
  topFeature?: FeatureCategory
}

export const GenericUpgradeModal = ({
  onHideModal,
  topFeature,
}: GenericUpgradeModal) => {
  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Upgrade to access more connectors{' '}
          <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      hideFooter
    >
      <SubscriptionComparison
        className={styles.subscriptions}
        showCurrent
        showFreeTier={false}
        showFeatures
        topFeature={topFeature}
      />
    </Modal>
  )
}

export const RequestAppLinksModal = ({ onHideModal }: UpgradeModalProps) => {
  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Upgrade to create app links <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      hideFooter
    >
      <p>We recommend creating app links to:</p>
      <ul className={styles.featureList} style={{ marginBottom: 16 }}>
        <li>Route mobile users directly to in-app screens and/or app stores</li>
        <li>Compare all marketing to your apps</li>
        <li>
          Track all journeys with the same consistent parameters you do for web
        </li>
      </ul>
      <SubscriptionComparison
        className={styles.subscriptions}
        showCurrent
        showFreeTier={false}
        showFeatures
        topFeature="app-deeplinks"
      />
    </Modal>
  )
}

export const RequestShortLinksModal = ({ onHideModal }: UpgradeModalProps) => {
  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Upgrade to create short links{' '}
          <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      hideFooter
    >
      <p>
        We recommend <strong>always</strong> creating short links to:
      </p>
      <ul className={styles.featureList}>
        <li>
          Compare all marketing using the short link clickthrough metric (which
          doesn't require cookie consent)
        </li>
        <li>Create short, memorable links for print and out-of-home ads</li>
        <li>Fix landing pages and UTMs after the ad is live</li>
      </ul>
      <p>
        Plus a <strong>branded short link</strong> gives you increased
        clickthroughs from customers who only trust branded, compact links.
      </p>
      <SubscriptionComparison
        className={styles.subscriptions}
        showCurrent
        showFreeTier={false}
        showFeatures
        topFeature="shortlinks"
      />
    </Modal>
  )
}

export const RequestMoreUsersModal = ({ onHideModal }: UpgradeModalProps) => {
  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Upgrade to add new users <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      hideFooter
    >
      <p>
        Save time by letting your colleagues and agencies self-serve consistent
        links and reports.
      </p>
      <p>Regular users can:</p>
      <ul className={styles.featureList}>
        <li>
          <strong>Create links</strong> following your link parameters,
          dropdowns and rules
        </li>
        <li>
          <strong>Request new dropdowns</strong> for admin users to approve or
          reject
        </li>
        <li>
          <strong>View all links</strong> performance and create campaign
          reports
        </li>
      </ul>
      <p>Admin users can also:</p>
      <ul className={styles.featureList}>
        <li>Edit and delete links for all users</li>
        <li>Edit link parameters, dropdowns and rules</li>
        <li>Add, remove and edit user permissions</li>
      </ul>
      <p>We offer bespoke onboarding and training for Enterprise users.</p>
      <SubscriptionComparison
        className={styles.subscriptions}
        showCurrent
        showFreeTier={false}
        showFeatures
        topFeature="users"
      />
    </Modal>
  )
}

export const RequestMoreWorkspacesModal = ({
  onHideModal,
}: UpgradeModalProps) => {
  const linkCopy = useReactiveVar(linkOrCode)

  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Upgrade to create new workspaces{' '}
          <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      hideFooter
    >
      <p>We recommend creating new workspaces if:</p>
      <ul className={styles.featureList}>
        <li>
          <strong>You're an agency with multiple clients.</strong> Each client's
          workspace can have different users, link taxonomies, reports and
          integrations.
        </li>
        <li>
          <strong>
            You're a large company with multiple brands or websites.
          </strong>{' '}
          Each workspace can be connected to a different web analytics profile.{' '}
          <Link href={`mailto:${supportEmail}`}>Email us</Link> if you want to
          share the same{' '}
          <BoxedText>
            <Link href="/track/create">
              {dynamicTextValues.trackCreatePage[linkCopy]}
            </Link>
          </BoxedText>{' '}
          taxonomy and dropdowns across workspaces.
        </li>
        <li>
          <strong>
            You need separate workspaces for each marketing channel.
          </strong>{' '}
          We don't recommend this because it makes comparing channels and
          managing users more challenging.
        </li>
      </ul>
      <SubscriptionComparison
        className={styles.subscriptions}
        showCurrent
        showFreeTier={false}
        showFeatures
        topFeature="workspaces"
      />
    </Modal>
  )
}

export const RequestBrandedDomainModal = ({
  onHideModal,
  linkType = 'shortLink',
}: UpgradeModalProps & { linkType?: CustomDomainType }) => {
  const logAction = useLogAction()

  const [requestFeature, { loading, error }] = useMutation(sendFeatureRequest)

  const [domain, setDomain] = useState('')
  const [showThankYou, setShowThankYou] = useState(false)

  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={`Request branded ${
        linkType === 'shortLink' ? 'short' : 'app'
      } link`}
      noText={showThankYou ? 'Back' : 'Cancel'}
      onYes={
        showThankYou
          ? undefined
          : async () => {
              if (!showThankYou) {
                setShowThankYou(true)
                const message = `Request custom domain: ${domain}`

                requestFeature({
                  variables: {
                    message,
                    page: 'track/create-campaign-codes',
                  },
                })

                logAction({
                  variables: {
                    action: 'request-custom-domain',
                    pagePath: window.location.pathname,
                    websiteSection: 'track',
                    functionName: 'requestCustomDomain',
                    extra: message,
                  },
                })
              } else {
                onHideModal(false)
              }
            }
      }
      footerContent={
        <>
          {error && (
            <p className={styles.modalError}>
              Error sending request. Please try again later.
            </p>
          )}
        </>
      }
    >
      {(!showThankYou || loading) && (
        <>
          <p>
            Make your links trustworthy by upgrading to a branded{' '}
            {linkType === 'shortLink' ? 'short' : 'app'} link domain.
          </p>
          <p>
            This gives you control over the characters after the domain.
            Allowing you to create memorable links for offline media.
          </p>
          <p>
            Example: <BoxedText>your.company/offer1</BoxedText>
          </p>
          <p>
            You can give us access to your domain or we can buy one on your
            behalf. Put your preferred domain below and we will get in contact.
          </p>
          <Label
            modalHeading
            id="domain"
            optional="(optional)"
            className={styles.modalLabel}
          >
            <Tooltip
              id="preferred-domain-tooltip"
              useIcon
              tooltipMessage="Enter the name of a short domain you want to integrate with."
            >
              Preferred domain
            </Tooltip>
          </Label>
          <Input
            className={styles.domainInput}
            required
            name="domain"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value: val } = e.target as HTMLInputElement
              setDomain(val)
            }}
            value={domain}
          />
        </>
      )}
      {showThankYou && !loading && (
        <>
          <p>
            Thank you for contacting us and requesting custom domain
            integration. We will be in touch soon!
          </p>
        </>
      )}
    </Modal>
  )
}
