import React, { useState, useEffect, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { loginForm, registerForm } from '../api/apollo/variables'
import { getInviteContext } from '../api/REST/account-client'
import { InviteContext } from '../api/types'
import Button from '../components/button'
import Input from '../components/input'
import DisplayError from '../components/display-error'
import Footer from '../components/footer'
import Link from '../components/link'
import Message from '../components/login-message'
import SiteLogo from '../components/site-logo'
import { GoogleSSO, MicrosoftSSO, OktaSSO } from '../components/sso-buttons'
import { brandName, uplifterWebsite } from '../core/constants'
import { getUrlQuery } from '../helpers'
import { validatePassword } from '../helpers/forms'
import useAuthenticate from '../hooks/useAuthenticate'
import styles from '../styles/register.module.scss'

export default function Register() {
  const history = useHistory()

  const login = useReactiveVar(loginForm)
  const register = useReactiveVar(registerForm)

  const { registerUser } = useAuthenticate()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [token, setToken] = useState('')
  const [terms, setTerms] = useState(false)
  const [offers, setOffers] = useState(false)
  const [inviteContext, setInviteContext] = useState<null | InviteContext>(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const query = getUrlQuery()
  const nextToken = query?.get('token')

  useEffect(() => {
    async function fetchContext() {
      if (nextToken) {
        const result = await getInviteContext({ token: nextToken })

        if (result) {
          setInviteContext(result.res?.data || null)
        } else {
          setError(
            'This invitation link has expired. Please contact your administrator to send you a new link',
          )
        }
      }
    }

    fetchContext()
  }, [])

  useEffect(() => {
    if (!nextToken) history.push('/login')
    else setToken(nextToken)
  }, [nextToken])

  useEffect(() => {
    if (register.success) history.push('/welcome')
  }, [register.success])

  const { inviterEmail, companyName, inviteeEmail } = inviteContext || {
    inviterName: '',
    inviterEmail: '',
    inviteeEmail: '',
    companyName: '',
    accountName: '',
  }

  const state = useMemo(() => {
    return {
      fName: firstName,
      lName: lastName,
      token,
      email: inviteeEmail,
      password,
      acceptedMarketing: offers,
    }
  }, [firstName, lastName, password, token, offers, inviteeEmail])

  if (error) {
    return (
      <div className={styles.ssoWrapperOuter}>
        <section className={styles.ssoWrapper}>
          <form onSubmit={(e) => e.preventDefault()}>
            <SiteLogo className={styles.svg} />
            <h2>Join {brandName}</h2>
            {inviteContext && (
              <p>
                You have been invited by <strong>{inviterEmail}</strong> to join
                the <strong>{companyName}</strong> account.
              </p>
            )}
            <Message message={error} />
          </form>
        </section>
        <Footer />
      </div>
    )
  }

  return (
    <div className={styles.ssoWrapperOuter}>
      <section className={styles.ssoWrapper}>
        <form onSubmit={(e) => e.preventDefault()}>
          <SiteLogo className={styles.svg} />
          <h2>Join {brandName}</h2>
          {inviteContext && (
            <p>
              You have been invited by <strong>{inviterEmail}</strong> to join
              the <strong>{companyName}</strong> account.
            </p>
          )}
          {error && <Message message={error} />}
          <div className={styles.divider} />
          <Input
            type="text"
            name="first name"
            label="First Name"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): any =>
              setFirstName(event.target.value)
            }
            value={firstName}
            required
          />
          <Input
            type="text"
            name="last name"
            label="Last Name"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): any =>
              setLastName(event.target.value)
            }
            value={lastName}
            required
          />
          <Input
            name="offers"
            type="checkbox"
            checked={offers}
            onChange={() => setOffers(!terms)}
            label="Receive updates and offers."
            required
          />
          <Input
            name="terms"
            type="checkbox"
            checked={terms}
            className={styles.terms}
            onChange={() => setTerms(!terms)}
            label={
              <>
                I accept the{' '}
                <Link href="/terms-of-service.pdf">Terms of Service</Link> and{' '}
                <Link href={`${uplifterWebsite}privacy`}>Privacy Policy</Link>
              </>
            }
            required
          />
          <div
            className={classNames({
              [styles.inactive]: !!(
                firstName === '' ||
                lastName === '' ||
                !terms
              ),
            })}
          >
            <div className={styles.divider} />
            <h3>Sign up with</h3>
            <OktaSSO
              login={{ ...login, email: inviteeEmail || '' }}
              state={state}
              action="register"
            />
            <GoogleSSO
              login={{ ...login, email: inviteeEmail || '' }}
              state={state}
              action="register"
            />
            <MicrosoftSSO
              login={{ ...login, email: inviteeEmail || '' }}
              state={state}
              action="register"
            />
            <div className={styles.divider} />
            <h3>Or sign up with password</h3>
            <Input
              type="password"
              name="password"
              label="Password"
              autoComplete="new-password"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                setPassword(event.target.value)
              }
              value={password}
              required
            />
            <Input
              type="password"
              name="password-confirmation"
              label="Password Confirmation"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                setPasswordConfirmation(event.target.value)
              }
              value={passwordConfirmation}
              required
            />
            <Button
              loading={loading}
              isDisabled={loading}
              type="submit"
              style={{ marginBottom: 16 }}
              onPress={async () => {
                setLoading(true)

                if (!firstName) {
                  registerForm({
                    ...register,
                    onFail: {
                      message: 'First name field cannot be empty',
                      attempts: register.onFail.attempts + 1,
                    },
                  })
                } else if (!lastName) {
                  registerForm({
                    ...register,
                    onFail: {
                      message: 'Last name field cannot be empty',
                      attempts: register.onFail.attempts + 1,
                    },
                  })
                } else if (!password) {
                  registerForm({
                    ...register,
                    onFail: {
                      message: 'Password cannot be empty',
                      attempts: register.onFail.attempts + 1,
                    },
                  })
                } else if (!passwordConfirmation) {
                  registerForm({
                    ...register,
                    onFail: {
                      message: 'Please confirm your password',
                      attempts: register.onFail.attempts + 1,
                    },
                  })
                } else if (passwordConfirmation !== password) {
                  registerForm({
                    ...register,
                    onFail: {
                      message: 'Passwords do not match',
                      attempts: register.onFail.attempts + 1,
                    },
                  })
                } else if (!terms) {
                  registerForm({
                    ...register,
                    onFail: {
                      message:
                        'Please accept the terms of service and privacy policy before continuing',
                      attempts: register.onFail.attempts + 1,
                    },
                  })
                } else {
                  const validatedPassword = validatePassword(password)

                  if (validatedPassword === '') {
                    await registerUser({ ...state, signUpMethod: 'password' })
                  } else {
                    registerForm({
                      ...register,
                      onFail: {
                        message: validatedPassword,
                        attempts: register.onFail.attempts + 1,
                      },
                    })
                  }
                }

                setLoading(false)
              }}
            >
              Sign up
            </Button>
            <DisplayError attempts={register.onFail.attempts}>
              {register.onFail.message}
            </DisplayError>
            <Link
              href="/forgot-password"
              newTab={false}
              className={styles.link}
            >
              Forgot password?
            </Link>
            <Link href="/login" newTab={false} className={styles.link}>
              Already have an account? Login here
            </Link>
          </div>
        </form>
      </section>
      <Footer />
    </div>
  )
}
