import { useEffect, useMemo } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'

import { currentUserDetails } from '../api/apollo/variables'
import {
  getCompanyDetails,
  getMicrosoftSubscriptionData,
  getPaddleSubscriptionDetails,
} from '../api/graphql/company-client'
import { brandName, usePaddleSandboxAccount } from '../core/constants'
import { getCompanySubscriptionLevel } from '../helpers'

export const currencyLookup = {
  GBP: '£',
  ARS: 'ARS ',
  AUD: 'A$',
  BRL: 'R$',
  CAD: 'CA$',
  CNY: 'CN¥',
  CZK: 'CZK ',
  DKK: 'DKK ',
  EUR: '€',
  HKD: 'HK$',
  HUF: 'HUF ',
  INR: '₹',
  ILS: '₪',
  JPY: '¥',
  MXN: 'MX$',
  TWD: 'NT$',
  NZD: 'NZ$',
  NOK: 'NOK ',
  PLN: 'PLN ',
  RUB: 'RUB ',
  SGD: 'SGD ',
  ZAR: 'ZAR ',
  KRW: '₩',
  SEK: 'SEK ',
  CHF: 'CHF ',
  THB: 'THB ',
  TRY: 'TRY ',
  UAH: 'UAH ',
  USD: '$',
}

export type FeatureCategory =
  | 'users'
  | 'workspaces'
  | 'shortlinks'
  | 'app-deeplinks'
  | 'connectors'

interface SubscriptionLevelFeature {
  id?: string
  category?: FeatureCategory
  text: string
  tooltip?: string
}

interface SubscriptionLevelDetails {
  title: string
  tagline: string
  paddleMonthly?: {
    subscriptionID: number
    defaultPrice: string
  }
  paddleAnnual?: {
    subscriptionID: number
    defaultPrice: string
  }
  maxUsers: number
  maxWorkspaces: number
  whatYouCanDo: SubscriptionLevelFeature[]
  whatYouGet: SubscriptionLevelFeature[]
}

export const subscriptionLevelDetails: {
  [key: string]: SubscriptionLevelDetails
} = {
  startup: {
    title: 'Startup',
    tagline:
      'Replace messy UTM spreadsheets and fiddly Google Analytics reports.',
    paddleMonthly: {
      subscriptionID: usePaddleSandboxAccount ? 52360 : 580851,
      defaultPrice: '£29.00',
    },
    paddleAnnual: {
      subscriptionID: usePaddleSandboxAccount ? 73177 : 580852,
      defaultPrice: '£290.00',
    },
    maxUsers: 5,
    maxWorkspaces: 1,
    whatYouCanDo: [
      {
        id: 'startup-utm-links',
        text: 'Create UTM links',
        tooltip: 'Create thousands of accurate, consistent links, fast.',
      },
      {
        id: 'startup-short-links',
        text: 'Create short links',
        tooltip:
          'Increase brand awareness and engagement by creating short, bespoke links for your campaigns. Count every clickthrough and compare performance.',
      },
      {
        id: 'startup-qr-codes',
        text: 'Create QR codes',
        tooltip:
          'Create branded QR codes that help you connect online and offline activity, from OOH, print and events to digital campaigns.',
      },
      {
        id: 'startup-custom-parameters',
        text: 'Custom parameters',
        tooltip:
          'Add parameters for business units, products, geographies and more. Concatenate them to existing UTMs or make your own.',
      },
      {
        id: 'startup-user-permissions',
        text: 'User permissions',
        tooltip:
          'Take the stress out of managing consistency. Regular users can request new dropdown values, for admins to on-click approve, reject or edit.) (Take the stress out of managing consistency. Regular users can request new dropdown values, for admins to on-click approve, reject or edit.',
      },
      {
        id: 'startup-one-click-reports',
        text: 'One-click reports',
        tooltip:
          "View metrics for each link, see what's working and what's not with our custom reports.",
      },
      {
        id: 'startup-landing-page-monitoring',
        text: 'Landing page monitoring',
        tooltip: '',
      },
    ],
    whatYouGet: [
      {
        id: 'startup-click-limit',
        text: 'Unlimited clicks',
      },
      {
        id: 'startup-link-limit',
        text: 'Monitor 250 links',
      },
      {
        id: 'startup-user-limit',
        text: '1-5 users',
        tooltip:
          'Let other users self-serve and collaborate on consistent links, dropdowns, reports and insights.',
      },
      {
        id: 'startup-workspace-limit',
        text: '1 workspace',
        tooltip:
          'A single workspace for one client, brand, website or channel. Upgrade to have multiple workspaces with different parameters, dropdowns, reports and integrations.',
      },
      {
        id: 'startup-connectors',
        text: 'GA4 Connector',
      },
    ],
  },
  business: {
    title: 'Business',
    tagline:
      'Let everyone self-serve consistent, branded links to web or app, QR codes, reports and insights.',
    paddleMonthly: {
      subscriptionID: usePaddleSandboxAccount ? 73178 : 901932,
      defaultPrice: '£49.00',
    },
    paddleAnnual: {
      subscriptionID: usePaddleSandboxAccount ? 73179 : 901933,
      defaultPrice: '£490.00',
    },
    maxUsers: 10,
    maxWorkspaces: 2,
    whatYouCanDo: [
      {
        id: 'business-branded-links',
        text: 'Create branded short links',
        tooltip:
          'Create memorable signposting by making shortened links people will remember and trust, increasing clickthrough rate by 39% compared with generic URLs. Branded domains are available at an additional cost',
      },
      {
        id: 'business-app-links',
        text: 'Create app deep links',
        tooltip:
          'Route mobile users to specific in-app screens and/or app stores. Track all marketing to your apps with consistent parameters.',
      },
      {
        id: 'business-journey-reports',
        text: 'Journey reports',
        tooltip: '',
      },
      {
        id: 'business-create-workspaces',
        text: 'Create workspaces',
        tooltip:
          'Create new workspaces for different clients, brands, websites or channels. Each can have different parameters, dropdowns, reports, users and intergrations.',
      },
      {
        id: 'business-taxonomy-workshop',
        text: 'Taxonomy workshop',
        tooltip: `Onboarding workshop to make sure you and your team get the most of ${brandName}.`,
      },
      {
        id: 'business-sla-support',
        text: '48h SLA support',
        tooltip:
          'Get campaign problems solved with excellent email support, average response times of under 30 minutes and resolutions within 48 hours.',
      },
    ],
    whatYouGet: [
      {
        id: 'business-click-limit',
        text: 'Unlimited clicks',
      },
      {
        id: 'business-link-limit',
        text: 'Monitor 2.5k links',
      },
      {
        id: 'business-user-limit',
        text: '1-10 users',
        tooltip:
          'Let other users self-serve and collaborate on consistent links, dropdowns, reports and insights.',
      },
      {
        id: 'business-workspace-limit',
        text: '2 workspaces',
        tooltip:
          'Create multiple workspaces for different clients, brands, websites or channels. Each can have different parameters, dropdowns, reports, users and integrations.',
      },
      {
        id: 'business-connectors',
        text: 'Any 1 connector',
        tooltip: 'Additional connectors are available at an additional cost.',
      },
    ],
  },
  enterprise: {
    title: 'Enterprise',
    tagline:
      'Automate all UTM pain away with enhanced integrations, security, training and support.',
    maxUsers: Infinity,
    maxWorkspaces: Infinity,
    whatYouCanDo: [
      {
        id: 'enterprise-connectors',
        text: 'Multiple connectors',
        tooltip: '',
      },
      {
        id: 'enterprise-open-api',
        text: 'Open API',
        tooltip:
          'Retrieve the data you need, in the format you want. Automate your reports and processes with our API.',
      },
      {
        id: 'enterprise-sso',
        text: 'Single Sign-On',
        tooltip:
          'Increase security, save time and reduce the number of passwords you need to remember. Login with your Microsoft, Google or Okta account.',
      },
      {
        id: 'enterprise-custom-onboarding',
        text: 'Custom onboarding',
        tooltip:
          'Get consitent links and reports for everyone, with custom onboarding and unlimited training with a real person online, in any time zone.',
      },
      {
        id: 'enterprise-invoicing',
        text: 'Annual invoicing',
        tooltip: '',
      },
      {
        id: 'enterprise-sla-support',
        text: '2h SLA support',
        tooltip:
          'Get campaign problems solved with excellent email support, average response times of under 30 minutes and resolutions within 2 hours.',
      },
    ],
    whatYouGet: [
      {
        id: 'enterprise-click-limit',
        text: 'Unlimited clicks',
      },
      {
        id: 'enterprise-link-limit',
        text: 'Unlimited links',
      },
      {
        id: 'enterprise-user-limit',
        text: 'Multiples of 10 users',
      },
      {
        id: 'enterprise-workspace-limit',
        text: '3 workspaces',
        tooltip: 'Additional workspaces are available at an additional cost.',
      },
      {
        id: 'enterprise-connectors',
        text: 'Any 2 connectors',
        tooltip: 'Additional connectors are available at an additional cost.',
      },
    ],
  },
}

export default function useSubscriptionLevel() {
  const { companySubscriptionLevel, isDemoAccount } = useReactiveVar(
    currentUserDetails,
  )

  const { data: companyData } = useQuery(getCompanyDetails)

  const [
    getPaddleDetails,
    { data: paddleData, loading: loadingPaddleData },
  ] = useLazyQuery(getPaddleSubscriptionDetails, {
    notifyOnNetworkStatusChange: true,
  })
  const [
    fetchMicrosoftSubscriptionData,
    { data: microsoftSubscriptionData, loading: loadingMicrosoftData },
  ] = useLazyQuery(getMicrosoftSubscriptionData, {
    notifyOnNetworkStatusChange: true,
  })

  // The Microsoft webhook to update subscriptionLevel sometimes fails
  // But microsoftSubscriptionData gets the level from the source at Microsoft, so is more accurate
  // Is not used by default because it's a slower request that is only made if the org is registered via MS
  // Its value should be used whenever it exists
  const adjustedSubscriptionLevel = useMemo(() => {
    if (!microsoftSubscriptionData) return companySubscriptionLevel

    return (
      microsoftSubscriptionData.currentCompany.microsoftSubscriptionData
        ?.planId || companySubscriptionLevel
    )
  }, [companySubscriptionLevel, microsoftSubscriptionData])

  const { paddleSubscriptionStatus, paymentProcessor } = useMemo(() => {
    if (!companyData)
      return {
        paddleSubscriptionStatus: null,
        paymentProcessor: 'uplifter',
      }

    return companyData.currentCompany
  }, [companyData])

  const {
    isEnterprise,
    isTeam,
    isPaddle,
    isFree,
    isMicrosoftMarketplace,
  } = useMemo(() => {
    const subscriptionLevel = getCompanySubscriptionLevel(
      adjustedSubscriptionLevel,
      paddleSubscriptionStatus,
    )

    return {
      isEnterprise: isDemoAccount || subscriptionLevel === 'enterprise',
      isTeam:
        !isDemoAccount &&
        ['paddle', 'microsoft_team'].indexOf(subscriptionLevel) > -1,
      isPaddle: !isDemoAccount && subscriptionLevel === 'paddle',
      isFree:
        !isDemoAccount &&
        ['free', 'microsoft_free'].indexOf(subscriptionLevel) > -1,
      // Microsoft accounts have same permissions as free/team (Paddle) tiers
      // But should not see Upgrade pages
      isMicrosoftMarketplace: paymentProcessor === 'microsoft',
    }
  }, [
    isDemoAccount,
    adjustedSubscriptionLevel,
    paddleSubscriptionStatus,
    paymentProcessor,
  ])

  const loading = useMemo(() => {
    if (paymentProcessor === 'microsoft') return !microsoftSubscriptionData

    return !companySubscriptionLevel
  }, [companySubscriptionLevel, paymentProcessor, microsoftSubscriptionData])

  // Fetch Paddle subscription details
  useEffect(() => {
    if (
      isTeam &&
      !isMicrosoftMarketplace &&
      !!companyData?.currentCompany.paddleSubscriptionStatus
    ) {
      getPaddleDetails()
    }
  }, [isTeam, isMicrosoftMarketplace, companyData])

  // Fetch MS Subscription details
  useEffect(() => {
    if (paymentProcessor !== 'microsoft') return

    fetchMicrosoftSubscriptionData()
  }, [paymentProcessor])

  return {
    loading,
    isPaid: isEnterprise || isTeam,
    isEnterprise,
    isTeam,
    isPaddle,
    isFree,
    isMicrosoftMarketplace,
    microsoftSubscriptionData,
    loadingMicrosoftData,
    fetchMicrosoftSubscriptionData,
    paddleData,
    loadingPaddleData,
    getPaddleDetails,
  }
}
