import React, { useEffect, useState } from 'react'
import moment from 'moment'
import classNames from 'classnames'

import Button from './button'
import ProgressBar from './progress-bar'
import { onboardingSectionData, uplifterWebsite } from '../core/constants'
import useOnboarding from '../hooks/useOnboarding'
import useLogAction from '../hooks/useLogAction'
import { quiz } from '../static-copy/quiz-questions'
import styles from '../styles/quiz.module.scss'

interface QuizProps {
  isComplete?: boolean
}

const Quiz = ({ isComplete = false }: QuizProps) => {
  const [activeQuestion, setActiveQuestion] = useState(0)
  const [selectedAnswer, setSelectedAnswer] = useState(false)
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<number | null>(
    null,
  )
  const [showResult, setShowResult] = useState(false)
  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  })

  const { mergedOnboardingSections, updateOnboardingSection } = useOnboarding()
  const logAction = useLogAction()

  const { questions } = quiz
  const { question, choices, correctAnswer } = questions[activeQuestion]

  useEffect(() => {
    // The quiz has already been completed, go straight to the results
    if (mergedOnboardingSections && mergedOnboardingSections.length > 0) {
      const learnOnboarding = mergedOnboardingSections.find(
        (section) => section.onboardingSectionID === 'learnCampaignLinks',
      )

      if (learnOnboarding && learnOnboarding.sectionCompleted && !showResult) {
        setShowResult(true)
      }
    }
  }, [mergedOnboardingSections])

  useEffect(() => {
    if (showResult) {
      if (
        (result.score / questions.length) * 100 >= 80 &&
        Array.isArray(mergedOnboardingSections)
      ) {
        // Update onboarding section
        const onboardingSectionsCopy: typeof mergedOnboardingSections = JSON.parse(
          JSON.stringify(mergedOnboardingSections),
        )

        const learnLinksSection = onboardingSectionsCopy.findIndex(
          (section) => section.onboardingSectionID === 'learnCampaignLinks',
        )

        logAction({
          variables: {
            action: 'passed-linkedin-learn-test',
            functionName: '',
            pagePath: '/track/learn',
            websiteSection: 'track',
          },
        })

        if (
          onboardingSectionsCopy[learnLinksSection] &&
          !onboardingSectionsCopy[learnLinksSection].sectionCompleted
        ) {
          onboardingSectionsCopy[learnLinksSection].sectionCompleted = true

          updateOnboardingSection('learnCampaignLinks', 'user')

          logAction({
            // @ts-ignore
            variables: {
              ...onboardingSectionData.learnCampaignLinks.actionData,
              pagePath: '/track/learn',
            },
          })
        }
      }

      if (
        (result.score / questions.length) * 100 < 80 &&
        Array.isArray(mergedOnboardingSections)
      ) {
        logAction({
          variables: {
            action: 'failed-linkedin-learn-test',
            functionName: '',
            pagePath: '/track/learn',
            websiteSection: 'track',
          },
        })
      }
    }
  }, [showResult, result.score])

  const onClickNext = () => {
    setSelectedAnswerIndex(null)

    setResult((prev) => {
      return selectedAnswer
        ? {
            ...prev,
            score: prev.score + 1,
            correctAnswers: prev.correctAnswers + 1,
          }
        : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }
    })

    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion((prev) => prev + 1)
    } else {
      setActiveQuestion(0)
      setShowResult(true)
    }
  }

  const currentMonth = (moment().month() + 1).toString()
  const currentYear = moment().year().toString()
  const expiryYear = (moment().year() + 2).toString()

  const linkedInURL = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Campaign%20Tracking%20Expert%20certificate&organizationId=14073124&issueYear=${currentYear}&issueMonth=${currentMonth}&expirationYear=${expiryYear}&expirationMonth=${currentMonth}&certUrl=${uplifterWebsite}&certId=1234`

  return (
    <>
      {!isComplete && (
        <p>
          Complete the test with 80% or more to get a Campaign Tracking Expert
          LinkedIn certificate.
        </p>
      )}
      <div className={styles.quizContainer}>
        {!showResult ? (
          <div>
            <div>
              <p className={styles.activeQuestionNo}>
                <span>{activeQuestion + 1}</span> of {questions.length}
              </p>
              <ProgressBar
                percentage={Math.ceil(
                  (activeQuestion / questions.length) * 100,
                )}
                showPercent={false}
                thin
                superThin
                green
              />
            </div>
            <h4>{question}</h4>
            {choices.map((answer, index) => (
              <Button
                key={answer}
                variant="plainBox"
                className={classNames(styles.answerButton, {
                  [styles.selectedAnswer]: selectedAnswerIndex === index,
                })}
                onPress={() => {
                  setSelectedAnswerIndex(index)
                  setSelectedAnswer(answer === correctAnswer)
                }}
              >
                {answer}
              </Button>
            ))}

            <Button
              onPress={onClickNext}
              isDisabled={selectedAnswerIndex === null}
            >
              {activeQuestion === questions.length - 1 ? 'Finish' : 'Confirm'}
            </Button>
          </div>
        ) : (
          <div className={styles.result}>
            {(!isComplete || (result.score / questions.length) * 100 >= 80) && (
              <p>
                Score:
                <span> {`${(result.score / questions.length) * 100}%`}</span>
              </p>
            )}
            {isComplete || (result.score / questions.length) * 100 >= 80 ? (
              <div>
                <p>
                  Congratulations, you know what campaign links are and how to
                  use them to improve your marketing.
                </p>
                <div className={styles.flexBtns}>
                  <Button
                    onPress={() => {
                      window.open(linkedInURL, '_blank')
                    }}
                  >
                    Add certificate to LinkedIn profile
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ height: 40 }}
                    onPress={() => {
                      setActiveQuestion(0)

                      setResult({
                        score: 0,
                        correctAnswers: 0,
                        wrongAnswers: 0,
                      })
                      setShowResult(false)
                    }}
                  >
                    Take the test again
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <p>
                  Unfortunately, you failed the test! You need at least 80% to
                  get your certificate.
                </p>
                <Button
                  onPress={() => {
                    setActiveQuestion(0)

                    setResult({
                      score: 0,
                      correctAnswers: 0,
                      wrongAnswers: 0,
                    })
                    setShowResult(false)
                  }}
                >
                  Take the test again
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default Quiz
