import React, { useState, useRef, useEffect, FormEvent, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'
import { nanoid } from 'nanoid'

import Button, { NavigateButton } from './button'
import DisplayError from './display-error'
import Input, { Label } from './input'
import Link from './link'
import SiteLogo, { LogoTagline } from './site-logo'
import { GoogleSSO, MicrosoftSSO } from './sso-buttons'
import {
  loggedInState,
  loginForm,
  onboardingState,
} from '../api/apollo/variables'
import { resolveMicrosoftToken } from '../api/REST/account-client'
import { brandName, uplifterWebsite } from '../core/constants'
import {
  onBoardingV2FormValidation,
  onBoardingV2FormValidationPassword,
} from '../helpers/forms'
import useAuthenticate from '../hooks/useAuthenticate'
import styles from '../styles/get-started.module.scss'

const initialError = { message: '', attempts: 0 }

interface FormFields {
  email: string
  fName: string
  lName: string
  phoneNum?: string
  organisation: string
  whoWillUse: string | null
  terms: boolean
  offers: boolean
  /** Used to prevent new accounts being created by bots */
  botCheckbox: boolean
  password: string
  passwordConfirmation: string
}

interface FormState {
  secondStep: boolean
  loading: boolean
  error: { message: string; attempts: number }
  radioError: string | null
}

interface GetStartedProps {
  microsoftMarketplace?: boolean
}

export default function GetStarted({
  microsoftMarketplace = false,
}: GetStartedProps) {
  const { authenticated } = useReactiveVar(loggedInState)
  const login = useReactiveVar(loginForm)
  const { createdBillingUser, onError } = useReactiveVar(onboardingState)

  const { authPassword, createNewClient } = useAuthenticate()

  const history = useHistory()

  const errorRef = useRef<HTMLSpanElement>(null)

  const microsoftActivationToken = useMemo(() => {
    if (!microsoftMarketplace) return null

    const params = new URLSearchParams(window.location.search)

    return params.get('token')
  }, [])

  const [formFields, setFormFields] = useState<FormFields>({
    email: '',
    fName: '',
    lName: '',
    // phoneNum: '',
    organisation: '',
    whoWillUse: null,
    terms: false,
    offers: false,
    botCheckbox: false,
    password: '',
    passwordConfirmation: '',
  })
  const [canEditEmail, setCanEditEmail] = useState(true)
  const [showWhoWillUse, setShowWhoWillUse] = useState(true)

  const [formState, setFormState] = useState<FormState>({
    secondStep: false,
    loading: false,
    error: initialError,
    radioError: null,
  })

  // ! Used for testing new MS Marketplace subscriptions
  // useEffect(() => {
  //   if (email === 'chris@uplifter.ai') {
  //     setEmail(`chris+${nanoid()}@uplifter.ai`)
  //   }
  // }, [email])

  // Resolve Microsoft Marketplace token
  useEffect(() => {
    const resolveToken = async () => {
      if (microsoftActivationToken) {
        setShowWhoWillUse(false)

        const data = await resolveMicrosoftToken(microsoftActivationToken)

        if (data && data.success) {
          if (data.alreadyActiveLicense) {
            history.push('/settings')
            return
          }

          if (data.email) {
            setFormFields({ ...formFields, email: data.email })
            setCanEditEmail(false)
          }
        } else {
          history.push('/create-account')
        }
      }
    }

    resolveToken()
  }, [microsoftActivationToken])

  const handleError = (message: string) =>
    setFormState((curr) => ({
      ...curr,
      error: { message, attempts: curr.error.attempts + 1 },
    }))

  // Reset failure messages
  useEffect(() => {
    loginForm({
      ...login,
      onFail: {
        message: '',
        attempts: 0,
      },
    })

    onboardingState({
      onError: '',
      createdBillingUser: false,
      paddleData: {},
    })

    setFormState((curr) => ({
      ...curr,
      error: initialError,
    }))
  }, [])

  useEffect(() => {
    if (createdBillingUser)
      authPassword({
        username: formFields.email,
        password: formFields.password,
      })
  }, [createdBillingUser])

  useEffect(() => {
    if (authenticated) history.push('/welcome')
  }, [authenticated])

  useEffect(() => {
    if (onError) {
      handleError(onError)

      if (errorRef && errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [onError])

  useEffect(() => {
    if (formState.error.message !== '' && errorRef && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [formState.error])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setFormState((curr) => ({ ...curr, loading: true }))

    const {
      fName,
      lName,
      email,
      organisation,
      whoWillUse,
      botCheckbox,
      password,
      passwordConfirmation,
      offers,
      terms,
    } = formFields

    if (botCheckbox) {
      setFormState((curr) => ({
        ...curr,
        loading: false,
        error: {
          message: 'Suspicious activity detected. Please try again later.',
          attempts: curr.error.attempts + 1,
        },
      }))

      Sentry.captureMessage('Signup form bot detected')

      return
    }

    if (!formState.secondStep) {
      if (showWhoWillUse && !whoWillUse) {
        setFormState((curr) => ({
          ...curr,
          radioError: `Please tell us who will be using ${brandName}.`,
        }))
      }

      const validFirstStep = onBoardingV2FormValidation(
        {
          fName,
          lName,
          email,
          organisation,
          terms,
        },
        handleError,
      )

      if (validFirstStep && (!showWhoWillUse || whoWillUse)) {
        setFormState((curr) => ({
          ...curr,
          secondStep: true,
          error: initialError,
        }))
      }
    } else {
      const validSecondStep = onBoardingV2FormValidationPassword(
        {
          password,
          passwordConfirmation,
        },
        handleError,
      )

      if (validSecondStep) {
        await createNewClient({
          fName,
          lName,
          email,
          // phoneNum,
          organisation,
          passW: password,
          signUpMethod: 'password',
          acceptedMarketing: offers,
          microsoftActivationToken: microsoftActivationToken || undefined,
          planInterest: showWhoWillUse ? whoWillUse : '',
        })
      }
    }

    setFormState((curr) => ({ ...curr, loading: false }))
  }

  const loginUrl = {
    pathname: '/login',
    // This is needed to force refresh on landing
    key: nanoid(),
    state: {
      applied: true,
    },
  }

  const state = {
    fName: formFields.fName,
    lName: formFields.lName,
    email: formFields.email,
    // phoneNum,
    organisation: formFields.organisation,
    acceptedMarketing: formFields.offers,
    planInterest: formFields.whoWillUse,
  }

  if (formState.secondStep) {
    return (
      <div className={styles.ssoWrapper}>
        <form onSubmit={handleSubmit}>
          <SiteLogo link={uplifterWebsite} className={styles.svg} />
          <LogoTagline />
          <p className={styles.subHeading}>How would you like to log in?</p>
          <span className={styles.spacer} ref={errorRef} />
          <DisplayError
            className={styles.errorMessage}
            attempts={formState.error.attempts}
          >
            {formState.error.message}
          </DisplayError>
          <div>
            <GoogleSSO login={login} state={state} action="create" />
            <MicrosoftSSO login={login} state={state} action="create" />

            <div className={styles.divider} />
            <p className={styles.subHeading}>Or sign up with password</p>
            <Input
              name="password"
              type="password"
              autoComplete="new-password"
              label="Password"
              value={formFields.password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value: val } = e.target as HTMLInputElement
                setFormFields((curr) => ({ ...curr, password: val }))
              }}
              required
            />

            <Input
              name="passwordConfirmation"
              type="password"
              autoComplete="new-password"
              label="Confirm password"
              value={formFields.passwordConfirmation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value: val } = e.target as HTMLInputElement
                setFormFields((curr) => ({
                  ...curr,
                  passwordConfirmation: val,
                }))
              }}
              required
            />
          </div>
          <Button
            loading={formState.loading}
            isDisabled={formState.loading}
            type="submit"
            style={{ marginBottom: 16 }}
          >
            Sign up
          </Button>
          <div className={styles.accountText}>
            <p>
              <span>Already have an account?</span>{' '}
              <NavigateButton
                className={styles.loginLink}
                onPress={() => {
                  // Reset failure messages
                  loginForm({
                    ...login,
                    onFail: {
                      message: '',
                      attempts: 0,
                    },
                  })

                  onboardingState({
                    onError: '',
                    createdBillingUser: false,
                    paddleData: {},
                  })

                  history.push(loginUrl)
                }}
              >
                Log in here
              </NavigateButton>
            </p>
          </div>
        </form>
      </div>
    )
  }

  return (
    <div className={styles.ssoWrapper}>
      <form onSubmit={handleSubmit}>
        <SiteLogo link={uplifterWebsite} className={styles.svg} />
        <LogoTagline />
        <span className={styles.spacer} ref={errorRef} />
        <DisplayError
          className={styles.errorMessage}
          attempts={formState.error.attempts}
        >
          {formState.error.message}
        </DisplayError>
        <div>
          <Input
            name="fName"
            type="fName"
            label="First name"
            value={formFields.fName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value: val } = e.target as HTMLInputElement
              setFormFields((curr) => ({ ...curr, fName: val }))
            }}
            required
          />
          <Input
            name="lName"
            type="lName"
            label="Last name"
            value={formFields.lName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value: val } = e.target as HTMLInputElement
              setFormFields((curr) => ({ ...curr, lName: val }))
            }}
            required
          />
          <Input
            name="email"
            type="email"
            label="Email"
            value={formFields.email}
            disabled={!canEditEmail}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value: val } = e.target as HTMLInputElement
              setFormFields((curr) => ({ ...curr, email: val }))
            }}
            required
          />
          <Input
            name="organisation"
            value={formFields.organisation}
            label="Organisation"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value: val } = e.target as HTMLInputElement
              setFormFields((curr) => ({ ...curr, organisation: val }))
            }}
            required
          />
          {/* <Input
                name="phone"
                value={formFields.phoneNum}
                label="Phone number (optional)"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value: val } = e.target as HTMLInputElement
                  setFormFields((curr) => ({ ...curr, phoneNum: val }))
                }}
              /> */}
          {showWhoWillUse && (
            <>
              <div className={styles.radioLabel}>
                <Label>Who will be using {brandName}?</Label>
              </div>
              <div className={styles.whoWillUse}>
                <Input
                  className={
                    formFields.whoWillUse === 'Individual'
                      ? styles.checked
                      : undefined
                  }
                  type="radio"
                  name="who-will-use"
                  id="who-will-use-just-me"
                  checked={formFields.whoWillUse === 'Individual'}
                  label="Just me"
                  onClick={() => {
                    setFormFields((curr) => ({
                      ...curr,
                      whoWillUse: 'Individual',
                    }))
                    setFormState((curr) => ({ ...curr, radioError: null }))
                  }}
                />
                <Input
                  className={
                    formFields.whoWillUse === 'Team'
                      ? styles.checked
                      : undefined
                  }
                  id="who-will-use-team"
                  type="radio"
                  name="who-will-use"
                  checked={formFields.whoWillUse === 'Team'}
                  label="My team"
                  onClick={() => {
                    setFormFields((curr) => ({
                      ...curr,
                      whoWillUse: 'Team',
                    }))
                    setFormState((curr) => ({ ...curr, radioError: null }))
                  }}
                />
                <Input
                  className={
                    formFields.whoWillUse === 'Enterprise'
                      ? styles.checked
                      : undefined
                  }
                  id="who-will-use-enterprise"
                  type="radio"
                  name="who-will-use"
                  checked={formFields.whoWillUse === 'Enterprise'}
                  label="Multiple teams"
                  onClick={() => {
                    setFormFields((curr) => ({
                      ...curr,
                      whoWillUse: 'Enterprise',
                    }))
                    setFormState((curr) => ({ ...curr, radioError: null }))
                  }}
                />

                {formState.radioError && (
                  <p id={styles.radioError}>{formState.radioError}</p>
                )}
              </div>
            </>
          )}

          <Input
            className={styles.checkbox}
            name={nanoid()}
            type="checkbox"
            hidden
            checked={formFields.botCheckbox}
            onChange={() =>
              setFormFields((curr) => ({
                ...curr,
                botCheckbox: !curr.botCheckbox,
              }))
            }
            label={<>Don't tick this box, its hidden to detect bots.</>}
          />

          <Input
            className={styles.checkbox}
            name="offers"
            type="checkbox"
            checked={formFields.offers}
            onChange={() =>
              setFormFields((curr) => ({ ...curr, offers: !curr.offers }))
            }
            label="Receive updates and offers."
          />
          <Input
            className={styles.checkbox}
            name="terms"
            type="checkbox"
            checked={formFields.terms}
            onChange={() =>
              setFormFields((curr) => ({ ...curr, terms: !curr.terms }))
            }
            label={
              <>
                I accept the{' '}
                <Link href="/terms-of-service.pdf">Terms of Service</Link> and{' '}
                <Link href={`${uplifterWebsite}privacy`}>Privacy Policy</Link>.
              </>
            }
          />
          <Button type="submit" style={{ marginBottom: 16 }}>
            Create new account
          </Button>
          <div className={styles.accountText}>
            <p>
              <span>Already have an account?</span>{' '}
              <NavigateButton
                className={styles.loginLink}
                onPress={() => {
                  // Reset failure messages
                  loginForm({
                    ...login,
                    onFail: {
                      message: '',
                      attempts: 0,
                    },
                  })

                  onboardingState({
                    onError: '',
                    createdBillingUser: false,
                    paddleData: {},
                  })

                  history.push(loginUrl)
                }}
              >
                Login here
              </NavigateButton>
            </p>
          </div>
        </div>
      </form>
    </div>
  )
}
